import { Fragment } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormIcon from '../../Layouts/Forms/FormIcon';

const ChangePassword = () => {
    const [t, i18n] = useTranslation('global');
    const prefix = 'settings.security';
    return (
        <Fragment>
            <Row className='mt-4 mb-4'>
                <FormIcon title={t(`${prefix}.actual-pass`)} />
                <FormIcon title={t(`${prefix}.new-pass`)} />
                <FormIcon title={t(`${prefix}.confirm-pass`)} />
            </Row>
        </Fragment>
    );
};

export default ChangePassword;
