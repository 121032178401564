import { Heading } from "@chakra-ui/layout";
import { Container } from "react-bootstrap";
import TableComp from "../Layouts/Table";

const TendersProvider = () => {
    return (
        <Container fluid>
            <Heading>Licitaciones</Heading>
            <TableComp
                headers={['#', 'Asunto', 'Tipo', 'Fecha', 'Acciones']}
            />
        </Container>
    );
}

export default TendersProvider;