import { Container, Card, Col, Row, Button } from "react-bootstrap";
import { Heading } from "@chakra-ui/react";
import MallImage from '../../images/mall.png'; //Imagen por default
import { useEffect, useState } from "react";
import { useStore } from "react-redux";
import Fetcher from "../../libs/Petition";
import NoData from "../Layouts/NoData";
import '../../styles/Malls.Component.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import { useToast } from "@chakra-ui/toast";
import SkeletonCards from "../Layouts/Skeletons/Cards";
import EditMall from "./fragments/EditMall";
import { useTranslation } from "react-i18next";

const MallComponent = () => {
    const store = useStore();
    const history = useHistory();
    const toast = useToast();
    const [malls, setMalls] = useState([]);
    const [mall_selected, setMallSelected] = useState({});
    const [loading, setLoading] = useState(true);
    const [modalEdit, setModalEdit] = useState(false);

    const [t, i18n] = useTranslation('global');
    const prefix = 'malls.toasts';

    useEffect(() => {
        let { token } = store.getState().login;
        let headers = { Authorization: `Bearer ${token}` };
        getMalls(headers);
    }, []);

    const getMalls = async (headers) => {
        try {
            let response = await Fetcher({
                method: 'GET',
                url: '/malls',
                params: { fibra_id: store.getState().login.fibra.id },
                headers
            });
            // console.log('response malls: ', response.data);
            setMalls(response.data.data);
        } catch (error) {
            toast({
                title: t(`${prefix}.error-con.title`),
                description: t(`${prefix}.error-con.description`),
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
        setLoading(false);
    }

    const redirectTo = (e, mall) => {
        try {
            setMallSelected(mall);
            e.stopPropagation();
            history.push(`/malls/details/${mall.id}`);
        } catch (error) {
            toast({
                title: t(`${prefix}.error-red.title`),
                description: t(`${prefix}.error-red.description`),
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
    }

    const openOrCloseModal = (e, mall) => {
        if (mall) {
            e.stopPropagation();
            // console.log("Mall selected: ", mall);
            setMallSelected(mall);
        }
        setModalEdit(!modalEdit);
    }

    return (
        <Container id='malls-component'>
            <Heading>{t('malls.title')}</Heading>
            <Button variant='outline-success' onClick={e => openOrCloseModal(e, {})}>
                <FontAwesomeIcon icon={faPlus} /> {t('malls.button-add')}</Button>
            {
                !loading ?
                    <Row xs={1} md={4} className="g-4 mt-2">
                        {
                            malls ? malls.map((mall, index) => (
                                <Col key={index}>
                                    <Card onClick={e => redirectTo(e, mall)}>
                                        <Card.Img variant="top" src={MallImage} />
                                        <Card.Body>
                                            <Card.Title>{mall.code}</Card.Title>
                                            <Card.Text>{mall.name}</Card.Text>
                                            <div id='footer-options'>
                                                <Button variant='outline-primary' onClick={e => openOrCloseModal(e, mall)}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )) : null}
                    </Row> : <SkeletonCards cols={4} items={8} />
            }
            {(!malls || malls.length === 0) && !loading ? <NoData /> : null}

            {/* Modal para editar centro comercial */}
            {
                modalEdit ?
                    <EditMall
                        mall={mall_selected}
                        onClose={openOrCloseModal}
                    /> : null
            }
        </Container>
    );
}

export default MallComponent;