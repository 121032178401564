import { Heading } from '@chakra-ui/react';
import { Divider } from 'antd';
import React, { Fragment } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CompPaymentForm = (props) => {
    const { handleLoadFile } = props;
    const [t, i18n] = useTranslation('global');
    const prefix = 'mailbox.form-complementary-payment';
    
    return (
        <Fragment>
            <Row>
                <Divider orientation='left'><Heading size='sm'>{t(`${prefix}.title`)}</Heading></Divider>
                <Form.Group className="mb-3" as={Col}>
                    <Form.Label>{t(`${prefix}.load-xml`)}</Form.Label>
                    <Form.Control type="file" name="xml" onChange={handleLoadFile} accept="text/xml" />
                </Form.Group>
                <Form.Group className="mb-3" as={Col}>
                    <Form.Label>{t(`${prefix}.load-pdf`)}</Form.Label>
                    <Form.Control type="file" name="pdf" onChange={handleLoadFile} accept="application/pdf" />
                </Form.Group>
            </Row>
        </Fragment>
    )
}

export default CompPaymentForm;