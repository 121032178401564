import Fetcher from "../../libs/Petition"

const url = '/cedis_deliveries';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param cedis_id Obtiene las entregas de un centro de distribución
 * @param page [Opcional] Se utiliza para conocer la página en la que se encuentra
 * @param pageSize [Opcional] Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @returns Object {"data" => ComplaintSuggestionBox::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexCedisDeliveries = async (props) => {
    const {
        headers,
        cedis_id,
        page,
        pageSize } = props;

    let response = { data: [], totalItems: 0 };
    
    try {
        // Parámetros requeridos
        let params = {cedis_id};
        // Parámetros opcionales
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("Cedis deliveries [fetch]:: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = {
            data: [],
            totalItems: 0
        };
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param cedis_delivery_id Identificador de la queja o sugerencia sobre el que se hará la consulta
 * @returns Module Retorna un objeto tipo Module
 */
export const showCedisDeliveries = async ({ headers, cedis_delivery_id }) => {
    let response = {};
    try {
        let fetch = await Fetcher({
            url: `${url}/${cedis_delivery_id}`,
            method: 'GET',
            headers
        });
        // console.log("Delivery detail: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeCedisDeliveries = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status === CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param cedis_delivery_id Identificador de la queja o sugerencia que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateCedisDeliveries = async ({ headers, data, cedis_delivery_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${cedis_delivery_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = true;
        // console.log("Fetch complain suggestion: ", fetch);
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param cedis_delivery_id Identificador de la queja o sugerencia que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyCedisDeliveries = async ({ headers, cedis_delivery_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${cedis_delivery_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}