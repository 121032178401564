import { Container } from "react-bootstrap";
import PurchaseOrderTable from "./fragments/Table";
import { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { Heading } from "@chakra-ui/react";
import { indexPurchaseOrders } from "../../api/PurchaseOrders";
import { indexUser } from "../../api/Users";
import { useTranslation } from "react-i18next";
import SearchComp from "../Layouts/Search";
import { PageHeader } from "antd";

const PurchaseOrderProvider = () => {
    const store = useStore();
    const { token, user, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [purchase_orders, setPurchaseOrders] = useState([]);
    // Paginación
    const [totalItems, setTotalItems] = useState(0);        // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                    // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);           // Cantidad de registros por página
    const [loading, setLoading] = useState(true);
    const [providers, setProviders] = useState([]);
    const [t, i18n] = useTranslation('global');
    const [string, setString] = useState('');                   // Cadena para hallar coincidencias

    useEffect(() => {
        getProviders();
        getPurchaseOrders();
    }, [store]);

    const getPurchaseOrders = async () => {
        setLoading(true);
        let response = await indexPurchaseOrders({ headers, fibra_id: fibra.id, page, pageSize, user_id: user.id, string });
        setPurchaseOrders(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }

    const getProviders = async () => {
        let response = await indexUser({ headers, fibra_id: fibra.id, role_code: 'PROVEEDOR' });
        // console.log("providers: ", response);
        setProviders(response.data);
    }

    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    // Buscar mejor coincidencia de folio
    const searchOrder = () =>  getPurchaseOrders();

    return (
        <Container id='purchase-order-component' fluid>
            <PageHeader
                ghost={true}
                onBack={() => window.history.back()}
                title={<Heading>{t('purchase-order.title')}</Heading>}
                subTitle=""
                extra={[
                    <SearchComp
                        tooltip={t('purchase-order.search.tooltip')}
                        onClick={searchOrder}
                        placeholder={t('purchase-order.search.placeholder')}
                        setString={setString}
                        string={string}
                    />
                ]}
            >
            </PageHeader>
            <PurchaseOrderTable
                type='provider'                             // La tabla que se muestra es con permisos de administrador
                title={t('purchase-order.modal-edit.title')}            // Título del modal para editar órdenes de compra
                page={page}                                 // Página actual de la tabla
                pageSize={pageSize}                         // Tamaño de la página
                totalItems={totalItems}                     // Total de registros obtenidos
                getPurchaseOrders={getPurchaseOrders}       // Método para actualizar la vista de las órdenes de compra
                changePage={changePage}                     // Evento para cambiar el número y tamaño de página 
                loading={loading}                           // Indicador de carga de datos
                purchase_orders={purchase_orders}           // Arreglo de órdenes de compra obtenidas
                providers={providers}
                tactions={[]}
            />
        </Container>
    );
}

export default PurchaseOrderProvider;