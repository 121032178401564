import { Col, Form, Row } from 'react-bootstrap';
import FormIcon from '../../Layouts/Forms/FormIcon';

const FormData = (props) => {
    const { data, handleChange, cedis } = props;
    return (
        <Form>
            <Row className="mb-3">
                <FormIcon title="Código" value={data?.code} handleChange={handleChange} name='code' defaultValue={cedis?.code || ''} />
            </Row>
            <Row className="mb-3">
                <FormIcon title="Nombre" value={data?.name} handleChange={handleChange} name='name' defaultValue={cedis?.name || ''} />
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Label>Descripción</Form.Label>
                    <textarea
                        className='form-control'
                        value={data?.description}
                        defaultValue={cedis?.description || ''}
                        onChange={handleChange}
                        name='description'></textarea>
                </Col>
            </Row>
        </Form>
    );
};

export default FormData;
