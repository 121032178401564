import Fetcher from "../../libs/Petition";

const url = '/rents';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Se utiliza el identificador de la fibra para obtener los roles que le corresponden
 * @param page Se utiliza para conocer la página en la que se encuentra
 * @param pageSize Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @param user_id [Opcional] Se usa para obtener únicamente los registros de un cliente
 * @returns Object {"data" => RentRequest::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexRent = async (props) => {
    const {
        headers,
        fibra_id,
        page,
        pageSize,
        user_id } = props;
    let response = [];
    try {
        // Parámetros requeridos
        let params = { fibra_id, page, pageSize };
        // Parámetros opcionales
        if (user_id) params.user_id = user_id;
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = [];
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param rent_id Identificador de la renta sobre el que se hará la consulta
 * @returns Rent Retorna un objeto tipo Rent
 */
export const showRent = async ({ headers, rent_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}/${rent_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = [];
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeRent = async ({ headers, data }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status !== CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param rent_id Identificador de la renta que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateRent = async ({ headers, data, rent_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${rent_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param rent_id Identificador de la renta que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyRent = async ({ headers, rent_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${rent_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}