import JSZip from "jszip";
import Fetcher from "./Petition";

// Este método recibe como props 
//      url: ruta de descarga, se da por hecho que sólo varía el id del elemento que se va a agregar al zip
//      docs: {
//          id: 'identificador de la tupla en la que se almacena el path del archivo, 
//          name: 'Nombre del documento'
//      }
//      headers: Autorización Bearer para acceder a los end points
//      name: Nombre del archivo zip

const getZipFiles = async (props) => {
    const { url, headers, docs, name } = props;
    let zip = new JSZip();

    try {
        for (let index = 0; index < docs.length; index++) {
            let response = await Fetcher({
                url: `${url}/${docs[index].id}`, method: 'GET',
                headers, responseType: 'blob'
            });
            zip.file(`${docs[index].name}-${index}`, response.data, { binary: true });
        }
        let zip_as = await zip.generateAsync({ type: "blob" });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zip_as);
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click();
    } catch (error) {
        console.log("Error get zip files: ", error);
    }
}

export default getZipFiles;