import Room from './Room';
import '../Styles.css';

const _ = require('underscore');

const Rooms = (props) => {
	const { rooms, handleChatRoom, countMessages } = props;

	const getCount = (room_id) => {
		let count = 0;
		let pos = _.findIndex(countMessages, { id: room_id });
		if (pos !== -1) {
			count = countMessages[pos].count;
		}
		return count;
	}

	return (
		<div className='scroll-rooms scroll-body'>
			{
				rooms && rooms.map((room, index) =>
					<div key={index} onClick={() => handleChatRoom(room.id)}>
						<Room
							room={room}
							count={getCount(room.id)}
						/>
					</div>
				)
			}
		</div>
	)
}

export default Rooms;
