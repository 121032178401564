import {
    faBoxTissue,
    faBuilding,
    faBullhorn,
    faCogs,
    faComments,
    faExclamationTriangle,
    faFileContract,
    faFileSignature,
    faGavel,
    faMailBulk,
    faPoll,
    faStoreAlt,
    faTicketAlt,
    faUserCheck,
    faWallet
} from "@fortawesome/free-solid-svg-icons";

const providerPaths = [
    { code: 'CFDI', name: 'mailbox', to: '/mailbox/provider', icon: faMailBulk },
    { code: 'C-INC', name: 'incidents', to: '/incidents/provider', icon: faExclamationTriangle },
    { code: 'O-COM', name: 'purchase-orders', to: '/purchase-order-provider', icon: faWallet },
    { code: 'U-REP', name: 'repse', to: '/repse-provider', icon: faFileSignature },
    { code: 'C-COM', name: 'malls', to: '/malls/provider', icon: faBuilding },
    { code: 'R-LOC', name: 'local-rent', to: '/mall/local/provider', icon: faStoreAlt },
    { code: 'X-CON', name: 'contracts', to: '/contracts/provider', icon: faFileContract },
    { code: 'X-MSG', name: 'messages', to: '/chat-provider', icon: faComments },
    { code: 'X-COM', name: 'releases', to: '/releases/provider', icon: faBullhorn },
    { code: 'B-QSU', name: 'complaint-suggestions', to: '/complaints-suggestions/provider', icon: faBoxTissue },
    { code: 'X-ENC', name: 'quizzs', to: '/polls/provider', icon: faPoll },
    { code: 'X-LIC', name: 'tenders', to: '/tenders/provider', icon: faGavel },
    { code: 'C-ACC', name: 'access-control', to: 'access-control/provider', icon: faUserCheck },
    { code: 'X-CUP', name: 'coupons', to: '/coupons/provider', icon: faTicketAlt },
    { code: 'X-SET', name: 'settings', to: '/settings/provider', icon: faCogs },
];

export default providerPaths;