import { SET_CFDI_STATUSES } from '../actions/main';
import { stateCfdiStatuses as initialState } from '../initialState/main';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CFDI_STATUSES:
            return {
                state: action.cfdi_statuses
            };
        default: return state;
    }
}

export default reducer;