import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Fetcher from "../../../../libs/Petition";
import setToast from "../../../../libs/SetToast";

const AddDocument = (props) => {
    const [show, setShow] = useState(true);
    const [data, setData] = useState({});
    const [file, setFile] = useState(null);
    const toast = useToast();
    const { headers, fibra_id, doc } = props;
    const [t, i18n] = useTranslation('global');
    const prefix = [
        'repse.documents.toasts',
        'repse.documents.modal-edit',
        'repse.documents.form'
    ];

    const handleClose = () => {
        props.onClose();
        props.update();
        setShow(false)
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const handleLoadFile = (e) => setFile(e.target.files[0]);


    const updateDoc = async () => {
        try {
            let response = await Fetcher({
                url: `repse/docs/${doc.id}`, method: 'PUT',
                headers, data
            });
            // console.log("REsponse put: ", response);
            if (response.status === 200) {
                await saveFile(doc.id);
                setToast({
                    title: t(`${prefix[0]}.succ-upd.title`),
                    description: t(`${prefix[0]}.succ-upd.description`),
                    status: "success", toast
                });
                handleClose();
            } else {
                setToast({
                    title: t(`${prefix[0]}.error.title`),
                    description: response.statusText,
                    status: "warning", toast
                });
            }
        } catch (error) { }
    }

    const saveFile = async (id) => {
        // console.log("Saving file, ", file);
        if (file) {
            let form_data = new FormData();
            form_data.append('file', file);
            try {
                let response = await Fetcher({
                    url: `repse/docs/upload/${id}`, method: 'POST',
                    headers, data: form_data
                });
                // console.log("Response Saving file: ", response);
            } catch (error) { console.log("Error: ", error); }
        }
    }

    const save = async () => {
        try {
            let response = await Fetcher({
                url: 'repse/docs', method: 'POST',
                headers, data: { ...data, fibra_id }
            });
            if (response.status === 201) {
                await saveFile(response.data.data.id);
                setToast({
                    title: t(`${prefix[0]}.succ-sav.title`),
                    description: t(`${prefix[0]}.succ-sav.description`),
                    status: "success", toast
                });
                handleClose();
            } else {
                setToast({
                    title: t(`${prefix[0]}.error.title`),
                    description: response.statusText,
                    status: "warning", toast
                });
            }
            // console.log("REsponse post: ", response);
        } catch (error) { }
    }

    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{doc ? t(`${prefix[1]}.title-1`) : t(`${prefix[1]}.title-2`)} {t(`${prefix[1]}.title-3`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className='mb-3'>
                        <Form.Group as={Col}>
                            <Form.Label>{t(`${prefix[2]}.name`)}</Form.Label>
                            <Form.Control type='text' placeholder={t(`${prefix[2]}.ph-name`)} name='name'
                                defaultValue={doc?.name} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>{t(`${prefix[2]}.person-type`)}</Form.Label>
                            <Form.Select name='person_type' defaultValue={doc?.person_type ? doc?.person_type : null}
                                onChange={handleChange}>
                                <option value="null">{t(`${prefix[2]}.opt-1`)}</option>
                                <option value="física">{t(`${prefix[2]}.opt-2`)}</option>
                                <option value="moral">{t(`${prefix[2]}.opt-3`)}</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                        <Form.Group as={Col}>
                            <Form.Label>{t(`${prefix[2]}.periodic-revision`)}</Form.Label>
                            <Form.Control type='number' name='periodic_revision' defaultValue={doc?.periodic_revision}
                                placeholder={t(`${prefix[2]}.ph-periodic-revision`)} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>{t(`${prefix[2]}.file`)}</Form.Label>
                            <Form.Control type='file' onChange={handleLoadFile} />
                        </Form.Group>
                    </Row>

                    <Row className='mb-3'>
                        <Form.Group as={Col}>
                            <Form.Label>{t(`${prefix[2]}.description`)}</Form.Label>
                            <Form.Control as='textarea' name='description' defaultValue={doc?.description} onChange={handleChange} />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={handleClose}>{t(`${prefix[2]}.btn-close`)}</Button>
                <Button variant="outline-primary" onClick={doc ? updateDoc : save}>{doc ? t(`${prefix[2]}.btn-update`) : t(`${prefix[2]}.btn-save`)}</Button>
            </Modal.Footer>
        </Modal>
    );
}


export default AddDocument;