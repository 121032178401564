import { useState, useEffect, Fragment } from "react";
import { faBoxes, faEdit } from "@fortawesome/free-solid-svg-icons";
import TableComp from "../../Layouts/Table";
import { getCfdiStatuses } from "../../../api/Cfdi/CfdiStatuses";
import ModalStatusAddEdit from "../fragments/ModalStatusAddEdit";
import ModalStatusSequence from "../fragments/ModalStatusSequence";
import FlexButton from "../../Layouts/FlexButton";

const Statuses = (props) => {
    const { headers, fibra } = props;
    const [statuses, setStatuses] = useState([]);
    const [status_selected, setStatusSelected] = useState({});
    const [loading, setLoading] = useState(true);
    // Modal para indicar la secuencia de los status
    const [modal_status, setModalStatus] = useState(false);
    // Modal para agregar o actualizar status
    const [modal, setModal] = useState(false);
    const [type, setType] = useState('add');
    const [title, setTitle] = useState('Agregar estatus');

    useEffect(() => {
        getStatuses();
    }, []);

    const getStatuses = async () => {
        setLoading(true);
        let response = await getCfdiStatuses({ headers, fibra_id: fibra.id });
        setStatuses(response);
        setLoading(false);
    }

    const openOrClose = () => setModal(!modal);
    const openOrCloseConfig = () => setModalStatus(!modal_status);

    const updateStatus = (item) => {
        console.log("item", item);
        setType('update'); setTitle('Actualizar estado CFDi');
        setStatusSelected(item);
        openOrClose();
    }

    const configSequence = (item) => {
        console.log("item", item);
        setStatusSelected(item);
        openOrCloseConfig();
    }

    const addStatus = async () => {
        setType('add'); setTitle('Agregar estado CFDi');
        setStatusSelected({ code: '', name: '' });
        openOrClose();
    }

    return (
        <Fragment>
            <TableComp
                headers={['#', 'Nombre', 'Código', 'Fecha de creación', 'Fecha de modificación', 'Acciones']}
                keys={['id', 'name', 'code', 'created_at', 'updated_at']}
                body={statuses}
                loading={loading}
                actions={[
                    { handleClick: updateStatus, icon: faEdit, tooltip: 'Editar estado', variant: 'outline-success' },
                    { handleClick: configSequence, icon: faBoxes, tooltip: 'Editar estado', variant: 'outline-secondary' }
                ]}
            />
            {/* FAB para agregar un nuevo estatus cfdi */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={addStatus} />

            {
                modal ?
                    <ModalStatusAddEdit
                        onClose={openOrClose}
                        update={getStatuses}
                        title={title}
                        cfdi_status={status_selected}
                        headers={headers}
                        type={type}
                        fibra_id={fibra.id}
                    /> : null
            }
            {
                modal_status ?
                    <ModalStatusSequence
                        onClose={openOrCloseConfig}
                        cfdi_status={status_selected}
                        statuses={statuses}
                        headers={headers}
                    /> : null
            }
        </Fragment>
    );
}

export default Statuses;