import { PageHeader } from "antd";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import TabsComp from "../../Layouts/Tabs";
import Modules from "./Modules";
import Roles from "./Roles";
import Users from "./Users";

const FibraDetails = (props) => {
    const { onBack, fibra_id, title } = props;
    const [t, i18n] = useTranslation('global');

    return (
        <Fragment>
            <PageHeader
                ghost={true}
                onBack={() => onBack()}
                title={title}
                subTitle={t(`super-admin.subtitle`)}
            ></PageHeader>
            <TabsComp
                tablist={[
                    t(`super-admin.tabs.module-web`),
                    t(`super-admin.tabs.module-app`),
                    t(`super-admin.tabs.roles`),
                    t(`super-admin.tabs.users`)
                ]}
                tabpanels={[
                    <Modules fibra_id={fibra_id} app_id={1} />,
                    <Modules fibra_id={fibra_id} app_id={2} />,
                    <Roles fibra_id={fibra_id} />,
                    <Users fibra_id={fibra_id} />
                ]}
            />
        </Fragment>
    );
}

export default FibraDetails