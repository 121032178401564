import Fetcher from "../libs/Petition"

const url = '/complaint_suggestion_box';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Se utiliza el identificador de la fibra para obtener los roles que le corresponden
 * @param page [Opcional] Se utiliza para conocer la página en la que se encuentra
 * @param pageSize [Opcional] Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @param user_id [Opcional] Se usa para obtener únicamente los registros de un cliente
 * @param type [Opcional] Se usa para filtrar resultados a través del tipo (queja o sugerencia)
 * @returns Object {"data" => ComplaintSuggestionBox::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexComplaintSuggestion = async (props) => {
    const {
        headers,
        fibra_id,
        page,
        pageSize,
        user_id,
        type,
        status } = props;
    let response = [];
    try {
        // Parámetros requeridos
        let params = { fibra_id };
        // Parámetros opcionales
        if (user_id) params.user_id = user_id;
        if (type) params.type = type;
        if (status) params.status = status;
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("Fetcher complaints suggestions: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = {
            data: [],
            totalItems: 0
        };
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param complaint_suggestion_id Identificador de la queja o sugerencia sobre el que se hará la consulta
 * @returns Incident Retorna un objeto tipo Incident
 */
export const showComplaintSuggestion = async ({ headers, complaint_suggestion_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}/${complaint_suggestion_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeComplaintSuggestion = async ({ headers, data }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status !== CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param complaint_suggestion_id Identificador de la queja o sugerencia que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateComplaintSuggestion = async ({ headers, data, complaint_suggestion_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${complaint_suggestion_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = true;
        // console.log("Fetch complain suggestion: ", fetch);
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param complaint_suggestion_id Identificador de la queja o sugerencia que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyComplaintSuggestion = async ({ headers, complaint_suggestion_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${complaint_suggestion_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}