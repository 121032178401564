import { Form, Row, Col } from "react-bootstrap";
import PropTypes from 'prop-types';
import { Fragment } from "react";
import SkeletonForm from "../../Layouts/Skeletons/Form";
import { useTranslation } from "react-i18next";

const FormInvoice = ({ data, loading }) => {
    let {
        folio,
        uuid,
        subtotal,
        total,
        rfc_emisor,
        nombre_emisor,
        rfc_receptor,
        nombre_receptor,
        emission_date,
        condiciones_pago,
        metodo_pago,
        forma_pago,
        fecha_timbrado,
        tipo_moneda
    } = data;

    const [t, i18n] = useTranslation('global');
    const prefix = 'mailbox.form-invoice';

    return (
        <Fragment>
            {
                loading ? <SkeletonForm cols={3} rows={5} /> :
                    <Form>
                        <Row className="mb-3">
                            <FormGroup name={t(`${prefix}.transmitter-name`)} value={nombre_emisor} />
                            <FormGroup name={t(`${prefix}.transmitter-rfc`)} value={rfc_emisor} />
                        </Row>
                        <Row className="mb-3">
                            <FormGroup name={t(`${prefix}.receptor-name`)} value={nombre_receptor} />
                            <FormGroup name={t(`${prefix}.receptor-rfc`)} value={rfc_receptor} />
                        </Row>
                        <Row className="mb-3">
                            <FormGroup name={t(`${prefix}.folio`)} value={folio} />
                            <FormGroup name={t(`${prefix}.uuid`)} value={uuid} />
                            <FormGroup name={t(`${prefix}.subtotal`)} value={`${subtotal}`} />
                            <FormGroup name={t(`${prefix}.total`)} value={`${total}`} />
                        </Row>
                        <Row className="mb-3">
                            <FormGroup name={t(`${prefix}.payment-way`)} value={forma_pago} />
                            <FormGroup name={t(`${prefix}.currency`)} value={tipo_moneda} />
                            <FormGroup name={t(`${prefix}.payment-method`)} value={metodo_pago} />
                            <FormGroup name={t(`${prefix}.payment-conditions`)} value={condiciones_pago} />
                        </Row>
                        <Row className="mb-3">
                            <FormGroup name={t(`${prefix}.emission-date`)} value={emission_date} />
                            <FormGroup name={t(`${prefix}.stamp-date`)} value={fecha_timbrado} />
                        </Row>
                    </Form>
            }
        </Fragment>
    );
}

const FormGroup = (props) => {
    let { name, value } = props;
    return (
        <Form.Group as={Col}>
            <Form.Label>{name}</Form.Label>
            <Form.Control type="text" value={value} readOnly />
        </Form.Group>
    );
}

FormGroup.propsTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
}

FormInvoice.propsTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            folio: PropTypes.string,
            uuid: PropTypes.string,
            subtotal: PropTypes.string,
            total: PropTypes.string,
            rfc_emisor: PropTypes.string,
            nombre_emisor: PropTypes.string,
            rfc_receptor: PropTypes.string,
            nombre_receptor: PropTypes.string,
            emission_date: PropTypes.string,
            condiciones_pago: PropTypes.string,
            metodo_pago: PropTypes.string,
            forma_pago: PropTypes.string,
            fecha_timbrado: PropTypes.string,
            tipo_moneda: PropTypes.string
        })
    ).isRequired
}

export default FormInvoice;