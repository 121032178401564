export const stateLogin = {
    session: null,
    token: null,
    apps: null,
    roles: null,
    role_selected: null,
    modules: null,
    user: null,
    fibra: null
}

export const stateCookies = false;

export const stateUsers = {}; 

export const stateCfdiStatuses = {};

export const stateChat = {};