import { Fragment, useEffect, useState } from "react";
import { PageHeader, Select } from 'antd';
import { Heading } from "@chakra-ui/layout";
import ModalDetails from "./Modal";
import PaginationComp from "../../Layouts/Pagination";
import TableComp from "../../Layouts/Table";
import { useStore } from "react-redux";
import { indexComplaintSuggestion, updateComplaintSuggestion } from "../../../api/ComplaintSuggestionBox";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const ComplaintSuggestionsBox = (props) => {
    const [t, i18n] = useTranslation('global');
    const t_name = 'complaint-suggestions';  // Objeto de traducciones. Hace referencia al nombre
    const { params, theaders, tkeys, tbadges, is_admin } = props;

    const store = useStore();
    const { token, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const fibra_id = fibra.id;

    const [complaint_suggestions, setComplaintSuggestions] = useState([]);
    const [item_sel, setItemSel] = useState(null);
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [type, setType] = useState(null);
    const [status, setStatus] = useState(null);

    const [modalView, setModalView] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        getComplaitSuggestions();
    }, [page, pageSize, type, status]);

    const getComplaitSuggestions = async () => {
        setLoading(true);
        let response = await indexComplaintSuggestion(
            { headers, page, pageSize, type, fibra_id, status, ...params }
        );
        // console.log("Complaint suggestion box: ", response);
        setComplaintSuggestions(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }

    const updateData = async () => {
        let response = await updateComplaintSuggestion({ headers, data, complaint_suggestion_id: item_sel.id });
        let prefix = `${t_name}.swal-messages.success-update`
        if (response) {
            Swal.fire(t(`${prefix}.title`), t(`${prefix}.description`));
            openOrClose(); setData({});
            getComplaitSuggestions();
        }
    }

    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const changeType = (value) => { setType(value); }
    const changeStatus = (value) => { setStatus(value); }

    const openOrClose = () => { setModalView(!modalView); }

    const viewDetails = (item) => {
        setItemSel(item);
        openOrClose();
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    return (
        <Fragment>
            <PageHeader
                ghost={true}
                onBack={() => window.history.back()}
                title={<Heading>{t(`${t_name}.title`)}</Heading>}
                extra={[
                    <Select placeholder='Tipo' onChange={changeType} name='type' style={{ width: 180 }}>
                        <Option value={null}>{t(`${t_name}.filters.all`)}</Option>
                        <Option value='Queja'>{t(`${t_name}.filters.complaints`)}</Option>
                        <Option value='Sugerencia'>{t(`${t_name}.filters.suggestions`)}</Option>
                    </Select>,
                    <Select placeholder='Estado' onChange={changeStatus} name='status' style={{ width: 180 }}>
                        <Option value={null}>{t(`${t_name}.filters.all`)}</Option>
                        <Option value={0}>{t(`${t_name}.filters.pending`)}</Option>
                        <Option value={1}>{t(`${t_name}.filters.attending`)}</Option>
                    </Select>
                ]}
            >
            </PageHeader>
            <TableComp
                headers={theaders}
                keys={tkeys}
                badges={tbadges}
                body={complaint_suggestions}
                loading={loading}
                loadingItems={pageSize}
                actions={[
                    { icon: is_admin ? faEdit : faEye, tooltip: t(`${t_name}.table.tooltips.details`), handleClick: viewDetails }
                ]}
            />
            {/* Componente de paginación */}
            <PaginationComp totalItems={totalItems} current={page} onChange={changePage} />

            {   // Modal para ver el detalle de la queja o sugerencia
                modalView && <ModalDetails
                    onClose={openOrClose}
                    title={t(`${t_name}.modal-details.title`)}
                    complaint_suggestion_id={item_sel.id}
                    headers={headers}
                    data={data}
                    handleChange={handleChange}
                    update={updateData}
                    is_admin={is_admin}
                />
            }
        </Fragment>
    );
}

export default ComplaintSuggestionsBox;