import { Result, Button } from 'antd';
import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import '../../styles/PermissionDenied.Component.css';

const PermissionDenied = () => {
    const history = useHistory();
    const [t, i18n] = useTranslation('global');
    const prefix = 'permission-denied';

    const handleBack = () => {
        history.push('/');
    }

    return (
        <Container id='permission-denied-component'>
            <Result
                status="403"
                title={t(`${prefix}.title`)}
                subTitle={t(`${prefix}.subtitle`)}
                extra={<Button type="primary" onClick={handleBack}>{t(`${prefix}.extra`)}</Button>}
            />
        </Container>
    );
}

export default PermissionDenied;