import Fetcher from "../../libs/Petition";

const url = '/cfdi/status/roles';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param role_id Se utiliza el identificador del rol para obtener los cfdi status que le corresponden
 * @returns CfdiStatuRole::collection() Retorna una colección de objetos de tipo CfdiStatuses
 */
export const getCfdiStatusRoles = async ({ headers, role_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params: { role_id },
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = [];
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param cfdi_status_id_id Identificador del cfdi_status sobre el que se hará la consulta
 * @returns Role Retorna un objeto tipo CfdiStatuses
 */
export const getCfdiStatusRole = async ({ headers, cfdi_status_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}/${cfdi_status_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = [];
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeCfdiStatusRole = async ({ headers, data }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status !== CREATED)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param cfdi_status_id_id Identificador del cfdi_status que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateCfdiStatusRole = async ({ headers, data, cfdi_status_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${cfdi_status_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param cfdi_status_role_id Identificador del cfdi_status_role que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyCfdiStatusRole = async ({ headers, cfdi_status_role_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${cfdi_status_role_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}