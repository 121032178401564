import { Container } from "react-bootstrap";
import '../../styles/Incident.Component.css';
import IncidentsTable from "./fragments/Table";
import { useStore } from "react-redux";
import { useEffect, useState } from "react";
import { indexIncident } from "../../api/Incidents/Incidents";
import { useTranslation } from "react-i18next";

const Incident = () => {
    const store = useStore();
    const { token, fibra, user } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    // Paginación
    const [totalItems, setTotalItems] = useState(0);        // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                    // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);           // Cantidad de registros por página
    const [loading, setLoading] = useState(true);
    const [incidents, setIncidents] = useState([]);
    const [status, setStatus] = useState(null);
    const [incident_type_id, setIncidentTypeID] = useState(null);

    const [t, i18n] = useTranslation('global');
    const prefix = 'incidents.data';

    useEffect(() => {
        getIncidents();
    }, [page, pageSize, status, incident_type_id]);

    const getIncidents = async () => {
        setLoading(true);
        let response = await indexIncident({ headers, page, pageSize, fibra_id: fibra.id, user_id: user.id, status, incident_type_id });
        console.log("response incidents: ", response);
        setIncidents(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }
    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const changeStatus = (value) => {
        setStatus(value);
        setPage(1);
    }
    const changeIncidentType = (value) => {
        setIncidentTypeID(value);
        setPage(1);
    }

    return (
        <Container id='incident-component' fluid>
            <IncidentsTable
                theaders={[
                    '#',
                    t(`${prefix}.code`),
                    t(`${prefix}.type`),
                    t(`${prefix}.flat`),
                    t(`${prefix}.sector`),
                    t(`${prefix}.local`),
                    t(`${prefix}.status`),
                    t(`${prefix}.actions`)
                ]}
                tkeys={['id', 'code', 'incident_type', 'flat', 'sector', 'local', 'status']}
                tbadges={[{ index: 6, color: 'status_color' }]}
                totalItems={totalItems}
                page={page}
                pageSize={pageSize}
                incidents={incidents}
                loading={loading}
                changePage={changePage}
                changeStatus={changeStatus}
                changeIncidentType={changeIncidentType}
                update={getIncidents}
            />
        </Container>
    );
}

export default Incident;