import { Container } from "react-bootstrap";
import '../../styles/PurchaseOrder.Component.css';
import PurchaseOrderTable from "./fragments/Table";
import { useEffect, useState } from "react";
import { useStore } from "react-redux";
import ModalPurchaseOrder from "./fragments/Modal";
import { Heading } from "@chakra-ui/react";
import FlexButton from "../Layouts/FlexButton";
import { indexPurchaseOrders } from "../../api/PurchaseOrders";
import { indexUser } from "../../api/Users";
import { useTranslation } from "react-i18next";
import { faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Schedule from "./fragments/Schedule";
import { PageHeader } from "antd";
import ButtonIcon from "../Layouts/Forms/ButtonIcon";
import SearchComp from "../Layouts/Search";

const PurchaseOrderDanhos = () => {
    const store = useStore();
    const { token, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [modalAdd, setModalAdd] = useState(false);            // Modal para agregar orden de compra
    const [modalSchedule, setModalSchedule] = useState(false);  // Modal para programar entrega de elementos de una orden de compra
    const [orderSel, setOrderSel] = useState({});               // Orden de compra seleccionada
    // Paginación
    const [totalItems, setTotalItems] = useState(0);            // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                        // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);               // Cantidad de registros por página
    const [loading, setLoading] = useState(true);
    const [purchase_orders, setPurchaseOrders] = useState([]);
    const [providers, setProviders] = useState([]);
    const [t, i18n] = useTranslation('global');
    const [string, setString] = useState('');                   // Cadena para hallar coincidencias

    useEffect(() => {
        getProviders();
        getPurchaseOrders();
    }, []);

    // Método para obtener las órdenes de compra
    const getPurchaseOrders = async () => {
        setLoading(true);
        let response = await indexPurchaseOrders({ headers, fibra_id: fibra.id, page, pageSize, string });
        setPurchaseOrders(response.data);
        setTotalItems(response.totalItems);
        // setString('');
        setLoading(false);
    }

    const getProviders = async () => {
        let response = await indexUser({ headers, fibra_id: fibra.id, role_code: 'PROVEEDOR' });
        // console.log("providers: ", response);
        setProviders(response.data);
    }

    const openOrCloseModal = () => { setModalAdd(!modalAdd); }
    const openOrCloseModalSchedule = () => { setModalSchedule(!modalSchedule); }

    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }
    const scheduleDelivery = (item) => {
        setOrderSel(item);
        openOrCloseModalSchedule();
    }

    // Buscar mejor coincidencia de folio
    const searchOrder = () => getPurchaseOrders();

    return (
        <Container id='purchase-order-component' fluid>
            <PageHeader
                ghost={true}
                onBack={() => window.history.back()}
                title={<Heading>{t('purchase-order.title')}</Heading>}
                subTitle=""
                extra={[
                    <SearchComp
                        tooltip={t('purchase-order.search.tooltip')}
                        onClick={searchOrder}
                        placeholder={t('purchase-order.search.placeholder')}
                        setString={setString}
                        string={string}
                    />
                ]}
            >
            </PageHeader>

            {/* Table */}
            <PurchaseOrderTable
                type='admin'                                // La tabla que se muestra es con permisos de administrador
                title={t('purchase-order.modal-edit.title')}// Título del modal para editar órdenes de compra
                page={page}                                 // Página actual de la tabla
                pageSize={pageSize}                         // Tamaño de la página
                totalItems={totalItems}                     // Total de registros obtenidos
                getPurchaseOrders={getPurchaseOrders}       // Método para actualizar la vista de las órdenes de compra
                changePage={changePage}                     // Evento para cambiar el número y tamaño de página 
                loading={loading}                           // Indicador de carga de datos
                purchase_orders={purchase_orders}           // Arreglo de órdenes de compra obtenidas
                providers={providers}                       // Arreglo de proveedores
                tactions={[{
                    icon: faCalendarAlt, handleClick: scheduleDelivery,
                    tooltip: t('purchase-order.tooltips.delivery-date'),
                    variant: 'outline-primary'
                }]}
            />
            {/* Button para agregar órdenes de compra */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={openOrCloseModal} />

            {/* Modal para agregar nueva orden de pago */}
            {
                modalAdd ?
                    <ModalPurchaseOrder
                        title={t(`purchase-order.modal-add.title`)}
                        type='add'
                        headers={headers}
                        fibra_id={fibra.id}
                        onClose={openOrCloseModal}
                        update={getPurchaseOrders}
                        providers={providers}
                    /> : null
            }
            {/* Modal para programar la entrega en CEDIS */}
            {
                modalSchedule && <Schedule
                    onClose={openOrCloseModalSchedule}
                    orderSel={orderSel}
                    headers={headers}
                    fibra_id={fibra.id}
                />
            }
        </Container>
    );
}

export default PurchaseOrderDanhos;