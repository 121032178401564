import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import { CookiesProvider } from 'react-cookie';
import { ChakraProvider } from "@chakra-ui/react";
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import 'bootstrap/dist/css/bootstrap.css';

// Importar archivos de idioma
import global_es from './lang/es/global.json';
import global_en from './lang/en/global.json';

const lang = localStorage.getItem('lang');
// console.log("Local storage lang: ", lang);

// Inicializar i18next para las traducciones
i18next.init({
	interpolation: { escapeValue: false },
	lng: lang || "es",
	resources: {
		es: {
			global: global_es
		},
		en: {
			global: global_en
		}
	}
});

ReactDOM.render(
	<React.StrictMode>
		<CookiesProvider>
			<ChakraProvider>
				<I18nextProvider i18n={i18next}>
					<Provider store={store}>
						<App />
					</Provider>
				</I18nextProvider>
			</ChakraProvider>
		</CookiesProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();