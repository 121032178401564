import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect } from "react";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import { Select, useToast } from '@chakra-ui/react'
import { useState } from "react";
import { updateInvoice } from "../../../api/Cfdi/Mailbox";
import setToast from "../../../libs/SetToast";
import { useTranslation } from 'react-i18next';
const _ = require('underscore');

const ModalCfdi = (props) => {
    const { onClose, invoice, headers, cfdiStatuses, update } = props;

    const toast = useToast();
    const [statusSequences, setStatusSequences] = useState([]);
    const [newStatus, setNewStatus] = useState(null);
    const [t, i18n] = useTranslation('global');
    const prefix = 'mailbox.files-upload.toasts';

    useEffect(() => {
        let pos = _.findIndex(cfdiStatuses, { id: invoice.cfdi_status_id })
        if (pos !== -1) {
            setStatusSequences(cfdiStatuses[pos].status_sequences);
        }
    }, []);

    const onSave = async () => {
        if (newStatus?.cfdi_status_id) {
            let response = await updateInvoice({ headers, data: { ...newStatus }, invoice_id: invoice.id });
            if (response) {
                update();   // Actualizar cfdis
                setToast({
                    title: t(`${prefix}.cfdi-updated.title`), status: "success", toast, description: t(`${prefix}.cfdi-updated.description`),
                });
            } else {
                setToast({
                    title: t(`${prefix}.cfdi-failed.title`), status: "warning", toast, description: t(`${prefix}.cfdi-failed.description`)
                });
            }
        }
        onClose();  // Cerrar modal
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        setNewStatus({ [name]: value })
    }
    return (
        <ModalComp
            onClose={onClose}
            title={t('mailbox.modal-cfdi.title')}
            body={<Fragment>
                <Select placeholder={t('mailbox.modal-cfdi.select')} name='cfdi_status_id' onChange={handleChange}>
                    {
                        statusSequences.map((status_seq, index) =>
                            <option key={index} value={status_seq.id}>{status_seq.name}</option>
                        )
                    }
                </Select>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name={t('mailbox.modal-cfdi.button-close')} icon={faArrowLeft} variant="outline-secondary" onClick={onClose} />
                <ButtonIcon name={t('mailbox.modal-cfdi.button-save')} icon={faSave} variant="outline-success" onClick={onSave} />
            </Fragment>}
        />
    );
}

export default ModalCfdi;