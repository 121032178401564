import { SET_USERS } from '../actions/main';
import { stateUsers as initialState } from '../initialState/main';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USERS:
            return {
                state: action.users
            };
        default: return state;
    }
}

export default reducer;