import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useState } from "react";
import PropTypes from 'prop-types';
import Fetcher from "../../../../libs/Petition";
import setToast from "../../../../libs/SetToast";
import { useToast } from "@chakra-ui/toast";
import { useTranslation } from "react-i18next";
import ButtonIcon from "../../../Layouts/Forms/ButtonIcon";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";

const AddStatus = (props) => {
    const [show, setShow] = useState(true);
    const [data, setData] = useState({});
    const toast = useToast();
    const { headers, fibra_id, status } = props;
    const [t, i18n] = useTranslation('global');
    const prefix = ['repse.statuses.toasts', 'repse.statuses.repse-modal'];

    const handleClose = () => {
        props.onClose();
        props.update();
        setShow(false)
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const saveStatus = async (e) => {
        e.stopPropagation();
        try {
            let response = await Fetcher({
                url: '/repse/statuses', method: 'POST',
                headers, data: { ...data, fibra_id }
            });
            if (response.status === 201) {
                setToast({
                    title: t(`${prefix[0]}.add-succ.title`),
                    description: t(`${prefix[0]}.add-succ.description`),
                    status: "success", toast
                });
                handleClose();
            } else {
                setToast({
                    title: t(`${prefix[0]}.error.title`),
                    description: response.statusText,
                    status: "warning", toast
                });
            }
        } catch (error) {
            setToast({
                title: t(`${prefix[0]}.auth-err.title`),
                description: t(`${prefix[0]}.auth-err.description`),
                status: "error", toast
            });
        }
    }

    const updateStatus = async () => {
        try {
            let response = await Fetcher({
                url: `/repse/statuses/${status.id}`, method: 'PUT',
                headers, data
            });
            if (response.status === 200) {
                setToast({
                    title: t(`${prefix[0]}.upd-succ.title`),
                    description: t(`${prefix[0]}.upd-succ.description`),
                    status: "success", toast
                });
                handleClose();
            } else {
                setToast({
                    title: t(`${prefix[0]}.error.title`),
                    description: response.statusText,
                    status: "warning", toast
                });
            }
        } catch (error) {
            setToast({
                title: t(`${prefix[0]}.auth-err.title`),
                description: t(`${prefix[0]}.auth-err.description`),
                status: "error", toast
            });
        }
    }

    return (
        <Modal show={show} onHide={handleClose} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>{status === null ? t(`${prefix[1]}.title-1`) : t(`${prefix[1]}.title-2`)} {t(`${prefix[1]}.title-3`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className='mb-3'>
                        <Form.Group as={Col}>
                            <Form.Label>{t(`${prefix[1]}.subject`)}</Form.Label>
                            <Form.Control type='text' name='name' placeholder={t(`${prefix[1]}.subject`)} defaultValue={status?.name} onChange={handleChange} />
                        </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                        <Form.Group as={Col}>
                            <Form.Label>{t(`${prefix[1]}.description`)}</Form.Label>
                            <Form.Control as='textarea' name='description' defaultValue={status?.description} onChange={handleChange} />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonIcon name={t(`${prefix[1]}.button-close`)} variant='outline-secondary' icon={faArrowLeft} onClick={handleClose} />
                {
                    status === null ?
                        <ButtonIcon name={t(`${prefix[1]}.button-update`)} icon={faSave} onClick={updateStatus} variant='outline-success' /> :
                        <ButtonIcon name={t(`${prefix[1]}.button-save`)} icon={faSave} onClick={saveStatus} variant='outline-success' />
                }
            </Modal.Footer>
        </Modal>
    );
}

AddStatus.propTypes = {
    onClose: PropTypes.func.isRequired,
    headers: PropTypes.string.isRequired,
    fibra_id: PropTypes.number.isRequired,
    update: PropTypes.func
}

export default AddStatus;