import { useTranslation } from "react-i18next";
import TabsComp from "../../Layouts/Tabs";
import History from "./History";
import TableRents from "./Table";

const Main = (props) => {
    const { 
        rent_requests, 
        totalItems, 
        page, 
        pageSize, 
        loading, 
        changePage, 
        type, 
        changeStatus, 
        headers, 
        fibra_id, 
        user_id, 
        update 
    } = props;

    const [t, i18n] = useTranslation('global');

    return (
        <TabsComp
            tablist={[
                t('rents.tabs.history'),
                t('rents.tabs.search'),
            ]}
            tabpanels={[
                <History
                    type={type}
                    rent_requests={rent_requests}
                    totalItems={totalItems}
                    page={page}
                    pageSize={pageSize}
                    loading={loading}
                    changePage={changePage}
                    changeStatus={changeStatus}
                />,
                <TableRents 
                    headers={headers}
                    fibra_id={fibra_id}
                    user_id={user_id}
                    update={update}
                />,
            ]}
        />
    );
}

export default Main