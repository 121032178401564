import { Fragment, useEffect, useState } from "react";
import { EditOutlined, CloudDownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Row, Col } from "react-bootstrap";
import { Card } from 'antd';
import Fetcher from "../../../libs/Petition";
import { useStore } from "react-redux";
import FlexButton from "../../Layouts/FlexButton";
import AddDocument from '../fragments/Documents/AddDocument';
import downloadFile from "../../../libs/DownloadFile";
import { useToast } from "@chakra-ui/toast";
import {useTranslation} from 'react-i18next';

const Documents = () => {
    const store = useStore();
    const toast = useToast();
    const { token, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [docs, setDocs] = useState([]);
    const [modal_add, setModalAdd] = useState(false);
    const [document_selected, setDocumentSelected] = useState(null);
    const [t, i18n]= useTranslation('global');

    useEffect(() => {
        getDocs();
    }, []);

    const getDocs = async () => {
        try {
            let response = await Fetcher({
                url: '/repse/docs', method: 'GET',
                headers, params: { fibra_id: fibra.id }
            });
            // console.log("Docs: ", response);
            if (response.status === 200) {
                setDocs(response.data.data);
            }
        } catch (error) { }
    }

    const openOrCloseModalAdd = () => {
        setModalAdd(!modal_add);
    }

    const edit = (doc) => {
        setDocumentSelected(doc);
        openOrCloseModalAdd();
    }
    const add = () => {
        setDocumentSelected(null);
        openOrCloseModalAdd();
    }

    const downloadDoc = (doc) => {
        // console.log("downloading... ");
        downloadFile({
            url: `/repse/docs/download/${doc.id}`,
            type: 'application/json',
            name: doc.name,
            toast, headers
        });
    }

    return (
        <Fragment>
            {/* Lista los documentos que se solicitan para ser REPSE */}
            <Row xs={1} md={4} className="g-4 mt-2">
                {
                    docs ?
                        docs.map((doc, index) =>
                            <Card key={index} style={{ width: 300, marginTop: 16, marginLeft: 16 }}
                                as={Col} title={doc.name} actions={[
                                    <EditOutlined key="edit" onClick={() => edit(doc)} />,
                                    <CloudDownloadOutlined key="download" onClick={() => downloadDoc(doc)}/>,
                                    <DeleteOutlined key="delete" />,
                                ]}>
                                <p>{doc.description}</p>
                                <p>Tipo de persona: {doc.person_type ? doc.person_type : t('repse.documents.all')}</p>
                                <p>Revisión periódica: {doc.periodic_revision ? `${t('repse.documents.every')} ${doc.periodic_revision} ${t('repse.documents.days')}` : '---'}</p>
                            </Card>

                        ) : null
                }
            </Row>

            {/* FAB  para agregar un nuevo estado */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={add} />

            {/* Modal para agregar o editar los documentos */}
            {
                modal_add ?
                    <AddDocument
                        onClose={openOrCloseModalAdd}
                        update={getDocs}
                        headers={headers}
                        doc={document_selected}
                        fibra_id={fibra.id}
                    /> : null
            }
        </Fragment>
    );
}

export default Documents;