import Fetcher from "../../libs/Petition";

const url = '/cfdi/status/sequences';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param cfdi_status_id Se utiliza el identificador del cfdi status para obtener los status a los que pasa el status actual
 * @returns CfdiStatusSequence::collection() Retorna una colección de objetos de tipo CfdiStatusSequence
 */
export const getCfdiStatusSequences = async ({ headers, cfdi_status_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params: { cfdi_status_id },
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = [];
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param cfdi_status_id Identificador del cfdi_status sobre el que se hará la consulta
 * @returns CfdiStatusSequence Retorna un objeto tipo CfdiStatusSequence
 */
export const getCfdiStatusSequence = async ({ headers, cfdi_status_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}/${cfdi_status_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = [];
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeCfdiStatusSequences = async ({ headers, data }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status !== CREATED)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param cfdi_status_id Identificador del cfdi_status que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateCfdiStatusSequences = async ({ headers, data, cfdi_status_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${cfdi_status_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param cfdi_status_sequence_id Identificador del cfdi_status_sequence que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyCfdiStatusSequences = async ({ headers, cfdi_status_sequence_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${cfdi_status_sequence_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}