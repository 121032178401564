import { Select, useToast } from '@chakra-ui/react';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { destroyCedisUsers, indexCedisUsers, storeCedisUsers } from '../../../api/Cedis/CedisUsers';
import { indexUser } from '../../../api/Users';
import setToast from '../../../libs/SetToast';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import TableComp from '../../Layouts/Table';

const _ = require('underscore');

const UsersAssigned = (props) => {
    const { headers, fibra_id, cedis_id } = props;
    const toast = useToast();
    const [users, setUsers] = useState([]);
    const [usersAvailable, setUsersAvailable] = useState([]);
    const [user_id, setUserID] = useState(null);
    const [userCedis, setUserCedis] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getAdmins()
        getCedisUsers();
    }, []);

    useEffect(() => {
        getUsersAvailable();
    }, [userCedis]);
    // Usuarios que ya han sido asignados
    const getCedisUsers = async () => {
        setLoading(true);
        let response = await indexCedisUsers({ headers, cedis_id });
        setUserCedis(response.data);
        setLoading(false);
    }
    // Administradores que tiene la fibra
    const getAdmins = async () => {
        let response = await indexUser({ headers, fibra_id, role_code: 'ADMINISTRADOR' });
        setUsers(response.data);
    }
    // Usuarios disponibles
    const getUsersAvailable = async () => {
        setUsersAvailable(
            users.filter((user) => {
                let pos = _.findIndex(userCedis, { user_id: user.id });
                return pos === -1 ? true : false;
            }));
    }
    const handleChangeAdmin = (e) => {
        let { value } = e.target;
        setUserID(value);
    }

    const assignUser = async () => {
        let response = await storeCedisUsers({
            headers, data: {
                cedis_id, user_id
            }
        });
        if (response) {
            getCedisUsers();
            setUserID(null);
            setToast({
                title: '¡Nuevo usuario!', status: 'success', toast,
                description: 'Se han asignado permisos para que el usuario acceda a la interfaz "Entregas".'
            });
        }
    }

    const removePermission = async (item) => {
        let response = await destroyCedisUsers({ headers, cedis_user_id: item.id });
        if (response) {
            getCedisUsers();
            setToast({
                title: '¡Permiso revocado!', status: 'error', toast,
                description: 'Se han revocado los permisos para acceder a la interfaz "Entregas".'
            });
        }
    }
    return <Fragment>
        <Row className='mb-3'>
            <Col>
                <Form.Label>Administrador</Form.Label>
                <Select placeholder='Seleccionar administrador' name='user_id' defaultValue={user_id} value={user_id} onChange={handleChangeAdmin}>
                    {
                        usersAvailable.map((user, index) =>
                            <option key={index} value={user.id}>{user.name}</option>
                        )
                    }
                </Select>
            </Col>
            <Col className='d-flex justify-content-start align-items-end'>
                <ButtonIcon name='Asignar administrador' icon={faPlus} variant='outline-success'
                    onClick={assignUser} disabled={user_id ? false : true} />
            </Col>
        </Row>
        <TableComp
            headers={['#', 'Administrador', 'Correo electrónico', 'Asignado desde', 'Acciones']}
            keys={['#', 'user_name', 'user_email', 'created_at']}
            body={userCedis}
            loading={loading}
            actions={[
                { icon: faTrashAlt, tooltip: 'Eliminar del CEDIS', handleClick: removePermission, variant: 'outline-danger' }
            ]}
        />
    </Fragment>;
};

export default UsersAssigned;
