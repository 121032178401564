import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Fragment, useEffect } from "react";
import { destroyUserRoles, indexUserRoles, storeUserRoles } from "../../../api/UserRoles";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import TableComp from "../../Layouts/Table";

const _ = require('underscore');

const ModalUserRoles = (props) => {
    const { onClose, roles, user_id, headers } = props;

    const [userRoles, setUserRoles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getUserRoles();
    }, []);

    const getUserRoles = async () => {
        let response = await indexUserRoles({ headers, user_id });
        setUserRoles(response.data);
        setLoading(false);
    }
    // Evento para asignar módulo a la fibra
    const handleChange = async (checked, role_id) => {
        if (checked) { // Se agrega la relación
            await storeUserRoles({ headers, data: { user_id, role_id } });
        } else { // Se elimina la relación  
            let pos = _.findIndex(userRoles, { role_id });
            if (pos !== -1) // Si el registro se encuentra se elimina
                await destroyUserRoles({ headers, user_role_id: userRoles[pos].id });
        }
    }
    // Método para obtener si el registro existe o no
    const getChecked = (role_id) => {
        let pos = _.findIndex(userRoles, { role_id });
        return pos === -1 ? false : true;
    }
    // Método para indicar si el switch está habilitado
    const getDisabled = () => false;

    return (
        <ModalComp
            onClose={onClose}
            title="Editar acceso a roles"
            size="md"
            body={<Fragment>
                <TableComp
                    headers={['#', 'Código', 'Nombre', 'Acciones']}
                    keys={['#', 'code', 'name']}
                    body={roles}
                    type="switch"
                    actions={{
                        checkedChildren: 'Permitido',
                        unCheckedChildren: 'Denegado',
                        onChange: handleChange,
                        getChecked: getChecked,
                        getDisabled: getDisabled
                    }}
                    loading={loading}
                />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name="Cerrar" onClick={onClose} variant="outline-secondary" />
            </Fragment>}
        />
    );
};

export default ModalUserRoles;
