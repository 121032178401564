import { Heading } from "@chakra-ui/layout";
import { Container, Row, Col } from "react-bootstrap";
import CarParking from "./CarParking";
import Lots from "./Lots";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ParkingLots = (props) => {
    const { mall_id } = props;
    const [parking_lot, setParkingLot] = useState(-1);
    const [t, i18n] = useTranslation('global');
    const prefix = 'malls.parking';

    const handleChange = (_parking_lot) => {
        setParkingLot(_parking_lot);
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Heading as='h4' size='sm' className='mb-2'>{t(`${prefix}.parking-spaces`)}</Heading>
                    {/*  Lista de estacionamientos */}
                    <Lots mall_id={mall_id} handleChange={handleChange}/>
                </Col>
                <Col>
                    <Heading as='h4' size='sm' className='mb-2'>{t(`${prefix}.parking-lots`)}</Heading>
                    {/*  Aparcamientos de cada estacionamiento */}
                    <CarParking parking_lot={parking_lot}/>
                </Col>
            </Row>
        </Container>
    );
}

export default ParkingLots;