import { useEffect, useState } from "react";
import { getProviders } from "../../../api/Providers";
import TableComp from "../../Layouts/Table";

const Providers = ({headers, fibra_id}) => {
    const [providers, setProviders] =useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        let pro = await getProviders({ headers, fibra_id });
        setProviders(pro);
    }

    return (
        <TableComp
            headers={['#', 'Nombre', 'RFC', 'Estatus', 'Acciones']}
        />
    );
}

export default Providers;