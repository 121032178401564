import { Alert, AlertIcon, useToast } from '@chakra-ui/react';
import { faArrowLeft, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Fragment, useEffect } from 'react';
import { updateCedisDeliveryItems } from '../../../api/Cedis/CedisDeliveryItems';
import { showCedisDeliveries, updateCedisDeliveries } from '../../../api/Cedis/Deliveries';
import { updatePurchaseOrderItem } from '../../../api/PurchaseOrderItems';
import setToast from '../../../libs/SetToast';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import ModalComp from '../../Layouts/Modal';
import TabsComp from '../../Layouts/Tabs';
import Items from './Items';
import MainData from './MainData';
import Tracking from './Tracking';

const _ = require('underscore');

const ModalView = (props) => {
    const { onClose, event, headers } = props;

    const toast = useToast();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [itemsDelivered, setItemsDelivered] = useState([]);

    useEffect(() => {
        getEventDetails();
    }, []);

    const getEventDetails = async () => {
        let response = await showCedisDeliveries({ headers, cedis_delivery_id: event.id });
        console.log("REsponse: ", response);
        setData(response);
        setLoading(false);
    }
    // Evento que se lanza al marcar los items que re reciben
    const handleChange = async (checked, purchase_order_item_id) => {
        if (checked)    // Si el evento es marcado el item es entregado
            setItemsDelivered([...itemsDelivered, purchase_order_item_id]);
        else    // Si se desmarca se borra de la lista de entregados
            setItemsDelivered(itemsDelivered.filter((item) => item !== purchase_order_item_id));
    }
    // Botón de confirmar entrega, sólo se toman como recibidos los elementos marcados
    const accept_delivery = async () => {
        // Verificar que almenos un item está marcado
        if (itemsDelivered.length > 0) {
            // Cambiar el status de la entrega
            await updateCedisDeliveries({
                headers,
                data: { status: 'delivered' },
                cedis_delivery_id: event.id
            });
            // Agregar partidas que fueron recibidas
            await itemsDelivered.map(async (item) => {
                await updateCedisDeliveryItems({
                    headers,
                    cedis_delivery_item_id: item,
                    data: { status: 'delivered' }
                });
                let pos = _.findIndex(data.items, { id: item });
                if (pos !== -1) {
                    await updatePurchaseOrderItem({
                        headers,
                        data: { status: 'delivered' },
                        item_id: data.items[pos].purchase_order_item_id
                    });
                }
            })
            // Agregar partidas que no fueron recibidas
            await data?.items.map(async (item) => {
                if (!itemsDelivered.includes(item.id)) {
                    await updateCedisDeliveryItems({
                        headers,
                        cedis_delivery_item_id: item.id,
                        data: { status: 'undelivered' }
                    });
                    await updatePurchaseOrderItem({
                        headers,
                        data: { status: 'undelivered' },
                        item_id: item.purchase_order_item_id
                    });
                }

            });
            setToast({
                title: '¡Partidas recibidas!', status: 'success', toast,
                description: 'A partir de ahora ya puede facturar las partidas que han sido recibidas.'
            });
            onClose();
        } else {
            setToast({
                title: '¡No hay partidas seleccionadas!', status: 'warning', toast,
                description: 'Debe seleccionar al menos una partida para continuar con esta operación.'
            });
        }
    }
    // Botón de entrega rechazad, todos los items pasan a estado "no entregado"
    const reject_delivery = async () => {
        await updateCedisDeliveries({
            headers,
            data: { status: 'rejected' },
            cedis_delivery_id: event.id
        });
        await data?.items && data?.items.map(async (item) => {
            if (!itemsDelivered.includes(item.id)) {
                await updateCedisDeliveryItems({
                    headers,
                    cedis_delivery_item_id: item.id,
                    data: { status: 'undelivered' }
                });
                await updatePurchaseOrderItem({
                    headers,
                    data: { status: 'rejected' },
                    item_id: item.purchase_order_item_id
                });
            }
        });
        setToast({
            title: '¡Entrega rechazada!', status: 'error', toast,
            description: 'Todas las partidas que esta entrega contiene hansido rechazadas.'
        });
        onClose();
    }
    return (
        <ModalComp
            onClose={onClose}
            size='lg'
            title='Vista general'
            body={<Fragment>
                {
                    data?.status === 'delivered' &&
                    <Alert status='success' className='mb-2'><AlertIcon /> Esta entrega ya ha sido aceptada</Alert>
                }
                {
                    data?.status === 'rejected' &&
                    <Alert status='error' className='mb-2'><AlertIcon /> Esta entrega ya ha sido rechazada</Alert>
                }
                {
                    data?.status === 'undelivered' &&
                    <Alert status='warning' className='mb-2'><AlertIcon /> Esta entrega no ha sido recibida</Alert>
                }
                <TabsComp
                    tablist={['Información general', 'Partidas', 'Seguimiento']}
                    tabpanels={[
                        <MainData
                            event={event}
                            loading={loading}
                            data={data} />,
                        <Items
                            items={data?.items || []}
                            loading={loading}
                            handleChange={handleChange}
                            itemsDelivered={itemsDelivered} />,
                        <Tracking
                            tracking_items={data?.tracking || []} />
                    ]}
                />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faArrowLeft} variant='outline-secondary' onClick={onClose} />
                <ButtonIcon name='Rechazar entrega' icon={faTimes} variant='outline-danger' onClick={reject_delivery} disabled={data?.status === 'pending' ? false : true} />
                <ButtonIcon name='Aceptar entrega' icon={faCheck} variant='outline-success' onClick={accept_delivery} disabled={data?.status === 'pending' ? false : true} />
            </Fragment>}
        />
    );
};

export default ModalView;
