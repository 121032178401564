import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { indexAccessControlUser } from "../../../api/AccessControlUsers";
import PaginationComp from "../../Layouts/Pagination";
import TableComp from "../../Layouts/Table";
import ModalViewUser from "./ModalViewUser";

const Users = () => {

    const [t, i18n] = useTranslation('global');
    const prefix = 'access-control.data';
    const store = useStore();
    const { token, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const fibra_id = fibra.id;
    const [loading, setLoading] = useState(true);

    const [users, setUsers] = useState([]);
    const [userSel, setUserSel] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    const [modalView, setModalView] = useState(false);

    useEffect(() => {
        getUserRequests();
    }, []);

    const getUserRequests = async () => {
        let response = await indexAccessControlUser({ headers, page, pageSize, fibra_id });
        console.log("Index access control user: ", response);
        setUsers(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }

    const openOrClose = () => { setModalView(!modalView); };

    const viewDetails = (item) => {
        console.log("Details user: ", item);
        setUserSel(item.id);
        openOrClose();
    }

    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    return (
        <Fragment>
            <TableComp
                headers={[
                    '#',
                    t(`${prefix}.user`),
                    t(`${prefix}.email`),
                    t(`${prefix}.phone-number`),
                    t(`${prefix}.status`),
                    t(`${prefix}.created-at`),
                    t(`${prefix}.actions`),
                ]}
                keys={['id', 'full_name', 'email', 'phone_number', 'status', 'created_at']}
                badges={[{ index: 4, color: 'status_color' }]}
                body={users}
                actions={[
                    { icon: faEye, handleClick: viewDetails, tooltip: t('access-control.tooltips.details') }
                ]}
                loading={loading}
                loadingItems={pageSize}
            />
            <PaginationComp current={page} onChange={changePage} totalItems={totalItems} />
            {/* Modal para vista detallada */}
            {
                modalView &&
                <ModalViewUser
                    onClose={openOrClose}
                    headers={headers}
                    fibra_id={fibra_id}
                    access_control_user_id={userSel}
                    update={getUserRequests}
                />
            }
        </Fragment>
    );
}

export default Users