import { Fragment, useState, useEffect, useRef } from "react";
import { Table, Form } from 'react-bootstrap';
import { Badge } from "@chakra-ui/react";
import ButtonIcon from "../../../Layouts/Forms/ButtonIcon";
import PDFViewerComp from "../../../Layouts/PDFViewer";
import _ from 'underscore';
import Fetcher from "../../../../libs/Petition";
import downloadFile from "../../../../libs/DownloadFile";
import getBlobFile from "../../../../libs/getBlobFile";
import getZipFiles from "../../../../libs/getZipFiles";
import { faArrowLeft, faFileDownload, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { destroyRepseUserDocs, storeRepseUserDocs, uploadRepseUserDocs } from "../../../../api/RepseUsers";
import setToast from "../../../../libs/SetToast";
import { useTranslation } from "react-i18next";

const Docs = (props) => {
    const { headers, user, fibra, toast } = props;

    const [list_docs, setListDocs] = useState([]);  // Documentos del proveedor
    const [docs, setDocs] = useState([]);           // Documento solicitados
    const [docs_zip, setDocsZip] = useState([]);     // Documentos para deescargar como ZIP
    const [file, setFile] = useState(null);         // Archivo seleccionado para la vista previa
    const [view_pdf, setViewPDF] = useState(false); // Mostrar componente de vista previa
    const [checks, setChecks] = useState([]);       // True o False para cada check de cada tupla

    const [fileSel, setFileSel] = useState({});
    // const [fileLoaded, setFileLoaded] = useState(null);
    const inputFile = useRef(null);
    const [t, i18n] = useTranslation('global');
    const prefix = ['repse.users.toasts', 'repse.users.docs-table'];

    useEffect(() => {
        getListDocs();
        getDocs();
    }, []);

    // Obtenemos la lista de documentos que ha proporcionado el proveedor
    const getListDocs = async () => {
        try {
            let response = await Fetcher({
                url: `/repse/user_docs`, method: 'GET',
                headers, params: { user_id: user.id }
            });
            // console.log("response list docs: ", response);
            if (response.status === 200) {
                let { data } = response.data;
                setListDocs(data);
            }
        } catch (error) { setListDocs([]); }
    }

    // Obtenemos la lista de documentos que son requeridos
    const getDocs = async () => {
        try {
            let response = await Fetcher({
                url: '/repse/docs', method: 'GET',
                headers, params: { fibra_id: fibra.id }
            });
            // console.log("response docs: ", response);
            if (response.status === 200) {
                let { data } = response.data;
                setDocs(data);
                setChecks(Array(data.length).fill(false));
            }

        } catch (error) {
            console.log("error: ", error);
            setDocs([]);
        }
    }

    const selectAll = (e) => {
        let { checked } = e.target;
        if (checked) {
            let _docs = docs.filter((doc) => {
                let { id } = doc;
                let pos = _.findIndex(list_docs, { repse_doc_id: id });
                return pos !== -1;
            }).map((doc) => {
                let { id, name } = doc;
                return { id: list_docs[getPosListDocs(id)].id, name }
            });
            setDocsZip(_docs);
            setChecks(Array(checks.length).fill(true));
        } else { setDocsZip([]) }
        setChecks(Array(docs.length).fill(checked));
    }

    const selectItem = (e) => {
        let { id, checked } = e.target;
        let pos = getPosListDocs(parseInt(id));
        let posDocs = getPosDocs(parseInt(id));
        if (checked) { //Se agregar el documento a al arreglo docs_zip
            setDocsZip(docs_zip => [...docs_zip, { //Se agrega el {id, name} del doc marcado
                id: list_docs[pos].id, name: docs[getPosDocs(parseInt(id))].name
            }]);
            setChecks( // Actualización de checks
                checks.map((check, index) => { return index !== posDocs ? check : true; })
            );
        } else { //Se elimina de docs_zip
            setDocsZip( //Se quita el archivo que se desmarcó
                docs_zip.filter(doc => { return doc.id !== list_docs[pos].id; })
            );
            setChecks( //Se actualizan los checks
                checks.map((check, index) => { return index !== posDocs ? check : false; })
            );
        }
    }

    // Obtiene la posición del documento seleccionado en el array de documentos proporcionados por el proveedor
    const getPosListDocs = (id) => { return _.findIndex(list_docs, { repse_doc_id: id }); }
    const getPosDocs = (id) => { return _.findIndex(docs, { id }); }

    // Obtiene "NA" en caso de que el documento aún no haya sido proporcionado por el proveedor
    const getStatus = (id) => {
        let res = "NA", pos = getPosListDocs(id);
        if (pos !== -1) {
            switch (list_docs[pos].status) {
                case 'validating': res = <Badge colorScheme="orange">Validando</Badge>; break;
                case 'accepted': res = <Badge colorScheme="green">Válido</Badge>; break;
                case 'rejected': res = <Badge colorScheme="red">Rechazado</Badge>; break;
                default: res = "NA"; break;
            }
        }
        return res;
    }

    // Obtiene la fecha en la que el proveedor cargó su documento
    const getFecha = (id) => {
        let res = "PENDIENTE", pos = getPosListDocs(id);
        if (pos !== -1) res = list_docs[pos].created_at;
        return res;
    }

    // Descarga el archivo que el proveedor proporcionó
    const getFile = (doc) => {
        let pos = getPosListDocs(doc.id);
        let id = list_docs[pos].id;
        downloadFile({
            url: `/repse/user_docs/download/${id}`,
            type: 'application/json',
            name: `${doc.name}`, toast, headers
        });
    }


    const getBFile = async (id) => {
        let pos = getPosListDocs(id);
        let blob_file = await getBlobFile({ url: `repse/user_docs/download/${list_docs[pos].id}`, headers });
        setFile(blob_file)
    }

    // Cambiar el status del documento enviado por el proveedor
    const handleChange = async (checked, id) => {
        let pos = getPosListDocs(id);
        try {
            let response = await Fetcher({
                url: `repse/user_docs/${list_docs[pos].id}`, method: 'PUT',
                headers, data: { status: checked }
            });
            if (response.status === 200) getListDocs();
        } catch (error) { }
    }

    // Vista previa del documento proporcionado por el proveedor
    const viewPDF = (doc) => { setViewPDF(true); getBFile(doc.id); }

    // Descarga multiple de archivos
    const multipleDownload = async () => {
        getZipFiles({ docs: docs_zip, headers, url: 'repse/user_docs/download', name: `${user.first_name} ${user.last_name}_REPSE_DOCS [${moment().format('lll')}]` });
    }
    // Habilita la función handleChange
    const uploadFile = (item) => {
        setFileSel(item);
        inputFile.current.click();
    }

    const handleChangeFile = async (e) => {
        // console.log("FILE: ", e.target.files[0]);
        let pos = _.findIndex(list_docs, { repse_doc_id: fileSel.id });
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        if (pos === -1) {
            // Agregar registro y subir archivo
            let storerepse = await storeRepseUserDocs({ headers, data: { user_id: user.id, repse_doc_id: fileSel.id } });
            // console.log("Store repse: ", storerepse);
            if (storerepse) {
                let response = await uploadRepseUserDocs({ headers, data: formData, user_doc_id: storerepse.id });
                if (response) {
                    getListDocs();
                    setToast({
                        title: t(`${prefix[0]}.success-load.title`), status: 'success', toast,
                        description: t(`${prefix[0]}.success-load.description`)
                    });
                } else {
                    setToast({
                        title: t(`${prefix[0]}.error-con.title`), status: 'error', toast,
                        description: t(`${prefix[0]}.error-con.description`)
                    });
                    // Elimina el registro ya que el archivo no se pudo cargar
                    await destroyRepseUserDocs({ headers, user_doc_id: storerepse.id });
                }
            }
        } else {
            // Subir archivo con el registro existente
            let response = await uploadRepseUserDocs({ headers, data: formData, user_doc_id: list_docs[pos].id });
            if (response) {
                getListDocs();
                setToast({
                    title: t(`${prefix[0]}.success-load.title`), status: 'success', toast,
                    description: t(`${prefix[0]}.success-load.description`)
                });
            } else {
                setToast({
                    title: t(`${prefix[0]}.error-con.title`), status: 'error', toast,
                    description: t(`${prefix[0]}.error-con.description`)
                });
            }
        }
    }
    // Deshabilitar la carga de archivos si están en estado de validación o aceptados
    const disabledUpload = (doc_id) => {
        let pos = getPosListDocs(doc_id);
        let response = false;
        if (pos !== -1) {
            if (list_docs[pos].status === 'accepted' || list_docs[pos].status === 'validating')
                response = true;
        }
        return response;
    }

    return (
        <Fragment>
            <input
                ref={inputFile}
                type="file"
                onChange={handleChangeFile}
                style={{ display: "none" }}
            />
            {
                view_pdf ? <PDFViewerComp file={file} /> :
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                {/* Seleccionar todos  */}
                                <th><Form.Check type='checkbox' onChange={selectAll} defaultChecked={false} /></th>
                                <th>#</th>
                                <th>{t(`${prefix[1]}.doc`)}</th>
                                <th>{t(`${prefix[1]}.status`)}</th>
                                <th>{t(`${prefix[1]}.date`)}</th>
                                <th>{t(`${prefix[1]}.actions`)}</th>
                                <th>{t(`${prefix[1]}.load`)}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                docs ? docs.map((doc, index) =>
                                    <tr key={index}>
                                        {/* El checkbox indica que elemento se agregará al zip para serdescargado */}
                                        <td><Form.Check type='checkbox' id={doc.id} onChange={selectItem} checked={checks[index]} defaultChecked={false} disabled={getStatus(doc.id) === "NA" ? true : false} /></td>
                                        <td>{index + 1}</td>
                                        <td>{doc.name}</td>
                                        <td>{getStatus(doc.id) === "NA" ? <Badge colorScheme="purple">Pendiente</Badge> : getStatus(doc.id)}</td>
                                        <td>{getFecha(doc.id)}</td>
                                        <td>
                                            <ButtonIcon variant="outline-secondary" onClick={() => getFile(doc)} disabled={getStatus(doc.id) === "NA" ? true : false} icon={faFileDownload} />
                                            <ButtonIcon variant="outline-secondary" onClick={() => viewPDF(doc)} disabled={getStatus(doc.id) === "NA" ? true : false} /></td>
                                        <td>
                                            <ButtonIcon
                                                name={t(`${prefix[1]}.upload`)} icon={faFileUpload} variant='outline-success' tooltipDisabled
                                                disabled={disabledUpload(doc.id)} onClick={() => uploadFile(doc)}
                                            />
                                        </td>
                                    </tr>
                                ) : null
                            }
                        </tbody>
                    </Table>
            }
            {
                !view_pdf ?
                    // Si hay documentos seleccionados, se muestra el botón de descarga múltiple
                    docs_zip.length > 0 ? <ButtonIcon variant="outline-success" icon={faFileDownload} onClick={multipleDownload} name={t(`${prefix[1]}.download`)} /> : null
                    : <ButtonIcon variant="outline-secondary" onClick={() => setViewPDF(false)} name={t(`${prefix[1]}.back`)} icon={faArrowLeft} />
            }
        </Fragment>
    );
}

export default Docs;