import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import TableRents from "./fragments/Table";

const AdminContracts = () => {
    const store = useStore();
    const { token, user, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [t, i18n] = useTranslation('global');
    const prefix = 'contracts.data';
    return (
        <TableRents
            params={{ fibra_id: fibra.id }}
            theaders={[
                '#',
                t(`${prefix}.contract`),
                t(`${prefix}.local`),
                t(`${prefix}.start_date`),
                t(`${prefix}.end_date`),
                t(`${prefix}.fixed_rent`),
                t(`${prefix}.actions`)
            ]}
            keys={['id', 'user', 'name', 'local_mall', 'start_date', 'end_date', 'renta_fija']}
            currency={[{ index: 6 }]}
        />
    );
}

export default AdminContracts