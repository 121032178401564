import { Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormIcon from "../../Layouts/Forms/FormIcon";

const MallData = (props) => {
    const { code, name } = props.mall;
    const [t, i18n] = useTranslation('global');
    const prefix = 'rents.forms';

    return (
        <Form>
            <Row className='mb-3'>
                <FormIcon title={t(`${prefix}.mall-code`)} value={code} />
                <FormIcon title={t(`${prefix}.mall-name`)} value={name} />
            </Row>
        </Form>
    );
}

export default MallData