import Fetcher from "../libs/Petition"

export const getProviders = async ({ headers, fibra_id }) => {
    let response = [];
    try {
        response = await Fetcher({
            url: `/users`,
            method: 'GET',
            params: { fibra_id },
            headers
        });
    } catch (error) {
        response = [];
    } finally {
        return response;
    }
}