import { Fragment, useEffect, useState } from "react";
import { useStore } from "react-redux";
import IncidentsEdit from "./ModalEdit";
import TableComp from "../../Layouts/Table";
import PaginationComp from "../../Layouts/Pagination";
import ModalIncident from "./Modal";
import { Heading } from "@chakra-ui/react";
import { PageHeader, Select } from 'antd';
import { useTranslation } from "react-i18next";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { indexIncidentType } from "../../../api/Incidents/IncidentTypes";

const { Option } = Select;

const IncidentsTable = (props) => {
    const { is_admin, page, pageSize, totalItems, changePage, incidents, loading, update, changeStatus, changeIncidentType, theaders, tkeys, tbadges } = props;
    const store = useStore();
    const { token, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [incident_selected, setIncidentSelected] = useState(-1);
    const [incidentTypes, setIncidentTypes] = useState([]);

    const [modalView, setModalView] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [actions, setActions] = useState([]);

    const [t, i18n] = useTranslation('global');
    const p_tooltip = 'incidents.table.tooltips';
    const p_filters = 'incidents.filters';

    useEffect(() => {
        getIncidentTypes();
        if (is_admin) {
            setActions([
                { icon: faEye, handleClick: viewDetails, tooltip: t(`${p_tooltip}.details`) },
                { icon: faEdit, handleClick: editIncident, tooltip: t(`${p_tooltip}.tracing`), variant: 'outline-success' }
            ]);
        } else {
            setActions([
                { icon: faEye, handleClick: viewDetails, tooltip: t(`${p_tooltip}.details`) }
            ]);
        }
    }, []);

    const getIncidentTypes = async () => {
        let response = await indexIncidentType({ headers, fibra_id: fibra.id });
        console.log("Incident types: ", response);
        setIncidentTypes(response.data);
    }
    const viewDetails = (i) => {
        setIncidentSelected(i.id);
        openOrCloseModal();
    }

    const editIncident = (i) => {
        setIncidentSelected(i.id);
        openOrCloseModalEdit();
    }
    const openOrCloseModal = () => { setModalView(!modalView); }

    const openOrCloseModalEdit = () => { setModalEdit(!modalEdit); }

    return (
        <Fragment>
            <PageHeader
                ghost={true}
                onBack={() => window.history.back()}
                title={<Heading>{t('incidents.title')}</Heading>}
                extra={[
                    <Select placeholder={t(`${p_filters}.status`)} onChange={changeStatus} name='status' style={{ width: 180 }}>
                        <Option value={null}>{t(`${p_filters}.all`)}</Option>
                        <Option value='pendiente'>{t(`${p_filters}.pending`)}</Option>
                        <Option value='atendiendo'>{t(`${p_filters}.attending`)}</Option>
                        <Option value='cancelado'>{t(`${p_filters}.canceled`)}</Option>
                        <Option value='resuelto'>{t(`${p_filters}.solved`)}</Option>
                    </Select>,
                    <Select placeholder={t(`${p_filters}.incident-type`)} onChange={changeIncidentType} name='incident_type_id' style={{ width: 180 }}>
                        <Option value={null}>{t(`${p_filters}.all`)}</Option>
                        {
                            incidentTypes && incidentTypes.map((incidentType, index) =>
                                <Option key={index} value={incidentType.id}>{incidentType.code} - {incidentType.name}</Option>
                            )
                        }
                    </Select>
                ]}
            >
            </PageHeader>
            <TableComp
                headers={theaders}
                keys={tkeys}
                body={incidents}
                loading={loading}
                loadingItems={pageSize}
                actions={actions}
                badges={tbadges}
            />
            {/* Componenete de paginación */}
            <PaginationComp totalItems={totalItems} onChange={changePage} current={page} />

            {/* Modal de vista detallada de los incidentes */}
            {
                modalView ?
                    <ModalIncident
                        headers={headers}
                        fibra_id={fibra.id}
                        incident_id={incident_selected}
                        onClose={openOrCloseModal}
                    /> : null
            }

            {/* Modal para editar incidente */}
            {
                modalEdit ?
                    <IncidentsEdit
                        incident_id={incident_selected}
                        headers={headers}
                        onClose={openOrCloseModalEdit}
                        update={update}
                    /> : null
            }
        </Fragment>
    );
}

export default IncidentsTable;