import { Fragment, useEffect, useState } from "react";
import Fetcher from "../../../libs/Petition";
import TableComp from "../../Layouts/Table";
import { faBoxes, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import FlexButton from "../../Layouts/FlexButton";
import ModalRoles from "../fragments/ModalRoles";
import ModalStatuses from "../fragments/ModalStatuses";
import { useDispatch } from "react-redux";
import { getCfdiStatuses } from "../../../api/Cfdi/CfdiStatuses";

const Roles = (props) => {
    const { headers, fibra } = props;
    const [roles, setRoles] = useState([]);
    const [role_selected, setRoleSelected] = useState(null);
    const dispatch = useDispatch();
    // Modal para ver, modificar y agregar nuevo role
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [modal_title, setModalTitle] = useState('');
    const [type, setType] = useState('add');
    // Modal para administrar los estatus cfdi
    const [modal_statuses, setModalStatuses] = useState(false);

    useEffect(() => {
        getRoles();
        // console.log("roles effect: ", roles);
    }, []);

    const getRoles = async () => {
        setLoading(true);
        try {
            let response = await Fetcher({
                url: `/roles`, method: 'GET', headers, params: { fibra_id: fibra.id }
            });
            // console.log("Roles response: ", response);
            if (response.status === 200) {
                setRoles(response.data.data);
            }
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    const showDetails = (item) => {
        setModalTitle('Detalles del rol');
        setType('details');
        setRoleSelected(item);
        openOrClose();
    }

    const updateRol = (item) => {
        setModalTitle('Modificar rol');
        setType('update');
        setRoleSelected(item);
        openOrClose();
    }

    const addRole = (item) => {
        setModalTitle('Agregar nuevo rol');
        setType('add');
        setRoleSelected({ name: '', code: '', description: '' });
        openOrClose();
    }

    const showStatuses = (item) => {
        setRoleSelected(item);
        openOrCloseStatuses();
    }
    const openOrClose = () => { setModal(!modal); }
    const openOrCloseStatuses = () => {
        setModalStatuses(!modal_statuses);
        if(modal_statuses){
            getStatuses(role_selected);
        }
    }

    // Se obtienen los cfdi_statuses en base al rol seleccionado
    const getStatuses = async (role) => {
        let response = await getCfdiStatuses({ headers, fibra_id: fibra.id, role_id: role.id });
        dispatch({ type: 'SET_CFDI_STATUSES', cfdi_statuses: response });
    }
    return (
        <Fragment>
            {/* Tabla para mostrar los roles de usuarios */}
            <TableComp
                headers={['#', 'Nombre', 'Descripción', 'Fecha', 'Acciones']}
                keys={['id', 'name', 'description', 'created_at']}
                body={roles}
                actions={[
                    // { handleClick: [Método que ejecuta], icon: [ícono], tooltip: [Mensaje de ayuda], variant: [estilo del button] } 
                    { handleClick: showDetails, icon: faEye, tooltip: 'Ver detalles', variant: 'outline-primary' },
                    { handleClick: updateRol, icon: faEdit, tooltip: 'Modificar rol', variant: 'outline-success' },
                    { handleClick: showStatuses, icon: faBoxes, tooltip: 'Estatus cfdi a los que puede acceder', variant: 'outline-secondary' },
                ]}
                loading={loading}
            />
            {/* FAB para agregar un nuevo rol */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={addRole} />

            {/* Modal para actualizar y agregar rol */}
            {
                modal ?
                    <ModalRoles
                        onClose={openOrClose}
                        title={modal_title}
                        role={role_selected}
                        type={type}
                        headers={headers}
                        update={getRoles}
                        fibra={fibra}
                    /> : null
            }
            {/* Modal para los status CFDi a los que pueden acceder los roles */}
            {
                modal_statuses ?
                    <ModalStatuses
                        onClose={openOrCloseStatuses}
                        role={role_selected}
                        headers={headers}
                        fibra_id={fibra.id}
                    /> : null
            }
        </Fragment>
    );
}

export default Roles;