import Fetcher from "../libs/Petition"

const url = '/roles';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Se utiliza el identificador de la fibra para obtener los roles que le corresponden
 * @returns Role::collection() Retorna una colección de objetos de tipo Role
 */
export const indexRoles = async ({ headers, fibra_id }) => {
    let response = {
        data: [],
        totalItems: 0
    };
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params: { fibra_id },
            headers
        });
        // console.log("Roles:: ", fetch.data);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = {
            data: [],
            totalItems: 0
        };
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param role_id Identificador del rol sobre el que se hará la consulta
 * @returns Role Retorna un objeto tipo Role
 */
export const showRole = async ({ headers, role_id }) => {
    let response = {
        data: [],
        totalItems: 0
    };
    try {
        let fetch = await Fetcher({
            url: `${url}/${role_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = {
            data: [],
            totalItems: 0
        };
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeRole = async ({ headers, data }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status !== CREATED)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param role_id Identificador del rol que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateRole = async ({ headers, data, role_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${role_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param role_id Identificador del rol que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyRole = async ({ headers, role_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${role_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}