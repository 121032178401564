import Fetcher from "../libs/Petition"

const url = '/users';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Se utiliza el identificador de la fibra para obtener los usuarios que le corresponden
 * @param role_code [Opcional] Obtiene los registros de un role segúnsu código
 * @returns User::collection() Retorna una colección de objetos de tipo User
 */
export const indexUser = async ({ headers, fibra_id, role_code, page, pageSize }) => {
    let response = { data: [], totalItems: 0 };
    try {
        let params = { fibra_id };
        if (role_code) params.role_code = role_code;
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        console.log("Users [index]", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = { data: [], totalItems: 0 }
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param user_id Identificador del usuario sobre el que se hará la consulta
 * @returns User Retorna un objeto tipo User
 */
export const showUser = async ({ headers, user_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}/${user_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = [];
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeUser = async ({ headers, data }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status !== CREATED)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param user_id Identificador del usuario que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateUser = async ({ headers, data, user_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${user_id}`,
            method: 'PUT',
            data,
            headers
        });
        // console.log("Update USers [fetch]: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param user_id Identificador del usuario que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyUser = async ({ headers, user_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${user_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}