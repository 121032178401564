import { Select, useToast } from '@chakra-ui/react';
import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { updateUser } from '../../../../api/Users';
import setToast from '../../../../libs/SetToast';
import ButtonIcon from '../../../Layouts/Forms/ButtonIcon';
import ModalComp from '../../../Layouts/Modal';

const ChangeStatuses = (props) => {
    const { onClose, userSel, statuses, headers, update } = props;
    const [repseStatusID, setRepseStatusID] = useState(null);
    const toast = useToast();
    const [t, i18n] = useTranslation('global');
    const prefix = ['repse.matrix.toasts', 'repse.matrix.update-modal'];

    useEffect(() => {
        setRepseStatusID(userSel?.status_repse?.id);
    }, []);

    const handleChangeStatus = (e) => {
        let { value } = e.target;
        setRepseStatusID(value);
    }
    const updateStatus = async () => {
        if (repseStatusID) {
            let response = await updateUser({
                headers,
                data: { status_repse_id: repseStatusID },
                user_id: userSel.id
            });
            if (response) {
                setToast({
                    title: t(`${prefix[0]}.success.title`), status: 'success', toast,
                    description: t(`${prefix[0]}.success.description`)
                });
                update();
            } else {
                setToast({
                    title: t(`${prefix[0]}.error.title`), status: 'error', toast,
                    description: t(`${prefix[0]}.error.description`)
                });
            }
            onClose();
        } else {
            setToast({
                title: t(`${prefix[0]}.warning.title`), status: 'warning', toast,
                description: t(`${prefix[0]}.warning.description    `)
            });
        }
    }
    return (
        <ModalComp
            title={t(`${prefix[1]}.title`)}
            onClose={onClose}
            body={<Fragment>
                <Row className='mb-3'>
                    <Col>
                        <Form.Label>{t(`${prefix[1]}.repse-status`)}</Form.Label>
                        <Select placeholder={t(`${prefix[1]}.select`)} name='repse_status_id' value={repseStatusID} onChange={handleChangeStatus}>
                            {
                                statuses.map((status, index) =>
                                    <option key={index} value={status.id}>{status.name}</option>
                                )
                            }
                        </Select>
                    </Col>
                </Row>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name={t(`${prefix[1]}.button-close`)} variant='outline-secondary' icon={faArrowLeft} onClick={onClose} />
                <ButtonIcon name={t(`${prefix[1]}.button-update`)} variant='outline-success' icon={faSave} onClick={updateStatus} />
            </Fragment>}
        />
    );
}

export default ChangeStatuses