import downloadFile from "../../libs/DownloadFile";
import Fetcher from "../../libs/Petition";

const url = '/repse/users';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Se utiliza el identificador de la fibra para obtener los roles que le corresponden
 * @param page [Opcional] Se utiliza para conocer la página en la que se encuentra
 * @param pageSize [Opcional] Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @returns Object {"data" => ComplaintSuggestionBox::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexRepseUsers = async (props) => {
    const {
        headers,
        fibra_id,
        person_type,
        page,
        pageSize } = props;
    let response = { data: [], totalItems: 0 };
    try {
        // Parámetros requeridos
        let params = { fibra_id };
        // Parámetros opcionales
        if (person_type) params.person_type = person_type;
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("Fetcher REPSE USERS: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param repse_user_id Identificador de la queja o sugerencia sobre el que se hará la consulta
 * @returns Incident Retorna un objeto tipo Incident
 */
export const showRepseUsers = async ({ headers, repse_user_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}/${repse_user_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeRepseUsers = async ({ headers, data }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status !== CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param repse_user_id Identificador de la queja o sugerencia que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateRepseUsers = async ({ headers, data, repse_user_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${repse_user_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = true;
        // console.log("Fetch complain suggestion: ", fetch);
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param repse_user_id Identificador de la queja o sugerencia que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyRepseUsers = async ({ headers, repse_user_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${repse_user_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param repse_user_id Identificador de la queja o sugerencia que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
// export const uploadRepseUsers = async ({ headers, data, repse_user_id }) => {
//     let response = false;
//     try {
//         let fetch = Fetcher({
//             url: `${url}/upload/${repse_user_id}`,
//             method: 'POST',
//             data,
//             headers
//         });
//         if (fetch.status === STATUS_OK)
//             response = fetch.data.data
//     } catch (error) {
//         response = false;
//     } finally {
//         return response;
//     }
// }
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param name Nombre con el que se va a descargar el archhivo
 * @param toast Instancia del hook toast de Chakra UI
 * @param repse_user_id Identificador de la queja o sugerencia que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
// export const downloadRepseUsers = async ({ headers, repse_user_id, name, toast }) => {
//     try {
//         downloadFile({
//             headers,
//             url: `${url}/download/${repse_user_id}`,
//             type: 'application/json',
//             name,
//             toast
//         });
//     } catch (error) {
//         console.log("Error: ", error);
//     }
// }