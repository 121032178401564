import { Container } from "react-bootstrap";
import '../../styles/BillingReports.Component.css';
import { Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const BillingReports = () => {
    const [t, i18n] = useTranslation('global');

    return(
        <Container id='billing-reports-component' fluid>
            <Heading>{t('billing-reports.title')}</Heading>
        </Container>
    );
}

export default BillingReports;