import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { destroyCfdiStatusSequences, getCfdiStatusSequences, storeCfdiStatusSequences } from "../../../api/Cfdi/CfdiStatusSequences";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import TableComp from "../../Layouts/Table";
import _ from 'underscore';

const ModalStatusSequence = (props) => {
    const { onClose, cfdi_status, statuses, headers } = props;
    const [status_sequences, setStatusSequences] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getSequences();
    }, []);

    const getSequences = async () => {
        let response = await getCfdiStatusSequences({ headers, cfdi_status_id: cfdi_status.id });
        setStatusSequences(response);
        setLoading(false);
    }
    const getChecked = (cfdi_status_id) => {
        let pos = _.findIndex(status_sequences, { sequence_status_id: cfdi_status_id });
        return pos !== -1 ? true : false;
    }

    const handleChange = async (checked, cfdi_status_id) => {
        if (checked) { // Se agrega la relación
            await storeCfdiStatusSequences({ headers, data: { cfdi_status_id: cfdi_status.id, sequence_status_id: cfdi_status_id } });
        } else { // Se elimina la relación
            let pos = _.findIndex(status_sequences, { sequence_status_id: cfdi_status_id });
            if (pos !== -1) // Si el registro se encuentra se elimina
                await destroyCfdiStatusSequences({ headers, cfdi_status_sequence_id: status_sequences[pos].id });
        }
    }
    // Se elimina el cfdi estatus actual, ya que no puede transicionar a sí mismo
    const filterStatuses = () => {
        return statuses.filter((status) => {
            return status.id !== cfdi_status.id ? true : false;
        });
    }

    const getDisabled = () => {
        return false;
    }

    return (
        <ModalComp
            onClose={onClose}
            title={`CFDi: ${cfdi_status?.name}`}
            body={<Fragment>
                <TableComp
                    headers={['#', 'Estatus CFDi', 'Activar']}
                    keys={['id', 'name']}
                    body={filterStatuses()}
                    type='switch'
                    actions={{
                        checkedChildren: 'Activado',
                        unCheckedChildren: 'Desactivado',
                        onChange: handleChange,
                        getChecked: getChecked,
                        getDisabled: getDisabled
                    }}
                    loading={loading}
                />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name='Cerrar' variant='outline-secondary' onClick={onClose} />
                {/* <ButtonIcon icon={faSave} name='Guardar y cerrar' variant='outline-primary' onClick={onClose} /> */}
            </Fragment>}
        />
    );
}

export default ModalStatusSequence;