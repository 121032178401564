import { CLOSE_SESSION, OPEN_SESSION, SET_ROLE_SELECTED, SET_MODULES } from '../actions/main';
import { stateLogin as initialState } from '../initialState/main';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_SESSION:
            return {
                ...state,
                session: action.session,
                token: action.token,
                apps: action.apps,
                roles: action.roles,
                user: action.user,
                role_selected: action.role_selected,
                modules: action.modules,
                fibra: action.fibra
            };
        case CLOSE_SESSION:
            return {
                state: undefined
            };
        case SET_ROLE_SELECTED:
            return {
                ...state,
                role_selected: action.role_selected
            };
        case SET_MODULES:
            return {
                ...state,
                modules: action.modules
            };
        default: return state;
    }
}

export default reducer;