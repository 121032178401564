import { Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormIcon from "../../Layouts/Forms/FormIcon";

const LocalData = (props) => {
    const {
        code,
        name,
        flat,
        price_by_m2,
        price_total,
        m2
    } = props.local_mall;
    
    const [t, i18n] = useTranslation('global');
    const prefix = 'rents.forms';
    return (
        <Form>
            <Row className='mb-3'>
                <FormIcon title={t(`${prefix}.code`)} value={code} />
                <FormIcon title={t(`${prefix}.name`)} value={name} />
                <FormIcon title={t(`${prefix}.flat`)} value={flat} />
            </Row>
            <Row className='mb-3'>
                <FormIcon title={t(`${prefix}.m2`)} value={m2} />
                <FormIcon icon='$' title={t(`${prefix}.price-by-m2`)} value={price_by_m2} />
                <FormIcon icon='$' title={t(`${prefix}.price-total`)} value={price_total} />
            </Row>
        </Form>
    );
}

export default LocalData