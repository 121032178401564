import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { destroyQuizzQuestions, storeQuizzQuestions } from "../../../api/Quizzs/QuizzQuestions";
import { showQuizz, updateQuizz } from "../../../api/Quizzs/Quizzs";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import SkeletonForm from "../../Layouts/Skeletons/Form";
import TabsComp from "../../Layouts/Tabs";
import Main from "../forms/Main";
import Questions from "../forms/Questions";

const Details = (props) => {
    const { onClose, quizzSel, headers, update } = props;
    const [quizz, setQuizz] = useState({});
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [questions, setQuestions] = useState([]);
    const [questionsDel, setQuestionsDel] = useState([]);
    const [t, i18n] = useTranslation('global');
    const prefix = 'polls.modal-details';

    useEffect(() => {
        getQuizz();
    }, []);

    useEffect(() => {
        // console.log("DATA::: ", data);
    }, [data])
    const getQuizz = async () => {
        let response = await showQuizz({ headers, quizz_id: quizzSel.id });
        // console.log("Quizz: ", response);
        setQuizz(response);
        setQuestions(response?.quizz_questions);
        setLoading(false);
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const updateData = async () => {
        // Actualizar datos generales
        let response = await updateQuizz({ headers, data, quizz_id: quizzSel.id });
        // Agregar nuevas preguntas
        questions.map(async (q) => {
            if (!q.id) {
                await storeQuizzQuestions({ headers, data: { question: q.question, quizz_id: quizzSel.id } });
            }
        });
        // Eliminar preguntas
        questionsDel.map(async (q) => {
            await destroyQuizzQuestions({ headers, quizz_question_id: q.id });
        });
        // console.log("UPADTE: ", response, data);
        if (response) {
            update(); onClose();
            Swal.fire(t(`${prefix}.swals.success.title`), t(`${prefix}.swals.success.description`), 'success');
        } else {
            Swal.fire(t(`${prefix}.swals.danger.title`), t(`${prefix}.swals.danger.description`), 'danger');
        }
    }

    return (
        <ModalComp
            onClose={onClose}
            title={quizzSel.name}
            size='lg'
            body={<Fragment>
                {
                    loading ? <SkeletonForm cols={3} rows={5} /> :
                        <TabsComp
                            tablist={[t(`${prefix}.tabs.general`), t(`${prefix}.tabs.questions`)]}
                            tabpanels={[
                                <Main quizz={quizz} data={data} handleChange={handleChange} />,
                                <Questions questions={questions} setQuestions={setQuestions}
                                    questionsDel={questionsDel} setQuestionsDel={setQuestionsDel} />
                            ]}
                        />
                }

            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name={t(`${prefix}.button-close`)} onClick={onClose} variant='outline-secondary' />
                <ButtonIcon icon={faSave} name={t(`${prefix}.button-save`)} onClick={updateData} variant='outline-primary' />
            </Fragment>}
        />
    );
}

export default Details;