import { Heading } from "@chakra-ui/layout";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TableComp from "../Layouts/Table";

const CouponsClient = () => {
    const [t, i18n] = useTranslation
    ('global');
    const prefix = 'coupons.data';
    return (
        <Container fluid>
            <Heading>{t('coupons.title')}</Heading>
            <TableComp
                headers={[
                    '#',
                    t(`${prefix}.subject`),
                    t(`${prefix}.type`),
                    t(`${prefix}.created-at`),
                    t(`${prefix}.actions`)
                ]}
            />
        </Container>
    );
}

export default CouponsClient;