import { Modal, Button } from "react-bootstrap";
import { Fragment, useEffect, useState } from "react";
import FormInvoice from "./FormInvoice";
import ModalComp from "../../Layouts/Modal";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { showInvoice } from "../../../api/Cfdi/Mailbox";
import { useTranslation } from "react-i18next";

const ModalView = ({ onClose, invoice, headers, is_admin }) => {

    const [details, setDetails] = useState({});         // Factura con todos sus datos
    const [loading, setLoading] = useState(true);
    const [t, i18n] = useTranslation('global');

    const prefix = 'mailbox.modal-details';

    useEffect(() => {
        getDetails();
    }, []);

    const getDetails = async () => {
        let response = await showInvoice({ headers, invoice_id: invoice.id });
        console.log("Show invoice: ", response);
        setDetails(response);
        setLoading(false);
    }

    return (
        <ModalComp
            onClose={onClose}
            size='lg'
            title={`${invoice.folio} - ${invoice.nombre_receptor}`}
            body={
                <FormInvoice data={details} loading={loading} />
            }
            footer={
                <Fragment>
                    <ButtonIcon icon={faArrowLeft} onClick={onClose} name={t(`${prefix}.button-close`)} variant='outline-secondary' />
                    {
                        is_admin ? 
                        null
                        : null
                    }
                </Fragment>
            }
        />
    );
}

export default ModalView;