import { faBoxes, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { indexRoles } from "../../../api/Roles";
import { indexUser } from "../../../api/Users";
import PaginationComp from "../../Layouts/Pagination";
import TableComp from "../../Layouts/Table";
import ModalUserEdit from "../fragments/ModalUserEdit";
import ModalUserRoles from "../fragments/ModalUsersRoles";

const Users = (props) => {
    const { fibra_id, headers } = props;

    const [users, setUsers] = useState([]);
    const [userSel, setUserSel] = useState(null);
    const [roles, setRoles] = useState([]);
    const [totalItems, setTotalItems] = useState(0);        // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                    // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);           // Cantidad de registros por página
    const [loading, setLoading] = useState(true);

    const [modalEdit, setModalEdit] = useState(false);
    const [modalRoles, setModalRoles] = useState(false);

    useEffect(() => {
        getUsers();
        getRoles();
    }, []);

    const getRoles = async () => {
        let response = await indexRoles({ headers, fibra_id });
        setRoles(response.data);
    }
    const getUsers = async () => {
        let response = await indexUser({ headers, fibra_id });
        console.log("Users: ", response);
        setUsers(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }

    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const openOrCloseEdit = () => setModalEdit(!modalEdit);
    const openOrCloseRoles = () => setModalRoles(!modalRoles);

    const updateRoles = (item) => {
        setUserSel(item);
        openOrCloseRoles();
    }

    const updateUser = (item) => {
        openOrCloseEdit();
    }

    return (
        <Fragment>
            <TableComp
                headers={[
                    '#',
                    'Nombre',
                    'Correo electrónico',
                    'Fecha de afiliación',
                    'Acciones'
                ]}
                keys={['id', 'name', 'email', 'created_at']}
                actions={[
                    {
                        handleClick: updateUser, icon: faEdit,
                        tooltip: 'Ver y editar datos de usuario', variant: 'outline-success'
                    },
                    {
                        handleClick: updateRoles, icon: faBoxes,
                        tooltip: 'Roles a los que puede acceder', variant: 'outline-secondary'
                    },
                ]}
                body={users}
                loading={loading}
                loadingItems={pageSize}
            />
            <PaginationComp current={page} onChange={changePage} totalItems={totalItems} />
            {/* Modal para editar datos de usuario */}
            {
                modalEdit &&
                <ModalUserEdit
                    onClose={openOrCloseEdit}
                />
            }
            {/* Modal para asignar permisos a los roles */}
            {
                modalRoles &&
                <ModalUserRoles
                    onClose={openOrCloseRoles}
                    roles={roles}
                    headers={headers}
                    user_id={userSel.id}
                />
            }
            {/* FAB para agregar nuevo usuario */}
        </Fragment>
    );
}

export default Users;