import {
    faBoxTissue,
    faBuilding,
    faBullhorn,
    faCogs,
    faComments,
    faExclamationTriangle,
    faFileContract,
    faFileSignature,
    faGavel,
    faMailBulk,
    faPoll,
    faStoreAlt,
    faTicketAlt,
    faUserCheck,
    faWallet
} from "@fortawesome/free-solid-svg-icons";

const clientPaths = [
    { code: 'CFDI', name: 'mailbox', to: '/mailbox/client', icon: faMailBulk },
    { code: 'C-INC', name: 'incidents', to: '/incidents', icon: faExclamationTriangle },
    { code: 'O-COM', name: 'purchase-orders', to: '/purchase-order-client', icon: faWallet },
    { code: 'U-REP', name: 'repse', to: '/repse-client', icon: faFileSignature },
    { code: 'C-COM', name: 'malls', to: '/malls/client', icon: faBuilding },
    { code: 'R-LOC', name: 'local-rent', to: '/mall/local/client', icon: faStoreAlt },
    { code: 'X-CON', name: 'contracts', to: '/client-contracts', icon: faFileContract },
    { code: 'X-MSG', name: 'messages', to: '/chat-client', icon: faComments },
    { code: 'X-COM', name: 'releases', to: '/releases/client', icon: faBullhorn },
    { code: 'B-QSU', name: 'complaint-suggestions', to: '/complaints-suggestions/client', icon: faBoxTissue },
    { code: 'X-ENC', name: 'quizzs', to: '/polls/client', icon: faPoll },
    { code: 'X-LIC', name: 'tenders', to: '/tenders/client', icon: faGavel },
    { code: 'C-ACC', name: 'access-control', to: '/access-control/client', icon: faUserCheck },
    { code: 'X-CUP', name: 'coupons', to: '/coupons/client', icon: faTicketAlt },
    { code: 'X-SET', name: 'settings', to: '/settings/client', icon: faCogs },
];

export default clientPaths;