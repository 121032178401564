import { faArrowLeft, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Popconfirm } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { showAccessControlUser, updateAccessControlUser } from "../../../api/AccessControlUsers";
import { indexProviderDocumentReceiveds } from "../../../api/ProvidersDocumentsReceiveds";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import TabsComp from "../../Layouts/Tabs";
import UserDocuments from "../forms/UserDocuments";
import UserForm from "../forms/UserForm";

const ModalViewUser = (props) => {
    const { onClose, access_control_user_id, headers, update } = props;

    const [t, i18n] = useTranslation('global');
    const prefix = 'access-control.modal-view-user';
    // Datos del usuario
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    // Documentos del proveedor
    const [files, setFiles] = useState([]);

    useEffect(() => {
        getAccessControlUser();
    }, []);

    const getAccessControlUser = async () => {
        let response = await showAccessControlUser({ headers, access_control_user_id });
        console.log("Access control: ", response);
        await setData(response);
        if (response.status === 'pending') {
            // Cambiar estado a validando y enviar notificación por email
            await changeStatus('validating', response.id);
        }
        if (response.id) {
            response = await indexProviderDocumentReceiveds({ headers, access_control_user_id: response.id });
            // console.log("Documents: ", response);
            setFiles(response.data)
        }
        setLoading(false);
    }

    const changeStatus = async (status, id) => {
        await updateAccessControlUser({
            headers,
            data: {
                status
            },
            access_control_user_id: id
        });
    }
    // Rechazar la solicitud
    const reject = async () => {
        await changeStatus('canceled', data.id);
        update(); onClose();
    }
    // Aprobar la solicitud
    const accept = async () => {
        await changeStatus('accepted', data.id);
        update(); onClose();
    }

    return (
        <ModalComp
            onClose={onClose}
            size={'xl'}
            title={t(`${prefix}.title`)}
            body={<Fragment>
                <TabsComp
                    tablist={[t(`${prefix}.tab-data`), t(`${prefix}.tab-docs`)]}
                    tabpanels={[
                        <UserForm loading={loading} data={data} />,
                        <UserDocuments files={files} loading={loading} headers={headers} data={data} />
                    ]}
                />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name={t(`${prefix}.button-close`)} icon={faArrowLeft} onClick={onClose} variant='outline-secondary' />
                <Popconfirm
                    title={t(`${prefix}.popconfirm-reject`)}
                    onConfirm={reject}
                    okText={t(`${prefix}.popconfirm-yes`)}
                    cancelText={t(`${prefix}.popconfirm-no`)}
                >
                    <ButtonIcon name={t(`${prefix}.button-reject`)} icon={faTimes} variant='outline-danger' />
                </Popconfirm>
                <Popconfirm
                    title={t(`${prefix}.popconfirm-accept`)}
                    onConfirm={accept}
                    okText={t(`${prefix}.popconfirm-yes`)}
                    cancelText={t(`${prefix}.popconfirm-no`)}
                >
                    <ButtonIcon name={t(`${prefix}.button-accept`)} icon={faCheck} variant='outline-success' />
                </Popconfirm>
            </Fragment>}
        />
    );
}

export default ModalViewUser