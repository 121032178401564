import { Fragment } from "react";
import { useEffect, useState } from "react";
import { Badge, Stat, StatNumber } from "@chakra-ui/react";
import { Button, Row, Col, Card } from "react-bootstrap";
import Fetcher from "../../../libs/Petition";
import { useStore } from "react-redux";
import SkeletonCards from "../../Layouts/Skeletons/Cards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoData from "../../Layouts/NoData";
import { faEdit, faTrashRestoreAlt } from "@fortawesome/free-solid-svg-icons";
import { indexLocalMall } from "../../../api/Malls/LocalMalls";
import { useTranslation } from "react-i18next";

const LocalMalls = (props) => {
    const store = useStore();
    const { token, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [local_malls, setLocalMalls] = useState([]);
    const [loading, setLoading] = useState(true);
    const { mall_id } = props;
    const [t, i18n] = useTranslation('global');
    const prefix = 'malls.car-parking.statuses';

    useEffect(() => {
        getLocalMalls();
    }, []);

    const getLocalMalls = async () => {
        let fetch = await indexLocalMall({headers, fibra_id: fibra.id, mall_id});
        setLocalMalls(fetch.data);
        setLoading(false);
    }

    return (
        <Fragment>
            {
                loading ?
                    <SkeletonCards items={4} cols={4} /> :
                    <Row xs={1} md={4} className="g-4 mt-2">
                        {local_malls ?
                            local_malls.map((local_mall, index) => (
                                <Col key={index}>
                                    <Card>
                                        <Card.Img variant="top" src={''} />
                                        <Card.Body>
                                            <Card.Title>{local_mall.code}</Card.Title>
                                            <Card.Text>{local_mall.name}</Card.Text>
                                            <Stat>
                                                {/* <StatLabel>Collected Fees</StatLabel> */}
                                                <StatNumber>{local_mall.price_total}</StatNumber>
                                                {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
                                            </Stat>
                                            {
                                                local_mall.taken ?
                                                    <Badge variant="outline" colorScheme="red">{t(`${prefix}.occupied`)}</Badge> :
                                                    <Badge variant="outline" colorScheme="green">{t(`${prefix}.available`)}</Badge>
                                            }
                                            <div id='footer-options'>
                                                <Button variant='outline-success'><FontAwesomeIcon icon={faEdit} /></Button>
                                                <Button variant='outline-danger'><FontAwesomeIcon icon={faTrashRestoreAlt} /></Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )) : null}
                    </Row>
            }

            {(!local_malls || local_malls.length === 0) && !loading ? <NoData /> : null}
        </Fragment>
    );
}

export default LocalMalls;