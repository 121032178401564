import { Select, useToast } from '@chakra-ui/react';
import { faArrowLeft, faBackward, faCalendarPlus, faForward, faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { indexCedis } from '../../../api/Cedis/Cedis';
import { indexCedisDeliveries, storeCedisDeliveries } from '../../../api/Cedis/Deliveries';
import { showPurchaseOrders } from '../../../api/PurchaseOrders';
import getFolio from '../../../libs/prefixFolio';
import setToast from '../../../libs/SetToast';
import CalendarComp from '../../Layouts/Calendar';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import FormIcon from '../../Layouts/Forms/FormIcon';
import ModalComp from '../../Layouts/Modal';
import TableComp from '../../Layouts/Table';

const _ = require('underscore');

const Schedule = (props) => {
    const { onClose, orderSel, headers, fibra_id } = props;
    const toast = useToast();

    const [loading, setLoading] = useState(true);
    const [purchaseOrder, setPurchaseOrder] = useState({});
    const [items, setItems] = useState([]);                     // Items seleccionados
    const [view, setView] = useState('items');                  // Vista por default en los items
    const [cedis, setCedis] = useState([]);                     // Lista de centros de distribución
    const [cedis_id, setCedisID] = useState(null);              // Cedis seleccionado
    const [events, setEvents] = useState([]);                   // Lista de entregas programadas
    const [timestamps, setTimestamps] = useState({});           // Fecha y hora de entrega
    const [statusOp, setStatusOp] = useState(false);
    const [t, i18n] = useTranslation('global');
    const prefix = 'purchase-order.toasts';
    const p_modal = 'purchase-order.modal-schedule';

    useEffect(() => {
        getPurchaseOrder();
    }, []);

    useEffect(() => {
        // console.log("cedis_id: ", cedis_id);
    }, [cedis_id]);


    const getPurchaseOrder = async () => {
        let response = await showPurchaseOrders({ headers, purchase_order_id: orderSel.id });
        setPurchaseOrder(response);
        await getCedis();
        setLoading(false);
    }

    const getCedis = async () => {
        let response = await indexCedis({ headers, fibra_id });
        if (response.data.length > 0) {
            setCedisID(response.data[0].id);
        }
        setCedis(response.data);
    }
    // Se marcan los items que serán entregados
    const handleChange = async (checked, purchase_order_item_id) => {
        if (checked) {
            setItems([...items, purchase_order_item_id]);
        } else {
            setItems(items.filter(items => items !== purchase_order_item_id));
        }
    }
    const getChecked = (purchase_order_item_id) => {

        return items.includes(purchase_order_item_id);
    }

    const getDisabled = (purchase_order_item_id) => {
        let disabled = false;
        let pos = _.findIndex(purchaseOrder.items, { id: purchase_order_item_id });
        if (pos !== -1) {
            let item = purchaseOrder.items[pos];
            if (item.status === 'pending' || item.status === 'delivered') {
                disabled = true;
            }
        }
        return disabled;
    }
    // Elegir centro ded distribución
    const handleChangeCedis = (e) => {
        let { value } = e.target;
        setCedisID(value);
    }

    // Método para pasar a la asignación de fecha
    const goForward = () => {
        if (items.length > 0) {
            if (cedis_id) {
                setView('schedule');    // Cambiar vista
                getEvents();            // Obtener eventos para el calendario, es decir, entregas disponibles
            } else
                setToast({
                    title: t(`${prefix}.select-cedis.title`), status: 'warning', toast, description: t(`${prefix}.select-cedis.description`)
                });
        } else {
            setToast({
                title: t(`${prefix}.no-data.title`), status: 'warning', toast, description: t(`${prefix}.no-data.description`)
            });
        }
    }
    // Obtener entregas programadas
    const getEvents = async () => {
        let response = await indexCedisDeliveries({ headers, cedis_id });
        setEvents(
            response.data.map((event) => {
                let { start, end } = event;
                return {
                    ...event,
                    start: new Date(start),
                    end: new Date(end)
                }
            })
        );
    }
    // Evento para asignar la fecha de entrega
    const handleDate = (e) => {
        let { value, name } = e.target;
        // console.log(`Name: ${name} - value: ${value}`);
        setTimestamps({ ...timestamps, [name]: value });
    }
    // Programar entrega
    const scheduleDelivery = async () => {
        setStatusOp(true);  // Indicar que se está realizando la operación
        // Validar los datos para poder finalizar la entrega
        let { delivery_date, start_time, end_time } = timestamps;
        if (validData()) {
            // Crear evento
            let code = `DEL_${getFolio()}`; // Folio de la entrega
            let start_date = new Date(`${delivery_date} ${start_time}:00`);
            let end_date = new Date(`${delivery_date} ${end_time}:00`);
            let new_event = {
                title: code,
                start: start_date,
                end: end_date
            };
            // Agregar evento al calendario
            setEvents([...events, new_event]);
            // Guardar cita en DB
            // console.log('moment format: ', moment(start_date).format('YYYY-MM-DD HH:mm:ss'));
            let response = await storeCedisDeliveries({
                headers, data: {
                    cedis_id, code,
                    purchase_order_id: orderSel.id,
                    start_date: moment(start_date).format('YYYY-MM-DD HH:mm:ss'),
                    end_date: moment(end_date).format('YYYY-MM-DD HH:mm:ss'),
                    // Enviar arreglo de identificadores de las partidas que serán entregadas
                    items
                }
            });
            if (response) {
                setToast({
                    title: t(`${prefix}.del-success.title`), status: 'success', toast, description: t(`${prefix}.del-success.description`)
                });
                onClose();
            } else {
                setStatusOp(false);
                setToast({
                    title: t(`${prefix}.del-error.title`), status: 'error', toast, description: t(`${prefix}.del-error.description`)
                });
            }
            // console.log("REsponse cedis store del: ", response);
        } else {
            setStatusOp(false);
        }
    }
    // Método para validar que las fechas sean válidas
    const validData = () => {
        let response = true;
        let { delivery_date, start_time, end_time } = timestamps;
        if (new Date(delivery_date) <= Date.now()) {
            response = false;
            setToast({
                title: t(`${prefix}.wrn-date.title`), status: 'warning', toast, description: t(`${prefix}.wrn-date.description`)
            });
        }
        if (moment(start_time, 'hh:mm') >= moment(end_time, 'hh:mm')) {
            response = false;
            setToast({
                title: t(`${prefix}.wrn-hour.title`), status: 'warning', toast, description: t(`${prefix}.wrn-hour.description`)
            });
        }
        return response;
    }
    return <ModalComp
        onClose={onClose}
        size='lg'
        title={t(`${p_modal}.title`)}
        body={<Fragment>
            {
                view === 'items' && <Fragment>
                    <Row className='mb-3'>
                        <Col>
                            <Form.Label>{t(`${p_modal}.cedis`)}</Form.Label>
                            <Select placeholder={t(`${p_modal}.select-cedis`)} name='cedis_id' value={cedis_id} onChange={handleChangeCedis}>
                                {
                                    cedis.map((ced, index) =>
                                        <option key={index} value={ced.id}>{ced.code}-{ced.name}</option>
                                    )
                                }
                            </Select>
                        </Col>
                    </Row>
                    <TableComp
                        headers={[
                            '#',
                            t(`${p_modal}.description`),
                            t(`${p_modal}.quantity`),
                            t(`${p_modal}.unit-price`),
                            t(`${p_modal}.subtotal`),
                            t(`${p_modal}.status`),
                            t(`${p_modal}.actions`)
                        ]}
                        keys={['#', 'description', 'quantity', 'unit_price', 'total', 'status']}
                        body={purchaseOrder?.items}
                        type='switch'
                        actions={{
                            checkedChildren: t(`${p_modal}.checked`),
                            unCheckedChildren: t(`${p_modal}.unchecked`),
                            onChange: handleChange,
                            getChecked: getChecked,
                            getDisabled: getDisabled
                        }}
                        loading={loading}
                        currency={[{ index: 3 }, { index: 4 }]}
                        badges={[{ index: 5, color: 'status_color' }]}
                    />
                </Fragment>
            }
            {
                view === 'schedule' && <Fragment>
                    <Row className='mb-3'>
                        <FormIcon title={t(`${p_modal}.delivery-date`)} type='date' name='delivery_date' value={timestamps?.delivery_date} handleChange={handleDate} />
                        <FormIcon title={t(`${p_modal}.from`)} type='time' name='start_time' value={timestamps?.start_time} handleChange={handleDate} />
                        <FormIcon title={t(`${p_modal}.to`)} type='time' name='end_time' value={timestamps?.end_time} handleChange={handleDate} />
                        {/* <Col className='d-flex justify-content-center align-items-end'>
                            <ButtonIcon name='Agregar fecha al calendario' icon={faCalendarPlus} onClick={}/>
                        </Col> */}
                    </Row>
                    <CalendarComp events={events} />
                </Fragment>
            }
        </Fragment>}
        footer={<Fragment>
            <ButtonIcon name={t(`${p_modal}.button-close`)} icon={faArrowLeft} variant='outline-secondary' onClick={onClose} />
            {
                view === 'items' &&
                <ButtonIcon name={t(`${p_modal}.button-forward`)} icon={faForward} variant='outline-primary'
                    tooltip={t(`${p_modal}.set-delivery-date`)} onClick={goForward} />
            }
            {
                view === 'schedule' && <Fragment>
                    <ButtonIcon name={t(`${p_modal}.button-backward`)} icon={faBackward} variant='outline-primary'
                        onClick={() => setView('items')} />
                    <ButtonIcon name={t(`${p_modal}.button-assign`)} icon={faTruckLoading} variant='outline-success'
                        onClick={scheduleDelivery} disabled={statusOp} />
                </Fragment>
            }
        </Fragment>}
    />;
};

export default Schedule;
