import { Col, Form, FormSelect, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormIcon from "../../Layouts/Forms/FormIcon";
import { Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';

const Data = (props) => {
    const [t, i18n] = useTranslation('global');
    const prefix = 'signup.data';
    const { handleChange, handleRfc, data } = props;

    return (
        <Form className='mt-3'>
            <Row className='mb-3'>
                <FormIcon type='text' title={t(`${prefix}.first_name`)} name='first_name'
                    value={data.first_name || ''} handleChange={handleChange} />
                <FormIcon type='text' title={t(`${prefix}.last_name`)} name='last_name'
                    value={data.last_name || ''} handleChange={handleChange} />
                <FormIcon type='text' title={t(`${prefix}.tax_id`)} name='tax_id'
                    value={data.tax_id || ''} handleChange={handleRfc} />
            </Row>
            <Row className='mb-3'>
                <FormIcon type='text' title={t(`${prefix}.business_name`)} name='business_name'
                    value={data.business_name || ''} handleChange={handleChange} />
                <FormIcon type='text' title={t(`${prefix}.alias`)} name='alias'
                    value={data.alias || ''} handleChange={handleChange} />
                <Col>
                    <label className='form-label'>{t(`${prefix}.person_type`)}</label>
                    <FormSelect name='person_type' onChange={handleChange} value={data.person_type}>
                        <option value={1}>{t(`${prefix}.person-types.physical`)}</option>
                        <option value={0}>{t(`${prefix}.person-types.moral`)}</option>
                    </FormSelect>
                </Col>
            </Row>
            <Row className='mb-3'>
                <FormIcon type='email' title={t(`${prefix}.email`)} name='email'
                    value={data.email} handleChange={handleChange} />
                <FormIcon type='phone' title={t(`${prefix}.phone`)} name='phone_number'
                    value={data.phone_number} handleChange={handleChange} />
                <Col>
                    <label className='form-label'>{t(`${prefix}.gender`)}</label>
                    <FormSelect name='gender' onChange={handleChange} value={data.gender} >
                        <option value={null}>{t(`${prefix}.gender-types.unknow`)}</option>
                        <option value={'male'}>{t(`${prefix}.gender-types.male`)}</option>
                        <option value={'female'}>{t(`${prefix}.gender-types.female`)}</option>
                    </FormSelect>
                </Col>
            </Row>
        </Form>
    );
}

export default Data