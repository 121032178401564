import { useToast } from "@chakra-ui/react";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { useState, Fragment } from "react";
import { storeCedis, updateCedis } from "../../../api/Cedis/Cedis";
import setToast from "../../../libs/SetToast";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import FormData from "./FormData";

const ModalAdd = (props) => {
    const { onClose, headers, update, fibra_id, cedis } = props;
    const [data, setData] = useState({ fibra_id });
    const toast = useToast();

    const handleChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    }
    // Validación de datos 
    const validData = () => {
        let response = true;
        if (data?.code === '' || data?.code === undefined || data?.name === '' || data?.name === undefined)
            response = false;
        return response;
    }
    const saveChanges = async () => {
        let response = await updateCedis({ headers, data, cedis_id: cedis.id });
        if (response) {
            update(); onClose();
            setToast({ title: '¡Información actualizada!', description: 'Los datos del CEDIS fueron actualizados de manera satisfactoria', status: 'success', toast });
        } else {
            setToast({ title: '¡Error al actualizar!', description: 'Ha ocurrido un error al tratar de establecer comunicación con nuestros servidores', status: 'warning', toast });
        }
    }

    const save = async () => {
        if (validData()) {
            let response = await storeCedis({ headers, data });
            if (response) {
                update(); onClose();
                setToast({
                    title: '¡Registro agregado!', description: 'Centro de distribución agregado de manera satisfactoria', status: 'success', toast
                });
            } else {
                setToast({
                    title: '¡Ocurrió un error!', description: 'Hubo un error a tratar de hacer la comunicación con nuestros servidores', status: 'error', toast
                });
            }
        } else {
            setToast({
                title: '¡Datos incorrectos!', description: 'Complete el formulario para continuar con esta operación', status: 'warning', toast
            });
        }
    }
    return (
        <ModalComp
            onClose={onClose}
            title="Agregar centro de distribución"
            body={<Fragment>
                <FormData
                    data={data}
                    cedis={cedis}
                    handleChange={handleChange}
                />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} variant="outline-secondary" name="Cerrar" onClick={onClose} />
                {
                    cedis?.id ?
                        <ButtonIcon icon={faSave} variant="outline-success" name="Actualizar" onClick={saveChanges} /> :
                        <ButtonIcon icon={faSave} variant="outline-success" name="Agregar" onClick={save} />
                }
            </Fragment>}
        />
    );
};

export default ModalAdd;
