import Fetcher from "../libs/Petition";

/**
 * 
 * @param email Correo electrónico el usuario
 * @param password Contraseña para acceder
 * @param sap_id [opcional] En caso de estar vinculado a SAP, introducir SAP ID también 
 */
export const login = async ({ email, password, sap_id }) => {
    let fetch = "init";
    try {
        let data = { email, password };
        if (sap_id) data.sap_id = sap_id;
        let response = await Fetcher({ method: 'POST', url: '/login', data });
        // console.log("Response service: ", response);
        if (response.status === 200) {
            fetch = {
                status: 'SUCCESS',
                data: response.data.data
            };
        } else {
            fetch = {
                status: 'FAILED',
                message: `${response.data.errors.msg[0]}`
            }
        }
    } catch (error) {
        console.log("Error: ", error);
        fetch = {
            status: 'FAILED',
            message: 'Error al conectar con el servidor'
        };
    } finally {
        return fetch;
    }
}

export const verify = async ({ email, code }) => {
    let response = { res: false, data: {} };
    try {
        let fetch = await Fetcher({
            method: 'POST',
            url: '/verify',
            data: { email, code }
        });
        // console.log("Fetcher verify code: ", fetch);
        if (fetch.status === 200)
            response = { res: true, data: fetch.data.data }
    } catch (error) {
        response = { res: false, data: {} };
    } finally {
        return response;
    }
}

export const twoFactorAuth = async ({ headers, data }) => {
    let response = { res: false, data: {} };
    try {
        let fetch = await Fetcher({
            method: 'POST',
            url: '/two_factor_auth',
            headers,
            data
        });
        // console.log("Two factor auth: ", fetch);
        if (fetch.status === 200)
            response = { res: true, data: fetch.data.data };
    } catch (error) {
        response = { res: false, data: {} };
    } finally {
        return response;
    }
}

export const signup = async ({ data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            method: 'POST',
            url: 'signup',
            data
        });
        // console.log("Fetcher: ", data);
        response = fetch;
    } catch (error) {
        console.log("Error: ", error);
    } finally {
        return response;
    }
}

export const logout = async ({ headers, data }) => {
    let response = { res: false, data: {} };
    try {
        let fetch = await Fetcher({
            method: 'POST',
            url: '/logout',
            headers,
            data
        });
        // console.log("Logout fetcher:: ", fetch);
        if (fetch.status === 200)
            response = fetch.data;
    } catch (error) {
        response = { res: false, data: {} };
    } finally {
        return response;
    }
}