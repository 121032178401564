import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TableComp from "../../Layouts/Table";

const Guests = () => {
    const [t, i18n] = useTranslation('global');
    const prefix = 'access-control.data';
    const [users, setUsers] = useState
    ([]);

    const viewDetails = () => {
        console.log("Details user");
    }

    return (
        <TableComp 
            headers={[
                '#',
                t(`${prefix}.user`),
                t(`${prefix}.email`),
                t(`${prefix}.status`),
                t(`${prefix}.created-at`),
                t(`${prefix}.actions`),
            ]}
            keys={['id', 'user', 'email', 'status', 'created_at']}
            badges={[{ index: 3, color: 'status_color' }]}
            body={users}
            actions={[
                {icon: faEye, handleClick: viewDetails}
            ]}
        />
    );
}

export default Guests