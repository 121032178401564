import { Heading } from "@chakra-ui/layout";
import { Tabs, TabList, Tab, TabPanel, TabPanels } from "@chakra-ui/react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import '../../../styles/Malls.Component.css';
import LocalMalls from "./LocalMalls";
import ParkingLots from "./Parking";
import { useTranslation } from "react-i18next";

const MallDetails = (props) => {
    const history = useHistory();
    const { mall_id } = props.match.params;
    const [t, i18n] = useTranslation('global');
    const prefix = 'malls.details';
    
    const handleBack = () => {
        history.push('/malls');
    }

    return (
        <Container id='local-malls-component' fluid>
            <Tabs align="start" variant="soft-rounded" colorScheme='blue'>
                <Row>
                    <Col sm={1}><Button variant='outline-secondary' onClick={handleBack}>
                        <FontAwesomeIcon icon={faArrowLeft} /></Button></Col>
                    <Col sm={7}><Heading>{t(`${prefix}.title`)}</Heading></Col>
                    <Col sm={4}>
                        <TabList>
                            <Tab>{t(`${prefix}.locals`)}</Tab>
                            <Tab>{t(`${prefix}.parking-lots`)}</Tab>
                        </TabList></Col>
                </Row>

                <TabPanels>
                    <TabPanel>
                        <LocalMalls mall_id={mall_id} />
                    </TabPanel>
                    <TabPanel>
                        <ParkingLots mall_id={mall_id} />
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </Container>
    );
}

export default MallDetails;