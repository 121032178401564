import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { indexChatMessages } from '../../../api/Chat/Message';
import Message from './Message'
import '../Styles.css';

const Chat = (props) => {
    const { chat_room_id, messages, setMessages } = props;

    const messagesEndRef = useRef();
    const infiniteRef = useRef();

    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const user_id = login.user.id;

    const [currentPage, setCurrentPage] = useState(1);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const loadArea = 30;
    const hasMore = true;

    useEffect(() => {
        if (chat_room_id) {
            setMessages([]);
            getMessages();
        }
    }, [chat_room_id]);

    useEffect(() => {
        // console.log("Messages: ", messages, " couunt: ", messages.length);
        scrollToBottom();
        let { current: scrollContainer } = infiniteRef;
        function onScroll() {
            // Valor del scroll cuando está hasta abajo
            const availableScroll = scrollContainer.scrollHeight - scrollContainer.clientHeight;
            // console.log("On scroll: [scrollcontainer]: ", scrollContainer.scrollTop, scrollContainer.scrollHeight, scrollContainer.clientHeight);
            // Si el escroll está en el mensaje más antiguo obtenido
            if (scrollContainer.scrollTop === 0) {
                console.log("Scroll en el mensaje más antiguo");
                getMessages();
            }
            // Si el scroll está en el mensaje más reciente
            if (scrollContainer.scrollTop === availableScroll) {
                console.log("Scroll en el mensaje más reciente");
            }
            // Handle scroll direction
            // if (scrollContainer.scrollTop > scrollPosition) {
            //     // Scroll bottom
            // } else {
            //     // Check load more scroll area
            //     if (scrollContainer.scrollTop <= loadArea && !isLoading) {
            //         // Check for available data
            //         if (hasMore) {
            //             // Run data fetching
            //             const nextPage = currentPage + 1;
            //             setCurrentPage(nextPage);
            //             getMessages();
            //         }
            //     }
            // }
            // // Save event scroll position
            // setScrollPosition(scrollContainer.scrollTop);
        }

        scrollContainer.addEventListener("scroll", onScroll);
        return () => {
            scrollContainer.removeEventListener("scroll", onScroll);
        };
    }, [messages]);

    const getMessages = async () => {
        setIsLoading(true);
        let response = await indexChatMessages({ headers, chat_room_id });
        setMessages(current => [...current, ...response.data]);
        setIsLoading(false);
    }
    // Posicionar scroll al final de los mensajes
    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className='p-2 scroll-chat scroll-body' ref={infiniteRef}>
            {
                messages && messages.map((msg, index) =>
                    <Fragment key={index}>
                        <Message
                            key={index}
                            alignMsg={user_id === msg.user.id ? 'end' : 'start'}
                            msg={msg}
                        />
                    </Fragment>
                )
            }
            <div ref={messagesEndRef} />
        </div>
    )
}

export default Chat;