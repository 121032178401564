import { Fragment } from 'react';
import { Steps } from 'antd';

const { Step } = Steps;

const Tracking = (props) => {
  const { tracking_items } = props;
  return <Fragment>
    <Steps progressDot current={tracking_items.length} direction="vertical">
      {
        tracking_items && tracking_items.reverse().map((item, index) =>
          <Step
            key={index}
            title={item.title}
            description={`${item.description}. ${item.created_at}.`}
          />
        )
      }
    </Steps>
  </Fragment>;
};

export default Tracking;
