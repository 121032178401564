import { faArrowLeft, faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { showLocalMall } from "../../../api/Malls/LocalMalls";
import Fetcher from "../../../libs/Petition";
import CarouselComponent from "../../Layouts/Carousel";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import TabsComp from "../../Layouts/Tabs";
import LocalData from "../forms/LocalData";
import MallData from "../forms/MallData";
import Swal from 'sweetalert2';
import { storeRentRequest } from "../../../api/Malls/RentRequest";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const Details = (props) => {
    const { onClose, local_mall_id, headers, fibra_id, user_id, update } = props;
    const [local_mall, setLocalMall] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [t, i18n] = useTranslation('global');
    const prefix = 'rents.modal-details';

    useEffect(() => {
        getLocalMall();
    }, []);

    const getLocalMall = async () => {
        let response = await showLocalMall({ headers, local_mall_id });
        // console.log("Show local mall: ", response);
        try {
            let fetch = await Fetcher({
                method: 'GET', url: `/local_malls/download/${local_mall_id}`,
                responseType: 'blob', headers
            });
            let image = new Blob([fetch.data], { type: fetch.data.type });
            let url = window.URL || window.webkitURL;
            image = url.createObjectURL(image);
            setPhotos([image]);
        } catch (error) {

        }
        setLocalMall(response);
        setLoading(false);
    }

    const sendRequest = () => {
        Swal.fire({
            title: t(`${prefix}.apply-for-question`),
            showCancelButton: true,
            confirmButtonText: t(`${prefix}.apply-for`),
        }).then(async (result) => {
            if (result.isConfirmed) {
                let data = {
                    user_id,
                    fibra_id,
                    local_mall_id,
                    status: 'pendiente'
                };
                let response = await storeRentRequest({ headers, data });
                // console.log("response:: ", response);
                Swal.fire(t(`${prefix}.requested`), '', 'success');
                update();
                onClose();
            }
        });
    }
    return (
        <ModalComp
            onClose={onClose}
            size='lg'
            title={`${local_mall?.code}-${local_mall?.name}` || t(`${prefix}.loading`)}
            body={<Fragment>
                {!loading &&
                    <TabsComp
                        tablist={[
                            t(`${prefix}.tabs.info`),
                            t(`${prefix}.tabs.mall`),
                            t(`${prefix}.tabs.pics`),
                        ]}
                        tabpanels={[
                            <LocalData local_mall={local_mall} />,
                            <MallData mall={local_mall?.mall} />,
                            <CarouselComponent evidences={photos} />,
                        ]}
                    />
                }
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name={t(`${prefix}.button-close`)} variant='outline-secondary' onClick={onClose} />
                <ButtonIcon icon={faCommentsDollar} name={t(`${prefix}.button-apply-for`)} variant='outline-success' onClick={sendRequest} />
            </Fragment>}
        />
    );
}

export default Details