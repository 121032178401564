import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from "react";
import TableComp from "../../../Layouts/Table";
import PaginationComp from "../../../Layouts/Pagination";
import { indexRepseMovements } from "../../../../api/Repse/RepseMovements";
import { useTranslation } from 'react-i18next';

const History = (props) => {
    const { user, headers, fibra_id } = props;
    const [movements, setMovements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);            // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                        // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);               // Cantidad de registros por página
    const [t, i18n] = useTranslation('global');
    const prefix = ['repse.users.history.headers', 'repse.users.history.tooltips'];

    useEffect(() => {
        getMovements();
    }, [page, pageSize]);

    const getMovements = async () => {
        let response = await indexRepseMovements({ headers, fibra_id, user_id: user.id, page, pageSize });
        setMovements(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }
    const viewDetails = () => {

    }
    return (
        <Fragment>
            <TableComp
                headers={[
                    '#', 
                    t(`${prefix[0]}.title`),
                    t(`${prefix[0]}.description`),
                    t(`${prefix[0]}.created-at`),
                    t(`${prefix[0]}.actions`)
                ]}
                keys={['#', 'title', 'description', 'created_at']}
                body={movements}
                loading={loading}
                loadingItems={pageSize}
                actions={[
                    { tooltip: t(`${prefix[1]}.view-detail`), handleClick: viewDetails }
                ]}
            />
            <PaginationComp
                totalItems={totalItems}
                current={page}
                onChange={changePage}
                defaultPageSize={pageSize}
            />
        </Fragment>
    );
}

History.propTypes = {
    users: PropTypes.object,
    headers: PropTypes.string
}

export default History;