const getFolio = () => {
    let folio = '';
    try {
        folio = `${new Date().getFullYear() - 2000}_${new Date().getTime()}`;
    } catch (error) {
        folio = ''
    }finally{
        return folio;
    }
}


export default getFolio;