import { Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormIcon from "../../Layouts/Forms/FormIcon";

const ClientData = (props) => {
    const {
        first_name,
        last_name,
        email,
        phone_number,
        created_at
    } = props.client;

    const [t, i18n] = useTranslation('global');
    const prefix = 'rents.forms';
    return (
        <Form>
            <Row className='mb-3'>
                <FormIcon title={t(`${prefix}.first-name`)} value={first_name} />
                <FormIcon title={t(`${prefix}.last-name`)} value={last_name} />
            </Row>
            <Row className='mb-3'>
                <FormIcon title={t(`${prefix}.email`)} value={email} />
                <FormIcon title={t(`${prefix}.phone-number`)} value={phone_number} />
                <FormIcon title={t(`${prefix}.membership-date`)} value={created_at} />
            </Row>
        </Form>
    );
}

export default ClientData