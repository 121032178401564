import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from "react";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";

const ModalUserEdit = (props) => {
    const { onClose } = props;
    return (
        <ModalComp
            onClose={onClose}
            title="Editar datos de usuario"
            size="lg"
            body={<Fragment>
                <h1>En construcción</h1>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name="Cerrar" onClick={onClose} variant="outline-secondary" />
            </Fragment>}
        />
    );
};

export default ModalUserEdit;
