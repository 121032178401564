import Fetcher from "../../libs/Petition"

const url = '/local_malls';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Se utiliza el identificador de la fibra para obtener los roles que le corresponden
 * @param page Se utiliza para conocer la página en la que se encuentra
 * @param pageSize Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @param available [Opcional] Se utiliza para obtener locales ocupados [FALSE] o disponibles [TRUE]
 * @returns Object {"data" => LocalMalls::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexLocalMall = async (props) => {
    const {
        headers,
        fibra_id,
        page,
        pageSize,
        available,
        mall_id,
        m2,
        price_minor,
        price_major } = props;
    let response = [];
    try {
        // Parámetros requeridos
        let params = { fibra_id, page, pageSize };
        // Parámetros opcionales
        if (price_minor) params.price_minor = price_minor;
        if (price_major) params.price_major = price_major;
        if (mall_id) params.mall_id = mall_id;
        if (m2) params.m2 = m2;
        params.available = available;
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = [];
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param local_mall_id Identificador del local sobre el que se hará la consulta
 * @returns Rent Retorna un objeto tipo Rent
 */
export const showLocalMall = async ({ headers, local_mall_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}/${local_mall_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = [];
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeLocalMall = async ({ headers, data }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status !== CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param local_mall_id Identificador del local que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateLocalMall = async ({ headers, data, local_mall_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${local_mall_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param local_mall_id Identificador del local que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyLocalMall = async ({ headers, local_mall_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${local_mall_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}