import { Fragment, useEffect, useState } from "react";
import { useStore } from "react-redux";
import FlexButton from "../Layouts/FlexButton";
import Mailbox from "./fragments/Mailbox"
import _ from "underscore";
import UploadInvoice from "./fragments/Upload";
import { getInvoices } from "../../api/Cfdi/Mailbox";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import CompPaymentModal from "./fragments/CompPaymentModal";
import { useTranslation } from "react-i18next";

const MailboxProvider = () => {
    const store = useStore();
    const { token, fibra, user, role_selected } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [modal_add, setModalAdd] = useState(false);
    const [modalComp, setModalComp] = useState(false);      // Modal para los complementos de pago

    const [mailbox, setMailbox] = useState([]);             // Lista de CFDi's
    const [invoice, setInvoice] = useState(null);           // Factura seleccionada para mostrar en el modal
    // Paginación
    const [totalItems, setTotalItems] = useState(0);        // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                    // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);           // Cantidad de registros por página
    const [loading, setLoading] = useState(true);           // indica si se están obteniendo registros
    const [cfdi_status_id, setCfdiStatusId] = useState(null);
    const [t, i18n] = useTranslation('global');
    const [string, setString] = useState('');                   // Cadena para hallar coincidencias

    useEffect(() => {
        getMailbox();
    }, [page, pageSize, cfdi_status_id]);

    const getMailbox = async () => {
        setLoading(true);
        let params = { headers, page, pageSize, string };
        if (cfdi_status_id) params.cfdi_status_id = cfdi_status_id;
        let response = await getInvoices({
            ...params, fibra_id: fibra.id,
            role_id: role_selected.id, user_id: user.id
        });
        setMailbox(response.data);
        if (response.totalItems) setTotalItems(response.totalItems);
        setLoading(false);
    }

    const handleOpenClose = () => { setModalAdd(!modal_add); }
    const handleOpenCloseComp = () => { setModalComp(!modalComp); }

    const complementaryPayment = (item) => {
        setInvoice(item);
        handleOpenCloseComp();
    }

    // Buscar coincidencias de cadena
    const searchInvoice = () => {
        getMailbox();
    }
    return (
        <Fragment>
            <Mailbox
                mailbox={mailbox}                   // Colección de registros obtenidos
                loading={loading}                   // Indicador de carga de registros de facturas
                invoice={invoice}                   // Factura seleccionada
                setInvoice={setInvoice}             // Factura seleccionada
                setCfdiStatusId={setCfdiStatusId}   // Guardar el estado de CFDi para actualizar
                page={page}                         // Página actual
                setPage={setPage}                   // Método para cambiar la página
                pageSize={pageSize}                 // Tamaño de la página
                setPageSize={setPageSize}           // Método para cambiar el tamaño de la página
                totalItems={totalItems}             // Total de registros existentes
                tactions={[                          // Botones que son visibles para el proveedor
                    {
                        icon: faFileUpload, variant: 'outline-success',
                        tooltip: t('mailbox.table.tooltips.add-comp'),
                        handleClick: complementaryPayment,
                        dinamic: [
                            { key: 'payment_method', value: 'PPD' },
                            { key: 'cfdi_status', value: 'Pagados' }
                        ]
                    }
                ]}
                string={string}
                setString={setString}
                searchInvoice={searchInvoice}
            />
            <FlexButton style={{ bottom: 10, right: 10 }} onClick={handleOpenClose} />
            {/* Modal para agregar nueva factura */}
            {
                modal_add ? <UploadInvoice
                    onClose={handleOpenClose}
                    headers={headers}
                /> : null
            }
            {/* Modal para mostrar los complementos de pago */}
            {
                modalComp && <CompPaymentModal
                    onClose={handleOpenCloseComp}
                    headers={headers}
                    invoice={invoice}
                    user_id={user.id}
                />
            }
        </Fragment>
    );
}

export default MailboxProvider;