import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { storeRole, updateRole } from "../../../api/Roles";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import FormGroupComponent from "../../Layouts/Forms/FormGroup";
import ModalComp from "../../Layouts/Modal";

const ModalRoles = (props) => {
    const { onClose, title, role, type, headers, update, fibra } = props;
    const [data, setData] = useState({});

    useEffect(() => {
        console.log("props: ", props);
    }, []);

    const handleChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const updateOrCreate = async () => {
        let response = false;
        if (type === 'update')
            response = await updateRole({ headers, data: { ...data, fibra_id: fibra.id }, role_id: role.id });
        else  // El código ADMINISTRADOR es para usuarios ADM
            response = await storeRole({ headers, data: { ...data, code: 'ADMINISTRADOR', fibra_id: fibra.id } });

        console.log("response: ", response);
        if (response) {
            update();
            onClose();
        }
    }
    return (
        <ModalComp
            onClose={onClose}
            title={title}
            body={<Fragment>
                <Form>
                    <Row className='mb-2'>
                        <FormGroupComponent
                            title='Nombre' defaultValue={role.name} name='name' value={data.name}
                            readOnly={type === 'details' ? true : false}
                            handleChange={handleChange} />
                    </Row>
                    <Row className='mb-2'>
                        <FormGroupComponent
                            title='Descripción' defaultValue={role.description} name='description' value={data.description}
                            readOnly={type === 'details' ? true : false}
                            handleChange={handleChange} />
                    </Row>
                </Form>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name='Cerrar' variant='outline-secondary' onClick={onClose} />
                {
                    type !== 'details' ?
                        <ButtonIcon icon={faSave} name='Guardar' onClick={updateOrCreate} /> : null
                }
            </Fragment>}
        />
    );
}

export default ModalRoles;