import { Modal, Button, Form } from "react-bootstrap";
import { Fragment, useEffect, useState } from "react";
import Fetcher from "../../../libs/Petition";
import FormInvoice from "./FormInvoice";
import FormFilesUpload from "./FormFilesUpload";
import { useStore } from "react-redux";
import _ from "underscore";
import { Progress } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import setToast from "../../../libs/SetToast";
import { uploadInvoice } from "../../../api/Cfdi/Mailbox";
import { useTranslation } from "react-i18next";

const UploadInvoice = (props) => {
    const store = useStore();
    const toast = useToast();
    let { fibra, user } = store.getState().login;
    let statuses = store.getState().cfdi.state;

    const [show, setShow] = useState(true);
    const [form, setForm] = useState({});
    const [loading, setLoading] = useState(false);
    const [invoice, setInvoice] = useState({});
    const [validated, setValidated] = useState(false);
    const [successfull, setSuccessfull] = useState(false);
    const [modal_size, setModalSize] = useState('md');
    const [t, i18n] = useTranslation('global');
    const p_toasts = 'mailbox.files-upload.toasts';
    const prefix = 'mailbox.files-upload';

    const { headers } = props;

    useEffect(() => {
        console.log("Form: ", form);
    }, [form])

    const handleClose = () => {
        if (!loading) {
            props.onClose(); setShow(false);
        }
    }

    const handleLoadFile = (e) => {
        let { name } = e.target;
        setForm(prevState => ({ ...prevState, [name]: e.target.files[0] }));
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        setForm(prevState => ({ ...prevState, [name]: value }));
    }

    const send = async (e) => {
        const html_form = e.currentTarget;
        e.preventDefault();
        if (!html_form.checkValidity()) {
            e.stopPropagation();
        } else {
            setLoading(true);
            try {
                let pos = _.findIndex(statuses, { code: 'inbox' });
                let params = {
                    fibra_id: fibra.id,
                    user_id: user.id,
                    cfdi_status_id: pos !== -1 ? statuses[pos].id : 1 //Esta parte se tiene que revisar
                };
                if (form.purchase_order_id && form.purchase_order_id !== '-1')
                    params.purchase_order_id = form.purchase_order_id;
                let data = new FormData();
                data.append('xml', form.xml);
                data.append('pdf', form.pdf);
                console.log("DATA FORM: ", data);
                let response = await uploadInvoice({ headers, data, params });
                console.log("RESPONSE:: ", response);
                // let response = await Fetcher({
                //     url: 'invoices/upload',
                //     method: 'POST',
                //     params, data, headers
                // });
                if (response.status === 201) {
                    setInvoice(response.data.data);
                    setModalSize('lg');
                    setSuccessfull(true);
                    setToast({
                        title: t(`${p_toasts}.success-xml.title`), status: "success", toast,
                        description: t(`${p_toasts}.success-xml.description`),
                    });
                } else {
                    setToast({
                        title: t(`${p_toasts}.warning-xml.title`), status: "warning", toast,
                        description: t(`${p_toasts}.warning-xml.description`),
                    });
                }
            } catch (error) {
                setToast({
                    title: t(`${p_toasts}.error-xml.title`), status: "error", toast,
                    description: t(`${p_toasts}.error-xml.description`),
                });
                console.log("error: ", error);
            }
            setLoading(false);
        }
        setValidated(true);
    }

    const discardInvoice = async () => {
        try {
            await Fetcher({
                url: `invoices/${invoice.id}`,
                method: 'DELETE',
                headers
            });
            handleClose();
            setToast({
                title: t(`${p_toasts}.warning.title`), status: "warning", toast,
                description: t(`${p_toasts}.warning.description`),
            });
        } catch (error) {
            setToast({
                title: t(`${p_toasts}.error.title`), status: "error", toast,
                description: t(`${p_toasts}.error.description`),
            });
        }
    }

    const saveAndExit = () => {
        setToast({
            title: t(`${p_toasts}.success.title`), status: "success", toast,
            description: t(`${p_toasts}.success.description`),
        });
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose} size={modal_size} backdrop="static" keyboard={false}>
            <Form noValidate validated={validated} onSubmit={send}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {loading ? t(`${prefix}.loading`) : t(`${prefix}.title`)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {successfull ?
                        <FormInvoice data={invoice} /> :
                        !loading ?
                            <FormFilesUpload
                                handleLoadFile={handleLoadFile}
                            /> : <Progress size="xs" isIndeterminate />
                    }
                </Modal.Body>
                {!loading ?
                    <Modal.Footer>
                        {successfull ? <Fragment>
                            <Button variant="outline-danger" onClick={discardInvoice}>{t(`${prefix}.button-danger`)}</Button>
                            <Button variant="outline-success" onClick={saveAndExit}>{t(`${prefix}.button-success`)}</Button>
                        </Fragment> : <Fragment>
                            <Button variant="outline-secondary" onClick={handleClose}>{t(`${prefix}.button-close`)}</Button>
                            <Button variant="outline-primary" type={"submit"}>{t(`${prefix}.button-primary`)}</Button>
                        </Fragment>}
                    </Modal.Footer> : null
                }
            </Form>
        </Modal>
    );
}

export default UploadInvoice;