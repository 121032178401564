import { Container } from "react-bootstrap";
import { Result, Button } from 'antd';
import '../../styles/Logout.Component.css';
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

const Logout = () => {

    const [cookie, setCookie] = useCookies(['user']);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (cookie.user) {
            setCookie('user', null, {
                path: "/",
                maxAge: 0, // Expires after 1hr
                sameSite: true,
            });
            dispatch({ type: 'CLOSE_SESSION' });
            window.location.href = '/';
        }
    }, [history]);

    return (
        <Container id='logout-component'>
            <Result
                status="500"
                title="500"
                subTitle="Lo sentimos, su sessión ha caducado."
                extra={<Button type="primary">Back Home</Button>}
            />,
        </Container>
    );
}

export default Logout;