import { faBoxes } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { getCfdiStatuses } from "../../api/Cfdi/CfdiStatuses";
import { getInvoices } from "../../api/Cfdi/Mailbox";
import Mailbox from "./fragments/Mailbox"
import ModalCfdi from "./fragments/ModalCfdi";

const MailboxAdmin = () => {
    const store = useStore();
    const { fibra, role_selected, token } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };

    const [mailbox, setMailbox] = useState([]);             // Lista de CFDi's
    const [modalCfdi, setModalCfdi] = useState(false);      // Modal para cambiar permisos CFDI
    const [invoice, setInvoice] = useState(null);           // Factura seleccionada para mostrar en el modal
    const [cfdiStatuses, setCfdiStatuses] = useState([]);   // Estados CFDi de la fibra
    // Paginación
    const [totalItems, setTotalItems] = useState(0);        // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                    // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);           // Cantidad de registros por página
    const [loading, setLoading] = useState(true);           // indica si se están obteniendo registros
    const [cfdi_status_id, setCfdiStatusId] = useState(null);
    const [t, i18n] = useTranslation('global');
    const [string, setString] = useState('');                   // Cadena para hallar coincidencias

    useEffect(() => {
        getCfdis();
        getMailbox();
    }, [page, pageSize, cfdi_status_id]);

    const getMailbox = async () => {
        setLoading(true);
        let params = { headers, page, pageSize, string };
        if (cfdi_status_id) params.cfdi_status_id = cfdi_status_id;
        let response = await getInvoices({
            ...params, fibra_id: fibra.id,
            role_id: role_selected.id
        });
        setMailbox(response.data);
        if (response.totalItems) setTotalItems(response.totalItems);
        setLoading(false);
    }

    const openOrCloseCfdi = () => setModalCfdi(!modalCfdi);
    const changeStatus = (item) => {
        setInvoice(item);
        openOrCloseCfdi();
    }

    // Método que obtiene los estados CFDi que pertenecen a la fibra
    const getCfdis = async () => {
        let response = await getCfdiStatuses({ headers, fibra_id: fibra.id });
        setCfdiStatuses(response);
    }
    // Buscar coincidencias de cadena
    const searchInvoice = () => {
        getMailbox();
    }

    return (
        <Fragment>
            <Mailbox
                is_admin
                mailbox={mailbox}                   // Colección de registros obtenidos
                loading={loading}                   // Indicador de carga de registros de facturas
                invoice={invoice}                   // Factura seleccionada
                setInvoice={setInvoice}             // Factura seleccionada
                setCfdiStatusId={setCfdiStatusId}   // Guardar el estado de CFDi para actualizar
                page={page}                         // Página actual
                setPage={setPage}                   // Método para cambiar la página
                pageSize={pageSize}                 // Tamaño de la página
                setPageSize={setPageSize}           // Método para cambiar el tamaño de la página
                totalItems={totalItems}             // Total de registros existentes
                tactions={[                          // Botones que son visibles para el administrador
                    {
                        handleClick: changeStatus, icon: faBoxes, tooltip: t('mailbox.table.tooltips.change-cfdi'), variant: 'outline-secondary'
                    }
                ]}
                string={string}
                setString={setString}
                searchInvoice={searchInvoice}
            />

            {/* Modal para cambiar permisos CFDI */}
            {
                modalCfdi &&
                <ModalCfdi
                    onClose={openOrCloseCfdi}
                    invoice={invoice}
                    cfdiStatuses={cfdiStatuses}
                    headers={headers}
                    update={getMailbox}
                />
            }
        </Fragment>
    );
}

export default MailboxAdmin;