import { faEye, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useState } from "react";
import { Modal, Button, Table, Form } from "react-bootstrap";
import { Badge } from "@chakra-ui/react";
import { Switch } from 'antd';
import Fetcher from "../../../../libs/Petition";
import _ from 'underscore';
import downloadFile from "../../../../libs/DownloadFile";
import moment from "moment";
import PDFViewerComp from "../../../Layouts/PDFViewer";
import getBlobFile from "../../../../libs/getBlobFile";
import getZipFiles from "../../../../libs/getZipFiles";
import { useTranslation } from "react-i18next";

const Documentation = (props) => {
    const { headers, user, fibra, toast, update } = props; // Desestructuración del PROPS

    const [list_docs, setListDocs] = useState([]);  // Documentos deñ proveedor
    const [docs, setDocs] = useState([]);           // Documento solicitados
    const [docs_zip, setDocsZip] = useState([]);     // Documentos para deescargar como ZIP
    const [show, setShow] = useState(true);         // Modal view: Activa por default
    const [file, setFile] = useState(null);         // Archivo seleccionado para la vista previa
    const [view_pdf, setViewPDF] = useState(false); // Mostrar componente de vista previa
    const [checks, setChecks] = useState([]);       // True o False para cada check de cada tupla 
    const [t, i18n] = useTranslation('global');
    const prefix = [
        'repse.users.documentation.statuses',
        'repse.users.documentation',
        'repse.users.documentation.headers'
    ];

    useEffect(() => {
        getListDocs();
        getDocs();
    }, []);

    // Obtenemos la lista de documentos que ha proporcionado el proveedor
    const getListDocs = async () => {
        try {
            let response = await Fetcher({
                url: `/repse/user_docs`, method: 'GET',
                headers, params: { user_id: user.id }
            });
            if (response.status === 200) {
                let { data } = response.data;
                setListDocs(data);
            }
        } catch (error) { setListDocs([]); }
    }

    // Obtenemos la lista de documentos que son requeridos
    const getDocs = async () => {
        try {
            let response = await Fetcher({
                url: '/repse/docs', method: 'GET',
                headers, params: { fibra_id: fibra.id }
            });
            if (response.status === 200) {
                let { data } = response.data;
                setDocs(data);
                setChecks(Array(data.length).fill(false));
            }

        } catch (error) { setDocs([]); }
    }

    // Evento para cerrar modal
    const handleClose = () => {
        props.onClose(); setShow(false)
    }

    // Obtiene la posición del documento seleccionado en el array de documentos proporcionados por el proveedor
    const getPosListDocs = (id) => { return _.findIndex(list_docs, { repse_doc_id: id }); }
    const getPosDocs = (id) => { return _.findIndex(docs, { id }); }

    // Obtiene "NA" en caso de que el documento aún no haya sido proporcionado por el proveedor
    const getStatus = (id) => {
        let res = "NA", pos = getPosListDocs(id);
        if (pos !== -1) {
            switch (list_docs[pos].status) {
                case 'validating': res = <Badge colorScheme="orange">{t(`${prefix[0]}.validating`)}</Badge>; break;
                case 'accepted': res = <Badge colorScheme="green">{t(`${prefix[0]}.accepted`)}</Badge>; break;
                case 'rejected': res = <Badge colorScheme="red">{t(`${prefix[0]}.rejected`)}</Badge>; break;
                default: res = "NA"; break;
            }
        }
        return res;
    }

    // Obtiene la fecha en la que el proveedor cargó su documento
    const getFecha = (id) => {
        let res = t(`${prefix[0]}.pending`), pos = getPosListDocs(id);
        if (pos !== -1) res = list_docs[pos].created_at;
        return res;
    }

    // Descarga el archivo que el proveedor proporcionó
    const getFile = (doc) => {
        let pos = getPosListDocs(doc.id);
        let id = list_docs[pos].id;
        downloadFile({
            url: `/repse/user_docs/download/${id}`,
            type: 'application/json',
            name: `${doc.name}`, toast, headers
        });
    }


    const getBFile = async (id) => {
        let pos = getPosListDocs(id);
        let blob_file = await getBlobFile({ url: `repse/user_docs/download/${list_docs[pos].id}`, headers });
        // console.log("Blob file: ", blob_file);
        setFile(blob_file)
    }

    // Cambiar el status del documento enviado por el proveedor
    const handleChange = async (checked, id) => {
        let pos = getPosListDocs(id);
        try {
            let response = await Fetcher({
                url: `repse/user_docs/${list_docs[pos].id}`, method: 'PUT',
                headers, data: { status: checked ? 'accepted' : 'rejected' }
            });
            if (response.status === 200) {
                getListDocs();
                update();
            }
        } catch (error) { }
    }

    // Vista previa del documento proporcionado por el proveedor
    const viewPDF = (doc) => { setViewPDF(true); getBFile(doc.id); }

    // Descarga multiple de archivos
    const multipleDownload = async () => {
        getZipFiles({ docs: docs_zip, headers, url: 'repse/user_docs/download', name: `${user.name}_REPSE_DOCS [${moment().format('lll')}]` });
    }

    const selectAll = (e) => {
        let { checked } = e.target;
        if (checked) {
            let _docs = docs.filter((doc) => {
                let { id } = doc;
                let pos = _.findIndex(list_docs, { repse_doc_id: id });
                return pos !== -1;
            }).map((doc) => {
                let { id, name } = doc;
                return { id: list_docs[getPosListDocs(id)].id, name }
            });
            setDocsZip(_docs);
            setChecks(Array(checks.length).fill(true));
        } else { setDocsZip([]) }
        setChecks(Array(docs.length).fill(checked));
    }

    const selectItem = (e) => {
        let { id, checked } = e.target;
        let pos = getPosListDocs(parseInt(id));
        let posDocs = getPosDocs(parseInt(id));
        if (checked) { //Se agregar el documento a al arreglo docs_zip
            setDocsZip(docs_zip => [...docs_zip, { //Se agrega el {id, name} del doc marcado
                id: list_docs[pos].id, name: docs[getPosDocs(parseInt(id))].name
            }]);
            setChecks( // Actualización de checks
                checks.map((check, index) => { return index !== posDocs ? check : true; })
            );
        } else { //Se elimina de docs_zip
            setDocsZip( //Se quita el archivo que se desmarcó
                docs_zip.filter(doc => { return doc.id !== list_docs[pos].id; })
            );
            setChecks( //Se actualizan los checks
                checks.map((check, index) => { return index !== posDocs ? check : false; })
            );
        }
    }

    return (
        <Modal show={show} onHide={handleClose} size='xl' backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                {
                    view_pdf ? <Modal.Title>{t(`${prefix[1]}.title-1`)}</Modal.Title> : <Modal.Title>{t(`${prefix[1]}.title-2`)}</Modal.Title>
                }
            </Modal.Header>
            <Modal.Body>
                {
                    view_pdf ? <PDFViewerComp file={file} /> :
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    {/* Seleccionar todos  */}
                                    <th><Form.Check type='checkbox' onChange={selectAll} defaultChecked={false} /></th>
                                    <th>#</th>
                                    <th>{t(`${prefix[3]}.doc`)}</th>
                                    <th>{t(`${prefix[3]}.status`)}</th>
                                    <th>{t(`${prefix[3]}.date`)}</th>
                                    <th>{t(`${prefix[3]}.actions`)}</th>
                                    <th>{t(`${prefix[3]}.aprove`)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    docs ? docs.map((doc, index) =>
                                        <tr key={index}>
                                            {/* El checkbox indica que elemento se agregará al zip para serdescargado */}
                                            <td><Form.Check type='checkbox' id={doc.id} onChange={selectItem} checked={checks[index]} defaultChecked={false} disabled={getStatus(doc.id) === "NA" ? true : false} /></td>
                                            <td>{index + 1}</td>
                                            <td>{doc.name}</td>
                                            <td>{getStatus(doc.id) === "NA" ? <Badge colorScheme="purple">{t(`${prefix[0]}.pending`)}</Badge> : getStatus(doc.id)}</td>
                                            <td>{getFecha(doc.id)}</td>
                                            <td>
                                                <Button variant="outline-secondary" onClick={() => getFile(doc)} disabled={getStatus(doc.id) === "NA" ? true : false}>
                                                    <FontAwesomeIcon icon={faFileDownload} /></Button>
                                                <Button variant="outline-secondary" onClick={() => viewPDF(doc)} disabled={getStatus(doc.id) === "NA" ? true : false}>
                                                    <FontAwesomeIcon icon={faEye} /></Button></td>
                                            <td><Switch
                                                checkedChildren={t(`${prefix[1]}.checked`)}
                                                unCheckedChildren={t(`${prefix[1]}.unchecked`)}
                                                onChange={(checked) => handleChange(checked, doc.id)}
                                                disabled={getStatus(doc.id) === "NA" ? true : false}
                                                defaultChecked={getPosListDocs(doc.id) !== -1 ? list_docs[getPosListDocs(doc.id)].status === 'accepted' ? true : false : false} /></td>
                                        </tr>
                                    ) : null
                                }
                            </tbody>
                        </Table>
                }
            </Modal.Body>
            <Modal.Footer>
                {!view_pdf ? <Fragment>
                    <Button variant="outline-secondary" onClick={handleClose}>{t(`${prefix[1]}.button-close`)}</Button>
                    { // Si hay documentos seleccionados, se muestra el botón de descarga múltiple
                        docs_zip.length > 0 ? <Button variant="outline-success" onClick={multipleDownload}>{t(`${prefix[1]}.button-download`)}</Button> : null
                    }
                </Fragment>
                    : <Button variant="outline-secondary" onClick={() => setViewPDF(false)}>{t(`${prefix[1]}.button-back`)}</Button>}
            </Modal.Footer>
        </Modal>
    );
}

export default Documentation;