import { createStore, combineReducers } from 'redux';

import reducerLogin from './reducers/login';
import reducerUsers from './reducers/users';
import reducerCfdi from './reducers/cfdi_statuses';
import reducerCookie from './reducers/cookies';
import reducerChat from './reducers/chat';

const appReducers = combineReducers({
    login: reducerLogin,
    users: reducerUsers,
    cfdi: reducerCfdi,
    cookies: reducerCookie,
    chat: reducerChat
});

export default createStore(appReducers);