import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);

const CalendarComp = (props) => {
    const { events, doubleClick, moveEvent, resizeEvent } = props;

    const doucleClickVoid = () => { }
    const moveEventVoid = () => { }
    const resizeEventVoid = () => { }
    // Evento vacío
    const newEvent = (_event) => { }

    return (
        <DragAndDropCalendar
            selectable
            resizable
            localizer={localizer}
            onSelectSlot={newEvent}
            events={events}
            onEventDrop={moveEvent || moveEventVoid}
            onEventResize={resizeEvent || resizeEventVoid}
            style={{ height: 500 }}
            onDoubleClickEvent={doubleClick || doucleClickVoid}
        />
    );
};

export default CalendarComp;
