import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getBlobRelease, showRelease } from "../../../api/Releases";
import { indexRoleReleases } from "../../../api/RoleReleases";
import { indexRoles } from "../../../api/Roles";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import PDFViewerComp from "../../Layouts/PDFViewer";
import TabsComp from "../../Layouts/Tabs";
import FormRelease from "./FormRelease";
import Recipients from "./Recipients";

const _ = require('underscore');

const ModalView = (props) => {
    const { onClose, release, headers, fibra_id } = props;
    const [file, setFile] = useState(null);
    const [data, setData] = useState({});
    const [roles, setRoles] = useState([]);
    const [roleReleases, setRoleReleases] = useState([]);   // Roles a los que va dirigido el comunicado
    const [loading, setLoading] = useState(true);
    const [t, i18n] = useTranslation('global');
    const prefix = 'releases.tabs';

    useEffect(() => {
        getReleaseData();
    }, []);

    const getReleaseData = async () => {
        let blob_file = await getBlobRelease({ headers, release_id: release.id });
        let response = await indexRoles({ headers, fibra_id });
        setRoles(response.data);
        response = await showRelease({ headers, release_id: release.id });
        setData(response);
        response = await indexRoleReleases({ headers, release_id: release.id });
        setRoleReleases(response.data);
        setFile(blob_file);
        setLoading(false);
    }

    const handleChange = () => { }
    // Método para obtener si el registro existe o no
    const getChecked = (role_id) => {
        let pos = _.findIndex(roleReleases, { role_id });
        return pos === -1 ? false : true;
    }
    // Método para indicar si el switch está habilitado
    const getDisabled = () => true;

    return (
        <ModalComp
            onClose={onClose}
            title={release.title}
            size="lg"
            body={<Fragment>
                <TabsComp
                    tablist={[
                        t(`${prefix}.release`), 
                        t(`${prefix}.preview`),
                        t(`${prefix}.addressee`)
                    ]}
                    tabpanels={[
                        <FormRelease  handleChangeFile={() => { }} handleChangeData={() => { }} data={data} />,
                        <PDFViewerComp file={file} />,
                        <Recipients loading={loading} roles={roles} handleChange={handleChange}
                            getChecked={getChecked} getDisabled={getDisabled} />
                    ]}
                />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name={t(`${prefix}.button-close`)} variant="outline-secondary" onClick={onClose} />
            </Fragment>}
        />
    );
};

export default ModalView;
