import { Container } from "react-bootstrap";
import Clients from "./fragments/Clients";
import Users from "./fragments/Users";
import TabsComp from "../Layouts/Tabs";
import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import FibraDetails from "./fragments/FibraDetails";

const SuperAdmin = () => {
    const [t, i18n] = useTranslation('global');
    const prefix = 'super-admin.tabs';
    const [config, setConfig] = useState(false);

    const [fibra, setFibra] = useState({});

    const showConfig = (item) => {
        if (!config) {
            setFibra(item);
        }
        setConfig(!config);
    }


    return (
        <Container fluid>
            {
                config ?
                    <FibraDetails
                        onBack={showConfig}
                        fibra_id={fibra.id}
                        title={`${fibra.code} - ${fibra.name}`}
                    /> :
                    <Clients
                        showConfig={showConfig}
                    />
            }
        </Container>
    );
}

export default SuperAdmin;