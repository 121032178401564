import './App.css';
import { connect } from 'react-redux';
import { Fragment, useEffect } from 'react';
import Login from './components/Login/index';
import App from './components/App';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';

const IndexComponent = (props) => {
	const [cookie, setCookie] = useCookies(['user']);

	useEffect(() => {
		// console.log("PROPS: ", props);
		// console.log("Cookies: ", cookie.user);
		// PedPendProv(); // Prueba de ws de sap
		try {
			if (props.session === null && cookie.user !== undefined) {
				// console.log("Session opened!");
				let { token, apps, roles, user, fibra } = cookie.user;
				props.openSession('active',
					token,
					apps,
					roles,
					user,
					roles[0].name,
					roles[0].modules,
					fibra
				); // Abrimos la sesión
			} else {
				setCookie('user', null, { path: "/", maxAge: 0, sameSite: true, });
			}
		} catch (error) {
			console.log('Error al leer las credenciales almacenadas');
		}

	}, [cookie, props]);

	return (<Fragment>
		<Helmet>
			<title>Victum Inmobiliario</title>
		</Helmet>
		{
			props.session ?
				<App /> :
				<Login />
		}
	</Fragment>);
}

const mapStateToProps = state => ({
	session: state.login.session,
	cookies: state.cookies
});

const mapDispatchToProps = dispatch => ({
	openSession(session, token, apps, roles, user, role_selected, modules, fibra) {
		dispatch({
			type: 'OPEN_SESSION',
			session,
			token,
			apps,
			roles,
			user,
			role_selected,
			modules,
			fibra
		})
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexComponent);
