import { Fragment } from 'react'

const ProviderHome = () => {
	const dashboard = 'https://metabase.victum-re.online/public/dashboard/3d6099d0-3356-40c2-81d2-f4d0072f3f1a';
	return (
		<Fragment>
			<iframe
				src={dashboard}
				frameborder="0"
				width="100%"
				height="900"
				allowtransparency
			></iframe>
		</Fragment>
	)
}

export default ProviderHome;