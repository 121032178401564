import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { storeChatMessages } from '../../../api/Chat/Message';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import FormIcon from '../../Layouts/Forms/FormIcon';

const Send = (props) => {
	const { chat_room_id, headers, user_id } = props;

	const [message, setMessage] = useState('');             // Mensaje que se envía

	const handleMessage = (e) => {
		setMessage(e.target.value);
	}

	const sendMessage = async () => {
		if (chat_room_id) {
			await storeChatMessages({
				headers,
				data: {
					user_id,
					message,
					chat_room_id
				}
			});
			setMessage('');
		}
	}

	const enterEvent = (e) => {
		if (e.key === 'Enter') sendMessage();
	}

	return (
		<Row className="m-3" style={{ heigth: '10vh' }}>
			<Col sm={11}>
				<FormIcon
					placeholder="Escribe un mensaje"
					handleChange={handleMessage}
					value={message}
					onKeyDown={enterEvent}
				/>
			</Col>
			<Col sm={1}>
				<ButtonIcon
					icon={faPaperPlane}
					variant='outline-success' size="md"
					tooltip='Enviar mensaje'
					onClick={sendMessage}
					disabled={chat_room_id ? false : true}
				/>
			</Col>
		</Row>
	)
}

export default Send;
