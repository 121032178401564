import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCfdiStatuses, storeCfdiStatus, updateCfdiStatus } from "../../../api/Cfdi/CfdiStatuses";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import FormGroupComponent from "../../Layouts/Forms/FormGroup";
import ModalComp from "../../Layouts/Modal";

const ModalStatusAddEdit = (props) => {
    const { onClose, update, cfdi_status, headers, type, title, fibra_id } = props;
    const [data, setData] = useState({});
    const dispatch = useDispatch();
    const role_selected = useSelector(state => state.login.role_selected);

    const handleChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const saveChanges = async () => {
        let response = false;
        if (type === 'add') { // Se crea el nuevo estado CFDi
            response = await storeCfdiStatus({ headers, data: { ...data, fibra_id } });
        } else { // Se actualiza el estado CFDi
            response = await updateCfdiStatus({ headers, data, cfdi_status_id: cfdi_status.id });
        };
        if (response) {
            // Actualizar estado de redux
            response = await getCfdiStatuses({ headers, fibra_id, role_id: role_selected.id });
            dispatch({ type: 'SET_CFDI_STATUSES', cfdi_statuses: response });
            update(); onClose();
        }

    }
    return (
        <ModalComp
            onClose={onClose}
            title={title}
            body={<Fragment>
                <Form>
                    <Row className='mb-2'>
                        <FormGroupComponent
                            title='Código' defaultValue={cfdi_status?.code} name='code' value={data?.code}
                            handleChange={handleChange} />
                    </Row>
                    <Row className='mb-2'>
                        <FormGroupComponent
                            title='Nombre' defaultValue={cfdi_status?.name} name='name' value={data?.name}
                            handleChange={handleChange} />
                    </Row>
                </Form>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name='Cerrar' variant='outline-secondary' onClick={onClose} />
                <ButtonIcon icon={faSave} name='Guardar' variant='outline-primary' onClick={saveChanges} />
            </Fragment>}
        />
    );
}

export default ModalStatusAddEdit;