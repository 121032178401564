import { Box, Text } from '@chakra-ui/react';
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { twoFactorAuth } from '../../../api/Authentification';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import TwoFactorModal from './TwoFactorModal';
import { useDispatch, useSelector } from 'react-redux';
import { OPEN_SESSION } from '../../../redux/actions/main';
import { useTranslation } from 'react-i18next';

const TwoFactorAuthComp = () => {
    const user = useSelector(state => state.login.user);
    const token = useSelector(state => state.login.token);
    const headers = { Authorization: `Bearer ${token}` };
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [type, setType] = useState('');
    const [t, i18n] = useTranslation('global');
    const prefix = 'settings.security';

    const openOrClose = () => setShowModal(!showModal);

    const confirm = async () => {
        let response = await twoFactorAuth({ headers, data: { user_id: user.id, has_two_factor: type === 'enable' ? true : false } });
        if (response.res) {
            let { token, apps, roles, user, fibra } = response.data;
            let role_selected = roles.length > 0 ? roles[0].name : null;
            let modules = role_selected.modules;
            dispatch({
                type: OPEN_SESSION,
                token,
                apps,
                roles,
                user,
                role_selected,
                modules,
                fibra,
            });
        }
    }
    const enableTwoFactor = () => {
        setType('enable');
        openOrClose();
    }

    const disableTwoFactor = () => {
        setType('disable');
        openOrClose();
    }

    return (
        <Fragment>
            <Box p={5} shadow='md' borderWidth='1px' className='mt-3'>
                <Row className=''>
                    <Col className='col-10'>
                        <Text>
                            {
                                user.has_two_factor ?
                                    `${t(`${prefix}.two-factor-enable`)} ${user.two_factor_since}`  // Habilitado desde
                                    : t(`${prefix}.two-factor-disable`)                             // Autentificación en dos pasos desactivada
                            }
                        </Text>
                    </Col>
                    <Col className='col-2 d-flex align-items-start'>
                        {
                            user.has_two_factor ? <ButtonIcon name={t(`${prefix}.button-disable`)} variant='outline-danger' icon={faToggleOff} onClick={disableTwoFactor} /> :
                                <ButtonIcon name={t(`${prefix}.button-enable`)} variant='outline-success' icon={faToggleOn} onClick={enableTwoFactor} />
                        }
                    </Col>
                </Row>
            </Box>
            {/* Modal para confirmación */}
            {
                showModal && <TwoFactorModal
                    onClose={openOrClose}
                    type={type}
                    confirm={confirm}
                />
            }
        </Fragment>
    );
};

export default TwoFactorAuthComp;
