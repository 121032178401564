import { Document, Page } from 'react-pdf';
import { useEffect, useState } from "react";
import { Col, Row, Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const PDFViewerComp = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [t, i18n] = useTranslation('global');
    const prefix = 'layouts.pdf-viewer';

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }
    useEffect(() => {
        console.log("Props file: ", props);
    }, []);

    const changePage = (number) => {
        if (number < pageNumber && number > 1) setPageNumber(number);
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Document
                        file={props.file}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={t(`${prefix}.loading`)}
                        noData={t(`${prefix}.no-data`)}
                        error={t(`${prefix}.error`)}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col><Button variant="outline-secondary" onClick={() => changePage(pageNumber - 1)}>{t(`${prefix}.prev`)}</Button></Col>
                <Col><p>{t(`${prefix}.page`)} {pageNumber} {t(`${prefix}.of`)} {numPages}</p></Col>
                <Col><Button variant="outline-secondary" onClick={() => changePage(pageNumber + 1)}>{t(`${prefix}.next`)}</Button></Col>
            </Row>
        </Container>
    );
}

export default PDFViewerComp;