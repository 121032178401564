import { SET_CHAT_ROOM_ID } from '../actions/main';
import { stateChat as initialState } from '../initialState/main';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CHAT_ROOM_ID:
            return {
                ...state,
                room_id: action.chat_room_id
            };
        default: return state;
    }
}

export default reducer;