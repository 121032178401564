import { useTranslation } from "react-i18next";
import PollsTable from "./fragments/Table";

const PollsAdmin = () => {
    const [t, i18n] = useTranslation('global');
    const prefix = 'polls.data';
    return (
        <PollsTable
            is_admin
            theaders={[
                '#',
                t(`${prefix}.name`),
                t(`${prefix}.to`),
                t(`${prefix}.status`),
                t(`${prefix}.created-at`),
                t(`${prefix}.actions`)
            ]}
            tkeys={['id', 'name', 'to', 'status', 'created_at']}
            tbadges={[{ index: 3, color: 'status_color' }]}
            params={{}}
        />
    );
}

export default PollsAdmin;