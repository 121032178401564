import { useToast } from "@chakra-ui/toast";
import { Fragment, useEffect, useState } from "react";
import { useStore } from "react-redux";
import Fetcher from "../../../libs/Petition";
import setToast from "../../../libs/SetToast";
import { Col, Row } from "react-bootstrap";
import { Card } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import FlexButton from "../../Layouts/FlexButton";
import AddStatus from "./Statuses/AddStatus";
import { useTranslation } from "react-i18next";

const { Meta } = Card;

const Statuses = () => {
    const store = useStore();
    const { token, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };

    const [statuses, setStatuses] = useState([]);
    const [status_selected, setStatusSelected] = useState(null);
    const [modal_add, setModalAdd] = useState(false);
    const toast = useToast();
    const [t, i18n] = useTranslation('global');

    useEffect(() => {
        getStatuses();
    }, []);

    const getStatuses = async () => {
        try {
            let response = await Fetcher({
                url: 'repse/statuses', method: 'GET',
                headers, params: { fibra_id: fibra.id }
            });
            // console.log("response: ", response);
            if (response.status === 200)
                setStatuses(response.data.data);
            else
                setToast({
                    title: t('repse.statuses.toasts.wrn.title'), status: "warning", toast,
                    description: response.data.message,
                });
        } catch (error) {
            setStatuses([]);
        }
    }

    const openOrCloseModalAdd = () => {
        setModalAdd(!modal_add);
    }

    const edit = (status) => {
        setStatusSelected(status);
        openOrCloseModalAdd();
    }

    const add = () => {
        setStatusSelected(null);
        openOrCloseModalAdd();
    }

    return (
        <Fragment>
            {/* Lista los estados disponibles */}
            <Row xs={1} md={4} className="g-4 mt-2">
                {
                    statuses ?
                        statuses.map((status, index) =>
                            <Card key={index} style={{ width: 300, marginTop: 16, marginLeft: 16 }}
                                as={Col} actions={[
                                    <EditOutlined key="edit" onClick={() => edit(status)} />,
                                    <DeleteOutlined key="delete" />,
                                ]}>
                                <Meta
                                    title={status.name}
                                    description={status.description}
                                />
                            </Card>

                        ) : null
                }
            </Row>
            {/* FAB  para agregar un nuevo estado */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={add} />

            {/* Modal para agregar nuevo status */}
            {modal_add ?
                <AddStatus
                    onClose={openOrCloseModalAdd}
                    headers={headers}
                    fibra_id={fibra.id}
                    status={status_selected}
                    update={getStatuses} />
                : null
            }
        </Fragment>
    );
}

export default Statuses;