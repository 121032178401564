import { Fragment, useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import FormIcon from "../../Layouts/Forms/FormIcon";
import SkeletonForm from "../../Layouts/Skeletons/Form";

const MainData = (props) => {
    const { event, loading, data } = props;

    useEffect(() => {

    }, []);

    return <Fragment>
        {
            loading ? <SkeletonForm rows={4} cols={3} /> :
                <Form>
                    <Row className="mt-3">
                        <FormIcon title="Código de entrega" value={data?.code} />
                        <FormIcon title="Fecha de entrega" value={data?.start_date} />
                        <FormIcon title="Fecha de entrega" value={data?.end_date} />
                    </Row>
                    <Row className="mt-3">
                        <FormIcon title="Proveedor" value={data?.provider} />
                        <FormIcon title="Orden de compra" value={data?.purchase_order} />
                    </Row>
                    <Row className="mt-3">
                        <FormIcon title="Fecha de creación" value={data?.created_at} />
                        <FormIcon title="Fecha última actualización" value={data?.created_at} />
                    </Row>
                </Form>
        }
    </Fragment>;
};

export default MainData;
