import { Heading } from '@chakra-ui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import TabsComp from '../Layouts/Tabs';
import Security from './Security/Security';
import Providers from './tabs/Providers';
import Roles from './tabs/Roles';
import Statuses from './tabs/Statuses';
import Users from './tabs/Users';

const AdminSettings = () => {
    const store = useStore();
    const { token, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [t, i18n] = useTranslation('global');

    return (
        <Fragment>
            <Heading className='mb-3'>{t('settings.title')}</Heading>
            <TabsComp
                tablist={[
                    t('settings.tabs.roles'),
                    t('settings.tabs.cfdi-statuses'),
                    t('settings.tabs.users'),
                    t('settings.tabs.security')
                ]}
                tabpanels={[
                    <Roles
                        headers={headers}
                        fibra={fibra}
                    />,
                    <Statuses
                        headers={headers}
                        fibra={fibra}
                    />,
                    <Users
                        headers={headers}
                        fibra_id={fibra.id}
                    />,
                    <Security />,
                    <Providers
                        headers={headers}
                        fibra_id={fibra.id}
                    />
                ]}
            />
        </Fragment>
    );
};

export default AdminSettings;