import { PageHeader, Select } from "antd";
import { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Signup from "../../Signup/Index";

const { Option } = Select;

const SignupForm = (props) => {
    const { goBack } = props;
    const [t, i18n] = useTranslation('global');
    const [fibraID, setFibraID] = useState(2);

    const handleChangeFibra = (value) => {
        // let { value } = e.target;
        setFibraID(value);
    }

    useEffect(() => {
        console.log("Fibra ID: ", fibraID);
    }, [fibraID]);
    return (
        <Container>
            <Card id='signup-form'>
                <Card.Body>

                    <PageHeader
                        onBack={() => goBack()}
                        title={t('login.signup.title')}
                        subTitle={t('login.signup.subtitle')}
                        extra={[
                            <Select placeholder='Fibra' onChange={handleChangeFibra} name='type' style={{ width: 180 }} defaultValue={fibraID}>
                                <option value={1}>Fibra Danhos</option>
                                <option value={2}>Grupo Dragón</option>
                            </Select>,
                        ]}
                    />
                    {/* Formulario para registrar proveedor. path: Signup/Index*/}
                    <Signup fibra_id={fibraID} goBack={goBack}/>
                </Card.Body>
            </Card>
        </Container >
    );
}

export default SignupForm