import { Col, Form, Row } from "react-bootstrap";
import { List, ListItem } from "@chakra-ui/layout";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import { Divider, Popconfirm } from 'antd';
import { useToast } from "@chakra-ui/toast";
import setToast from "../../../libs/SetToast";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import FormIcon from "../../Layouts/Forms/FormIcon";

const Questions = (props) => {
    const { questions, setQuestions, questionsDel, setQuestionsDel } = props;
    const toast = useToast();
    const [newQuestion, setNewQuestion] = useState('');
    const [t, i18n] = useTranslation('global');
    const prefix = 'polls.edit-questions';

    const confirm = (question) => {
        setQuestions(questions.filter((q) => q.id !== question.id));
        setQuestionsDel([...questionsDel, { id: question.id }]);
    }

    const question = (e) => {
        let { value } = e.target;
        setNewQuestion(value);
    }
    const addQuestion = () => {
        setQuestions([...questions, { question: newQuestion }]);
        setNewQuestion('');
        setToast({
            title: t('polls.toasts.success.title'), description: t('polls.toasts.success.description'),
            status: "success", toast
        });
    }

    return (
        <Form>
            <Row className='mb-3'>
                <FormIcon name='newQuestion' title={t(`${prefix}.input-add`)} handleChange={question} value={newQuestion} />
                <Col className='d-flex align-items-end col-3'>
                    <ButtonIcon name={t(`${prefix}.button-add`)} variant='outline-success' icon={faPlus} onClick={addQuestion} />
                </Col>
            </Row>
            <List spacing={3}>
                {
                    questions && questions.map((question, index) =>
                        <ListItem>
                            <Row className=''>
                                <Col className='col-11'>
                                    {index + 1}. {question?.question}
                                </Col>
                                <Col className='col-1 d-flex align-items-start'>
                                    <Popconfirm
                                        title={t(`${prefix}.popconfirm`)}
                                        onConfirm={() => confirm(question)}
                                        okText={t(`${prefix}.yes`)}
                                        cancelText={t(`${prefix}.no`)}
                                    >
                                        <ButtonIcon icon={faTrashAlt} variant='outline-danger' tooltip={t(`${prefix}.tooltip-del`)} />
                                    </Popconfirm>
                                </Col>
                            </Row>
                            <Divider />
                        </ListItem>

                    )
                }
            </List>
        </Form>
    );
}

export default Questions;