import Fetcher from "../../libs/Petition";

const url = '/quizz_question_answers';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param quizz_answer_id Identificador de la encuesta relacionada al usuario y rol que se está respondiendo
 * @returns Object {"data" => QuizzQuestionAnswers::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexQuizzQuestionAnswer = async (props) => {
    const {
        headers,
        quizz_question_id} = props;
    let response = [];
    try {
        // Parámetros requeridos
        let params = { quizz_question_id };
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = {
            data: [],
            totalItems: 0
        };
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param quizz_question_answer_id Identificador de la respuesta sobre el que se hará la consulta
 * @returns Incident Retorna un objeto tipo Incident
 */
export const showQuizzQuestionAnswer = async ({ headers, quizz_question_answer_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}/${quizz_question_answer_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeQuizzQuestionAnswer = async ({ headers, data }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status !== CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param quizz_question_answer_id Identificador de la respuesta que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateQuizzQuestionAnswer = async ({ headers, data, quizz_question_answer_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${quizz_question_answer_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = true;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param quizz_question_answer_id Identificador de la respuesta que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyQuizzQuestionAnswer = async ({ headers, quizz_question_answer_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${quizz_question_answer_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}