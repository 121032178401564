import Fetcher from "./Petition";

const getBlobFile = async (props) => {
    let { url, headers } = props;
    let file = null;
    try {
        let response = await Fetcher({
            method: 'GET', url,
            responseType: 'blob', headers
        });
        if (response.status === 200) file = response.data;
    } catch (error) {
        file = null;
    } finally {
        return file;
    }
}

export default getBlobFile;