import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PollsTable from "./fragments/Table";

const PollsProvider = () => {
    const role = useSelector(state => state.login.role_selected);
    const [t, i18n] = useTranslation('global');
    const prefix = 'polls.data';
    return (
        <PollsTable
            theaders={[
                '#',
                t(`${prefix}.name`),
                t(`${prefix}.to`),
                t(`${prefix}.status`),
                t(`${prefix}.created-at`),
                t(`${prefix}.actions`)
            ]}
            tkeys={['id', 'name', 'to', 'status', 'created_at']}
            tbadges={[{ index: 3, color: 'status_color' }]}
            params={{ role_id: role.id, active: 1 }}
        />
    );
}

export default PollsProvider;