import { Avatar, AvatarBadge, Box, Flex, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import { Badge } from 'antd';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const Room = (props) => {
    const { room, count } = props;
    const chat_room_id = useSelector(state => state.chat.room_id);

    return (
        <Flex>
            <Box
                w='100%'
                bgColor={room.id === chat_room_id ? 'gray.300' : '#fff'}
                className='p-3'
                borderBottomColor='blackAlpha.600'
                _hover={{
                    background: "gray.200",
                    cursor: "pointer"
                }}
            >
                <Stack alignItems='center' direction='row'>
                    <Avatar size='md'>
                        <AvatarBadge
                            boxSize='1em'
                            bg='green.500'
                            border={'none'}
                        />
                    </Avatar>
                    <Stack direction='column' style={{ width: '100%' }}>
                        <Heading size='sm'>{room.name}</Heading>
                        <Text color={room.id === chat_room_id ? 'gray.600' : 'gray.400'} size='xs'>{room?.last_message?.message}</Text>
                    </Stack>
                    <Badge  count={count} className='mr-0'/>
                </Stack>
                <div className='d-flex justify-content-end mt-0 pt-0'>
                    <Text fontSize='13px' color='gray.400'>
                        {moment(room?.last_message?.created_at).startOf('second').fromNow()}
                    </Text>
                </div>
            </Box>
        </Flex>
    )
}

export default Room;