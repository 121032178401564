import { Col, Form, FormSelect, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormIcon from "../../Layouts/Forms/FormIcon";

const Main = (props) => {
    const { quizz, data, handleChange } = props;
    const [t, i18n] = useTranslation('global');

    return (
        <Form>
            <Row className='mb-3'>
                <FormIcon name='name' title={t('polls.data.name')} defaultValue={quizz?.name} value={data?.name} handleChange={handleChange} />
                <FormIcon name='created_at' title={t('polls.data.created-at')} defaultValue={quizz?.created_at} value={data?.created_at} />
                <Col>
                    <Form.Label>{t('polls.data.status')}</Form.Label>
                    <FormSelect name='status' defaultValue={quizz?.status} value={data?.status} onChange={handleChange}>
                        <option value={1}>{t('polls.statuses.active')}</option>
                        <option value={0}>{t('polls.statuses.inactive')}</option>
                    </FormSelect>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <Form.Label>{t('polls.data.description')}</Form.Label>
                    <textarea className='form-control' name='description' defaultValue={quizz?.description}
                        value={data?.description} onChange={handleChange}></textarea>
                </Col>
            </Row>
        </Form>
    );
}

export default Main;