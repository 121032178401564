import { Heading } from '@chakra-ui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import TabsComp from '../Layouts/Tabs';
import Security from './Security/Security';

const ClientSettings = () => {
    const [t, i18n] = useTranslation('global');
    return (
        <Fragment>
            <Heading className='mb-3'>{t('settings.title')}</Heading>
            <TabsComp
                tablist={[
                    t('settings.tabs.security')
                ]}
                tabpanels={[
                    <Security />
                ]}
            />
        </Fragment>
    );
};

export default ClientSettings;