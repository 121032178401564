import { Fragment } from "react";
import { Heading } from '@chakra-ui/react';
import TableComp from "../Layouts/Table";
import { useState } from "react";
import { useEffect } from "react";
import { indexCedis } from "../../api/Cedis/Cedis";
import { useSelector } from "react-redux";
import PaginationComp from "../Layouts/Pagination";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import FlexButton from "../Layouts/FlexButton";
import ModalAdd from "./fragments/ModalAdd";
import ModalView from "./fragments/ModalView";

const CedisAdmin = () => {
    const [cedis, setCedis] = useState([]);
    const [cedisSel, setCedisSel] = useState({});
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    // Paginación
    const [totalItems, setTotalItems] = useState(0);        // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                    // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);           // Cantidad de registros por página
    const [loading, setLoading] = useState(true);           // indica si se están obteniendo registros
    const [modalView, setModalView] = useState(false);
    const [modalAdd, setModalAdd] = useState(false);

    useEffect(() => {
        getCedis();
    }, []);

    const getCedis = async () => {
        setLoading(true);
        let response = await indexCedis({ headers, fibra_id: login.fibra.id });
        setCedis(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }
    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const openOrCloseModalView = () => setModalView(!modalView);
    const openOrCloseModalAdd = () => setModalAdd(!modalAdd);

    const viewCenter = (item) => {
        setCedisSel(item);
        openOrCloseModalView();
    }

    const addCenter = () => {
        setCedisSel({});
        openOrCloseModalAdd();
    }
    const updateCenter = (item) => {
        setCedisSel(item);
        openOrCloseModalAdd();
    }
    return (
        <Fragment>
            <Heading>Centros de distribución</Heading>
            <TableComp
                headers={['#', 'Código', 'Nombre', 'Creado en', 'Acciones']}
                keys={['id', 'code', 'name', 'created_at']}
                body={cedis}
                actions={[
                    { icon: faEye, variant: 'outline-primary', handleClick: viewCenter, tooltip: 'Ver más' },
                    { icon: faEdit, variant: 'outline-success', handleClick: updateCenter, tooltip: 'Editar centro de distribución' },
                ]}
                loading={loading}
                loadingItems={pageSize}
            />
            {/* Componente de paginación */}
            <PaginationComp
                current={page}
                totalItems={totalItems}
                onChange={changePage}
            />
            {/* Componente para agregar otro centro de distribución */}
            <FlexButton style={{ bottom: 10, right: 10 }} onClick={addCenter} />
            {/* Modal para agregar registro */}
            {
                modalAdd && <ModalAdd
                    onClose={openOrCloseModalAdd}
                    headers={headers}
                    update={getCedis}
                    fibra_id={login.fibra.id}
                    cedis={cedisSel}
                />
            }
            {/* Modal para onsultar detalles */}
            {
                modalView && <ModalView
                    onClose={openOrCloseModalView}
                    cedis={cedisSel}
                    data={cedisSel}
                    headers={headers}
                    fibra_id={login.fibra.id}
                />
            }
        </Fragment>
    );
};

export default CedisAdmin;