import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import ModalComp from '../../Layouts/Modal';
import TabsComp from '../../Layouts/Tabs';
import FormData from './FormData';
import UsersAssigned from './Users';

const ModalView = (props) => {
    const { onClose, cedis, data, headers, fibra_id } = props;
    return (
        <ModalComp
            onClose={onClose}
            title='Vista general'
            size='lg'
            body={<Fragment>
                <TabsComp
                    tablist={['Datos generales', 'Usuarios asignados']}
                    tabpanels={[
                        <FormData
                            cedis={cedis}
                            handleChange={() => { }}
                            data={data}
                        />,
                        <UsersAssigned
                            fibra_id={fibra_id}
                            headers={headers}
                            cedis_id={cedis.id}
                        />
                    ]}
                />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name='Cerrar' variant='outline-secondary' onClick={onClose} />
            </Fragment>}
        />
    );
};

export default ModalView;
