import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Home from "./Home/index";
import About from "./About/index";
import Logout from "./Logout/index";
import PageNotFound from "./PageNotFound/index";
import BillingReports from "./BillingReports";
import Incident from "./Incidents/Client";
import PermissionDenied from "./PermissionDenied";
import IncidentControl from "./Incidents/Admin";
import PrivateRoute from "../libs/Middleware";
import MainLayout from "./Layouts/Layout";
import PurchaseOrderProvider from "./PurchaseOrder/Provider";
import PurchaseOrderDanhos from "./PurchaseOrder/Danhos";
import MallComponent from "./Malls/Index";
import MallDetails from "./Malls/fragments/Details";
import SuperAdmin from "./SuperAdmin/Index";
import RepseAdmin from "./Repse/Admin";
import RepseProvider from "./Repse/Provider";
import MailboxAdmin from "./Mailbox/MailboxAdmin";
import MailboxProvider from "./Mailbox/MailboxProvider";
import MailboxClient from "./Mailbox/MailboxClient";
import ReleasesAdmin from "./Releases/Admin";
import ReleasesProvider from "./Releases/Provider";
import ReleasesClient from "./Releases/Client";
import ComplaintsSuggestionsAdmin from "./ComplaintsSuggestions/Admin";
import ComplaintsSuggestionsProvider from "./ComplaintsSuggestions/Provider";
import ComplaintsSuggestionsClient from "./ComplaintsSuggestions/Client";
import PollsAdmin from "./Polls/Admin";
import PollsProvider from "./Polls/Provider";
import PollsClient from "./Polls/Client";
import TendersAdmin from "./Tenders/Admin";
import TendersProvider from "./Tenders/Provider";
import AccessControl from "./AccessControl/Admin";
import CouponsAdmin from "./Coupons/Admin";
import CouponsClient from "./Coupons/Client";
import Admin from "./Rents/Admin";
import Client from "./Rents/Client";
import AdminContracts from "./Contracts/Admin";
import ClientContracts from "./Contracts/Client";
import AdminSettings from "./Settings/Admin";
import ProviderSettings from "./Settings/Provider";
import ClientSettings from "./Settings/Client";
import CedisAdmin from "./Cedis/Admin";
import CedisDeliveries from "./Deliveries/Admin";
import AdminChat from "./Chat/Admin";
import ProviderChat from "./Chat/Provider";
import ClientChat from "./Chat/Client";
import AdminHome from "./Home/Admin";
import ClientHome from "./Home/Client";
import ProviderHome from "./Home/Provider";

const App = () => {
    // Rutas permitidas para mailbox
    const [mailbox_pipe, setMailboxPipe] = useState('');
    const cfdi = useSelector(state => state.cfdi);

    useEffect(() => {
        // Al autentificarse se elimina la imagen de fondo que tiene el body
        let pipe = '';
        document.body.classList.remove("setImage");
        cfdi.state?.map((status, index) => {
            if (index === 0) {
                pipe += `${status.code}`;
            } else {
                pipe += `|${status.code}`;
            }
        });
        setMailboxPipe(pipe);
    }, [cfdi, mailbox_pipe]);

    // const MailBoxTest = (props) => (<MailboxProvider {...props} status_id={1}/>);

    return (
        // Colección de componente de navegación
        <Router>
            <MainLayout>
                {/* Switch que redirecciona al componente de la ruta establecida */}
                <Switch>
                    {/* 
                        --- N O T A S --- 
                        PrivateRoute: Es un middleware que ayuda a contrlar el acceso en base al nivel de acceso que tiene cada usuario.
                        Route: Rutas que no necesitan protección.
                    */}

                    {/* Ruta de página principal (donde se muestran las gráficas) */}
                    <Route exact path='/' component={Home} />
                    <PrivateRoute exact path='/dashboard-admin' component={AdminHome} role='ADMINISTRADOR'/>
                    <PrivateRoute exact path='/dashboard-client' component={ClientHome} role='CLIENTE'/>
                    <PrivateRoute exact path='/dashboard-provider' component={ProviderHome} role='PROVEEDOR'/>

                    {/* Mailbox:  [MailboxDanhos: Acceso de administrador, MailboxProvider: Acceso restringido]*/}
                    <PrivateRoute exact path='/mailbox/admin' component={MailboxAdmin} role='ADMINISTRADOR' code='CFDI' />
                    <PrivateRoute exact path='/mailbox/provider' component={MailboxProvider} role='PROVEEDOR' code='CFDI' />
                    <PrivateRoute exact path='/mailbox/client' component={MailboxClient} role='CLIENTE' code='CFDI' />
                    {/* <PrivateRoute exact path={`/mailbox/admin/:status(${mailbox_pipe})`} component={MailboxAdmin} role='ADMINISTRADOR' code=''/>
                    <PrivateRoute exact path={`/mailbox/provider/:status(${mailbox_pipe})`} component={MailboxProvider} role='PROVEEDOR' code=''/>
                    <PrivateRoute exact path={`/mailbox/client/:status(${mailbox_pipe})`} component={MailboxClient} role='CLIENTE' /> *code=''/}

                    {/* Reportes de facturación */}
                    <Route exact path='/billing-reports' component={BillingReports} />

                    {/* Control y atención de incidentes */}
                    <PrivateRoute exact path='/incidents' component={Incident} role='CLIENTE' code='C-INC' />
                    <PrivateRoute exact path='/incidents-control' component={IncidentControl} role='ADMINISTRADOR' code='C-INC' />

                    {/* Centros de Distribución */}
                    <PrivateRoute exact path='/cedis/admin' component={CedisAdmin} role='ADMINISTRADOR' code='C-DIS' />
                    <PrivateRoute exact path='/cedis/deliveries' component={CedisDeliveries} role='ADMINISTRADOR' code='C-DEL' />

                    {/* Centros comerciales */}
                    <PrivateRoute exact path='/malls' component={MallComponent} role='ADMINISTRADOR' code='C-COM' />

                    {/* Estacionamientos */}
                    <PrivateRoute exact path='/malls/details/:mall_id' component={MallDetails} role='ADMINISTRADOR' />

                    {/* Contratos */}
                    <PrivateRoute exact path='/client-contracts' component={ClientContracts} role='CLIENTE' code='X-CON' />
                    <PrivateRoute exact path='/contracts' component={AdminContracts} role='ADMINISTRADOR' code='X-CON' />

                    {/* Órdenes de compra */}
                    <PrivateRoute exact path='/purchase-order' component={PurchaseOrderDanhos} role='ADMINISTRADOR' code='O-COM' />
                    <PrivateRoute exact path='/purchase-order-provider' component={PurchaseOrderProvider} role='PROVEEDOR' code='O-COM' />

                    {/* Super admin */}
                    <PrivateRoute exact path='/super-admin' component={SuperAdmin} role='ADMINISTRADOR' />

                    {/* REPSE */}
                    <PrivateRoute exact path='/repse-admin' component={RepseAdmin} role='ADMINISTRADOR' code='U-REP' />
                    <PrivateRoute exact path='/repse-provider' component={RepseProvider} role='PROVEEDOR' code='U-REP' />

                    {/* Comunicados */}
                    <PrivateRoute exact path='/releases' component={ReleasesAdmin} role='ADMINISTRADOR' code='X-COM' />
                    <PrivateRoute exact path='/releases/provider' component={ReleasesProvider} role='PROVEEDOR' code='X-COM' />
                    <PrivateRoute exact path='/releases/client' component={ReleasesClient} role='CLIENTE' code='X-COM' />

                    {/* Buzón de quejas y sugerencias */}
                    <PrivateRoute exact path='/complaints-suggestions' component={ComplaintsSuggestionsAdmin} role='ADMINISTRADOR' code='B-QSU' />
                    <PrivateRoute exact path='/complaints-suggestions/provider' component={ComplaintsSuggestionsProvider} role='PROVEEDOR' code='B-QSU' />
                    <PrivateRoute exact path='/complaints-suggestions/client' component={ComplaintsSuggestionsClient} role='CLIENTE' code='B-QSU' />

                    {/* Encuestas */}
                    <PrivateRoute exact path='/polls' component={PollsAdmin} role='ADMINISTRADOR' code='X-ENC' />
                    <PrivateRoute exact path='/polls/provider' component={PollsProvider} role='PROVEEDOR' code='X-ENC' />
                    <PrivateRoute exact path='/polls/client' component={PollsClient} role='CLIENTE' code='X-ENC' />

                    {/* Licitaciones */}
                    <PrivateRoute exact path='/tenders' component={TendersAdmin} role='ADMINISTRADOR' code='X-LIC' />
                    <PrivateRoute exact path='/tenders/provider' component={TendersProvider} role='PROVEEDOR' code='X-LIC' />

                    {/* Control de acceso */}
                    <PrivateRoute exact path='/access-control' component={AccessControl} role='ADMINISTRADOR' code='C-ACC' />

                    {/* Cupones */}
                    <PrivateRoute exact path='/coupons' component={CouponsAdmin} role='ADMINISTRADOR' code='X-CUP' />
                    <PrivateRoute exact path='/coupons/client' component={CouponsClient} role='CLIENTE' code='X-CUP' />

                    {/* Renta de locales */}
                    <PrivateRoute exact path='/mall/local/admin' component={Admin} role='ADMINISTRADOR' code='R-LOC' />
                    <PrivateRoute exact path='/mall/local/client' component={Client} role='CLIENTE' code='R-LOC' />
                    
                    {/* Chat */}
                    <PrivateRoute exact path='/chat-admin' component={AdminChat} role='ADMINISTRADOR' code='X-MSG' />
                    <PrivateRoute exact path='/chat-provider' component={ProviderChat} role='PROVEEDOR' code='X-MSG' />
                    <PrivateRoute exact path='/chat-client' component={ClientChat} role='CLIENTE' code='X-MSG' />

                    {/* Configuraciones generales */}
                    <PrivateRoute exact path='/settings' component={AdminSettings} role='ADMINISTRADOR' code='X-SET' />
                    <PrivateRoute exact path='/settings/provider' component={ProviderSettings} role='PROVEEDOR' code='X-SET' />
                    <PrivateRoute exact path='/settings/client' component={ClientSettings} role='CLIENTE' code='X-SET' />

                    {/* Rutas generales */}
                    <Route exact path='/About' component={About} />
                    <Route exact path='/logout' component={Logout} />
                    {/* <Route exact path='/Signup' component={Signup}/> */}

                    {/* Vista a la que se redirecciona cuando no se encuentra la ruta */}
                    <Route exact component={PageNotFound} />

                    {/* Vista que se muestra cuando no hay permiso para acceder a la ruta deseada */}
                    <Route exect path='/permission-denied' component={PermissionDenied} />

                </Switch>
            </MainLayout>
        </Router>
    );
}

export default App;