import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from "react";
import { Heading } from "@chakra-ui/layout";
import { useStore } from "react-redux";
import { downloadRelease } from "../../../api/Releases";
import TableComp from "../../Layouts/Table";
import PaginationComp from "../../Layouts/Pagination";
import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import ModalView from "./ModalView";
import { useTranslation } from "react-i18next";

const ReleasesTable = (props) => {
    const { theaders, tkeys, tbadges, totalItems, page, releases, changePage } = props;

    const store = useStore();
    const toast = useToast();
    const { token, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [t, i18n] = useTranslation('global');
    const prefix = 'releases.tooltips';
    const [modal, setModal] = useState(false);
    const [releaseSel, setReleaseSel] = useState(null);

    const operOrClose = () => setModal(!modal);

    const download = async (item) => {
        console.log("item: ", item);
        await downloadRelease({ headers, release_id: item.id, name: `${item.title}`, toast });
    }

    const viewDetails = (item) => {
        setReleaseSel(item)
        operOrClose();
    }

    return (
        <Fragment>
            <Heading>{t('releases.title')}</Heading>
            {/* Tabla en la que se listan los comunicados */}
            <TableComp
                headers={theaders}
                keys={tkeys}
                body={releases}
                badges={tbadges}
                actions={[
                    { icon: faEye, tooltip: t(`${prefix}.view`), handleClick: viewDetails },
                    { icon: faDownload, tooltip: t(`${prefix}.download`), handleClick: download, variant: 'outline-secondary' }
                ]}
            />
            {/* Paginación */}
            <PaginationComp current={page} totalItems={totalItems} onChange={changePage} />
            {/* Modal de vista previa */}
            {
                modal && <ModalView
                    onClose={operOrClose}
                    release={releaseSel}
                    headers={headers}
                    fibra_id={fibra.id}
                />
            }
        </Fragment>
    );
}

export default ReleasesTable