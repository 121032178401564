import { Fragment, useEffect, useState } from "react";
import { useToast } from "@chakra-ui/toast";
import { useStore } from "react-redux";
import FlexButton from "../../Layouts/FlexButton";
import { faEye, faFolder } from "@fortawesome/free-solid-svg-icons";
import Details from "./Users/Details";
import Documentation from "./Users/Documentation";
import TableComp from "../../Layouts/Table";
import PaginationComp from "../../Layouts/Pagination";
import { indexRepseUsers } from "../../../api/Repse/RepseUsers";
import { useTranslation } from "react-i18next";

const Users = (props) => {
    const { changePage, page, pageSize, totalItems, loading, providers, update } = props;
    const store = useStore();
    const toast = useToast();
    const { token, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [modal_doc, showModalDoc] = useState(false);
    const [modal_det, showModalDet] = useState(false);
    const [user_selected, setUserSelected] = useState(null);
    const [t, , i18n] = useTranslation('global');
    const prefix = 'repse.users';

    const openOrCloseModDoc = () => showModalDoc(!modal_doc);   // Mostrar u ocultar modal de documentos
    const openOrCloseModDet = () => showModalDet(!modal_det);   // Mostrar u ocultar modal de detalles

    const viewDocumentation = (user) => { setUserSelected(user); openOrCloseModDoc(); }     // Configuración antes de lanzar modal
    const viewDetails = (user) => { setUserSelected(user); openOrCloseModDet(); }           // Configuración antes de lanzar modal

    const add = () => { }
    return (
        <Fragment>
            {/* Tabla para listar a los socios comerciales de tipo proveedor */}
            <TableComp
                headers={[
                    '#',
                    t(`${prefix}.headers.business-name`),
                    t(`${prefix}.headers.duty-manager`),
                    t(`${prefix}.headers.tax-id`),
                    t(`${prefix}.headers.status`),
                    t(`${prefix}.headers.actions`),
                ]}
                keys={['#', 'business_name', 'name', 'rfc', 'status_repse_name']}
                body={providers}
                actions={[
                    { icon: faEye, handleClick: viewDetails, tooltip: t(`${prefix}.tooltips.view-details`) },
                    { icon: faFolder, handleClick: viewDocumentation, tooltip: t(`${prefix}.tooltips.view-docs`), variant: 'outline-success' }
                ]}
                loading={loading}
                loadingItems={pageSize}
                badges={[{ index: 4, color: 'status_repse_color' }]}
            />
            {/* Componente de paginación */}
            <PaginationComp
                totalItems={totalItems}
                current={page}
                onChange={changePage}
                defaultPageSize={pageSize}
            />
            {/* FAB  para agregar proveedor REPSE */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={add} />
            {/* Documents modal */}
            {
                modal_doc && <Documentation
                    headers={headers}
                    user={user_selected}
                    fibra={fibra}
                    toast={toast}
                    onClose={openOrCloseModDoc}
                    update={update}
                />
            }
            {/* Details modal */}
            {
                modal_det && <Details
                    headers={headers}
                    user={user_selected}
                    onClose={openOrCloseModDet}
                />
            }
        </Fragment>
    );
}

export default Users;