import Fetcher from "../libs/Petition"

const url = '/provider_documents';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * ESTE MÉTODO NO NECESITA AUTENTIFICACIÓN
 * @param fibra_id Se utiliza el identificador de la fibra para obtener los documentos que le corresponden
 * @returns Object {"data" => ProviderDocument::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexProviderDocuments = async (props) => {
    const { fibra_id } = props;
    let response = [];
    try {
        // Parámetros requeridos
        let params = { fibra_id };
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("Provider documents: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = {
            data: [],
            totalItems: 0
        };
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param provider_document_id Identificador del documento del proveedor sobre el que se hará la consulta
 * @returns ProviderDocument Retorna un objeto tipo ProviderDocument
 */
export const showProviderDocuments = async ({ headers, provider_document_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}/${provider_document_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeProviderDocuments = async ({ headers, data }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status !== CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param provider_document_id Identificador del documento del proveedor que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateProviderDocuments = async ({ headers, data, provider_document_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${provider_document_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = true;
        // console.log("Fetch complain suggestion: ", fetch);
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param provider_document_id Identificador del documento del proveedor que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyProviderDocuments = async ({ headers, provider_document_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${provider_document_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}