import { Box, Heading, Text } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ChangePassword from './ChangePassword';
import TwoFactorAuthComp from './TwoFactorAuth';

const Security = () => {
    const [t, i18n] = useTranslation('global');
    const prefix = 'settings.security';
    return (
        <Fragment>
            <Box p={5} shadow='md' borderWidth='1px' className='mt-3'>
                <Heading fontSize='xl'>{t(`${prefix}.change-pass`)}</Heading>
                <Text mt={4}>{t(`${prefix}.change-pass-text`)}</Text>
                <ChangePassword />
            </Box>
            <Box p={5} shadow='md' borderWidth='1px' className='mt-3'>
                <Heading fontSize='xl'>{t(`${prefix}.two-factor`)}</Heading>
                <Text mt={4}>{t(`${prefix}.two-factor-text`)}</Text>
                <TwoFactorAuthComp />
            </Box>
        </Fragment>
    );
};

export default Security;
