import { Fragment } from 'react';
import TableComp from '../../Layouts/Table';

const _ = require('underscore');

const Items = (props) => {
    const { items, loading, handleChange, itemsDelivered } = props;
    // Se marcan los items que serán entregados
    console.log("ITEMS: ", items);
    const getChecked = (purchase_order_item_id) => {
        let checked = false;
        let pos = _.findIndex(items, { id: purchase_order_item_id });
        if (pos !== -1) {
            if (items[pos].status === 'delivered')
                checked = true;
        }
        return checked;
    }

    const getDisabled = (purchase_order_item_id) => {
        let disable = true;
        let pos = _.findIndex(items, { id: purchase_order_item_id });
        if (pos !== -1) {
            if (items[pos].status === 'pending')
                disable = false;
        }
        return disable;
    }
    return <Fragment>
        <TableComp
            headers={[
                '#',
                'Descripción',
                'Cantidad',
                'Precio unitario',
                'Subtotal',
                'Acciones'
            ]}
            keys={['#', 'description', 'quantity', 'unit_price', 'subtotal']}
            body={items}
            loading={loading}
            currency={[{ index: 3 }, { index: 4 }]}
            type='switch'
            actions={{
                checkedChildren: 'Entregado',
                unCheckedChildren: 'No entregado',
                onChange: handleChange,
                getChecked: getChecked,
                getDisabled: getDisabled
            }}
        />
    </Fragment>;
};

export default Items;
