import { useToast } from "@chakra-ui/toast";
import { faEye, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { downloadProviderDocumentReceiveds } from "../../../api/ProvidersDocumentsReceiveds";
import TableComp from "../../Layouts/Table";

const UserDocuments = (props) => {
    const { files, loading, headers, data } = props;
    const [t, i18n] = useTranslation('global');
    const prefix = 'access-control.modal-view-user.documents';
    const toast = useToast();

    const download = async (item) => {
        downloadProviderDocumentReceiveds({
            headers, 
            provider_document_received_id: item.id,
            name: `${item.document_name} [${data?.first_name} ${data?.last_name}]`,
            toast
        });
    }
    return (
        <Fragment>
            <TableComp
                headers={[
                    '#',
                    t(`${prefix}.name`),
                    t(`${prefix}.created-at`),
                    t(`${prefix}.actions`)
                ]}
                keys={['#', 'document_name', 'created_at']}
                body={files}
                loading={loading}
                loadingItems={5}
                actions={[
                    {
                        icon: faFileDownload, tooltip: t(`${prefix}.action-download-tooltip`),
                        variant: 'outline-success', name: t(`${prefix}.action-download-name`), handleClick: download
                    }
                ]}
            />
        </Fragment>
    );
}

export default UserDocuments