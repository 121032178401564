import { Heading } from "@chakra-ui/layout";
import { faEdit, faPollH } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import { indexQuizz } from "../../../api/Quizzs/Quizzs";
import PaginationComp from "../../Layouts/Pagination";
import TableComp from "../../Layouts/Table";
import Answers from "./Answers";
import Details from "./Details";

const PollsTable = (props) => {
    const { theaders, tkeys, tbadges, params, is_admin } = props;

    const store = useStore();
    const { token, fibra, user } = store.getState().login;
    const role = useSelector(state => state.login.role_selected);
    const headers = { Authorization: `Bearer ${token}` };
    const fibra_id = fibra.id;

    const [quizzs, setQuizzs] = useState([]);
    const [quizzSel, setQuizzSel] = useState({});
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    const [modalAnswers, setModalAnswers] = useState(false);
    const [modalDetails, setModalDetails] = useState(false);
    const [t, i18n] = useTranslation('global');

    useEffect(() => {
        getQuizzs();
    }, [page, pageSize]);

    const getQuizzs = async () => {
        setLoading(true);
        let response = await indexQuizz({ headers, page, pageSize, fibra_id, ...params });
        setQuizzs(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }

    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const openOrCloseDetails = () => { setModalDetails(!modalDetails); };
    const openOrCloseAnswers = () => { setModalAnswers(!modalAnswers); };

    const viewDetail = (item) => {
        setQuizzSel(item);
        openOrCloseDetails();
    }

    const viewAnswers = (item) => {
        setQuizzSel(item);
        openOrCloseAnswers();
    }

    return (
        <Fragment>
            <Heading>{t('polls.title')}</Heading>
            {/* Tabla en la que se listan las encuestas */}
            <TableComp
                headers={theaders}
                keys={tkeys}
                body={quizzs}
                loading={loading}
                loadingItems={pageSize}
                badges={tbadges}
                // Las acciones son el base al rolo que acceda
                actions={[{
                    handleClick: is_admin ? viewDetail : viewAnswers,
                    tooltip: is_admin ? t('polls.tooltips.edit') : t('polls.tooltips.answer'),
                    icon: is_admin ? faEdit : faPollH,
                    variant: is_admin ? 'outline-success' : 'outline-primary'
                }]}
            />

            {/* Paginación de la tabla  */}
            <PaginationComp current={page} totalItems={totalItems} onChange={changePage} />
            {/* Modal para que los clientes y proveedores respondan las preguntas */}
            {
                modalAnswers &&
                <Answers
                    onClose={openOrCloseAnswers}
                    quizzSel={quizzSel}
                    headers={headers}
                    update={getQuizzs}
                    user_id={user.id}
                    role_id={role.id}
                />
            }
            {/* Modal para que el administrador pueda modificar las encuestas */}
            {
                modalDetails &&
                <Details
                    onClose={openOrCloseDetails}
                    quizzSel={quizzSel}
                    headers={headers}
                    update={getQuizzs}
                />
            }
        </Fragment>
    );
}

export default PollsTable