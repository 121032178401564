import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { indexRelease } from "../../api/Releases";
import ReleasesTable from "./fragments/Table";

const ReleasesClient = () => {
    const login = useSelector(state => state.login);
    const role_selected = login.role_selected;
    const headers = { Authorization: `Bearer ${login.token}` };
    const fibra_id = login.fibra.id;

    const [t, i18n] = useTranslation('global');
    const prefix = 'releases.data';
    const [releases, setReleases] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        getReleases();
    }, []);

    const getReleases = async () => {
        let response = await indexRelease({ headers, page, pageSize, fibra_id, role_id: role_selected.id });
        // console.log("GET RELEASES:: ", response);
        setReleases(response.data);
        setTotalItems(response.totalItems);
    }

    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    return (
        <ReleasesTable
            theaders={[
                '#',
                t(`${prefix}.title`),
                t(`${prefix}.status`),
                t(`${prefix}.created_at`),
                t(`${prefix}.actions`),
            ]}
            tkeys={['id', 'title', 'status', 'created_at']}
            tbadges={[]}
            releases={releases}
            totalItems={totalItems}
            page={page}
            changePage={changePage}
        />
    );
}

export default ReleasesClient;