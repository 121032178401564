import { useToast } from "@chakra-ui/react";
import { faArrowLeft, faPaperPlane, faSave } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { storeRelease, uploadRelease } from "../../../api/Releases";
import { storeRoleReleases } from "../../../api/RoleReleases";
import { indexRoles } from "../../../api/Roles";
import setToast from "../../../libs/SetToast";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import PDFViewerComp from "../../Layouts/PDFViewer";
import TabsComp from "../../Layouts/Tabs";
import FormRelease from "./FormRelease";
import Recipients from "./Recipients";

const AddRelease = (props) => {
    const { onClose, update } = props;

    const toast = useToast();
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const fibra_id = login.fibra.id;
    const [t, i18n] = useTranslation('global');
    const prefix = 'releases.tabs';
    const pt = 'releases.toasts';
    const [data, setData] = useState({});                   // Datos del formulario
    const [file, setFile] = useState(null);                 // Archivo PDF del comunicado
    const [roles, setRoles] = useState([]);                 // Roles de la fibra
    const [roleReleases, setRoleReleases] = useState([]);   // Roles a los que va dirigido el comunicado
    const [loading, setLoading] = useState(true);           // EStado de la operación

    useEffect(() => {
        getRoles();
    }, []);

    const getRoles = async () => {
        let response = await indexRoles({ headers, fibra_id });
        setRoles(response.data);
        setLoading(false);
    }
    // Guardar el archivo proporcionado
    const handleChangeFile = (e) => setFile(e.target.files[0]);
    // Manejador de los datos del formulario
    const handleChangeData = (e) => {
        let { value, name } = e.target;
        setData({ ...data, [name]: value });
    }
    // Evento para asignar módulo a la fibra
    const handleChange = async (checked, role_id) => {
        if (checked) {
            setRoleReleases([...roleReleases, { id: role_id }]);
        } else {
            setRoleReleases(roleReleases.filter(roleRelease => roleRelease.id !== role_id));
        }
    }
    // Método para validar que el comunicado puede ser enviado
    const validateRelease = () => {
        let response = true;
        try {
            // Validar datos del formulario
            if (data.title === '' || data.title === undefined || data.body === '' || data.body === undefined) {
                setToast({
                    title: t(`${pt}.no-data.title`), description: t(`${pt}.no-data.description`), toast, status: 'warning'
                });
                response = false;
            }
            // Validar archivo pdf
            if (file === null || file === undefined || file === {}) {
                setToast({
                    title: t(`${pt}.no-file.title`), description: t(`${pt}.no-file.description`), toast, status: 'warning'
                });
                response = false;
            }
            // Validar relación de roles a los que va dirigido
            if (roleReleases.length === 0) {
                setToast({
                    title: t(`${pt}.no-addresse.title`), description: t(`${pt}.no-addresse.description`), toast, status: 'warning'
                });
                response = false;
            }
        } catch (error) {
            setToast({
                title: t(`${pt}.error-unexp.title`), description: t(`${pt}.error-unexp.description`), toast, status: 'danger'
            });
            response = false;
        } finally {
            return response
        }
    }
    // Enviar el comunicado
    const sendRelease = async () => {
        // Validar campos
        if (validateRelease()) {
            // Crear el comunicado
            let response = await storeRelease({ headers, data: { ...data, fibra_id } });
            //Cargar archivo
            if (response) { // Si los datos del comunicado se guardan satisfactoriamente
                let formData = new FormData();
                formData.append('file', file);
                await uploadRelease({
                    headers,
                    release_id: response.id,
                    data: formData
                });
                // Cargar relación de los roles a los que va dirigido el comunicado
                roleReleases.map(async (roleRelease) => {
                    await storeRoleReleases({ headers, data: { role_id: roleRelease.id, release_id: response.id } });
                });
                setToast({
                    title: t(`${pt}.release-sent.title`), description: t(`${pt}.release-sent.description`), toast, status: 'success'
                });
                update();
                onClose();
            } else {//No se pudo completar en envío del comunicado
                setToast({
                    title: t(`${pt}.release-not-sent.title`), description: t(`${pt}.release-not-sent.description`), toast, status: 'error'
                });
            }
        }
    }
    // Método para obtener si el registro existe o no
    const getChecked = (role_id) => {
        return false;
    }
    // Método para indicar si el switch está habilitado
    const getDisabled = () => false;

    return (
        <ModalComp
            onClose={onClose}
            size="lg"
            title={t(`${prefix}.title-new`)}
            body={<Fragment>
                <TabsComp
                    tablist={[
                        t(`${prefix}.release`),
                        t(`${prefix}.preview`),
                        t(`${prefix}.addressee`)
                    ]}
                    tabpanels={[
                        <FormRelease edit handleChangeFile={handleChangeFile} handleChangeData={handleChangeData} data={data} />,
                        <PDFViewerComp file={file} />,
                        <Recipients loading={loading} roles={roles} handleChange={handleChange}
                            getChecked={getChecked} getDisabled={getDisabled} />
                    ]}
                />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name={t(`${prefix}.button-close`)} onClick={onClose} variant="outline-secondary" />
                <ButtonIcon icon={faPaperPlane} name={t(`${prefix}.button-send`)} onClick={sendRelease} variant="outline-success" />
            </Fragment>}
        />
    );
}

export default AddRelease;