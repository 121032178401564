import { Col, FloatingLabel, Form, FormSelect, Row } from "react-bootstrap";
import { Fragment, useEffect, useState } from "react";
import ModalComp from "../../Layouts/Modal";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { showIncident, updateIncident } from "../../../api/Incidents/Incidents";
import Swal from 'sweetalert2';
import SkeletonForm from "../../Layouts/Skeletons/Form";
import { useTranslation } from "react-i18next";

const IncidentsEdit = (props) => {
    const { incident_id, onClose, headers, update } = props;

    const [incident, setIncident] = useState({});
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [t, i18n] = useTranslation('global');
    const prefix = 'incidents.modal-edit';
    const prefix_swal = `${prefix}.swal-messages`;

    useEffect(() => {
        getIncident()
    }, []);

    const getIncident = async () => {
        let response = await showIncident({ headers, incident_id });
        setIncident(response);
        setLoading(false);
    }
    const saveChanges = async () => {
        let response = await updateIncident({ headers, data, incident_id });
        if (response) {
            update();
            Swal.fire(t(`${prefix_swal}.success-updated.title`), t(`${prefix_swal}.success-updated.description`), 'success');
            onClose();
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("Handle change: ", name, value);
        setData({
            ...data,
            [name]: value
        });
        setIncident({ ...incident, [name]: value });
    }

    return (
        <ModalComp
            onClose={onClose}
            title={incident.code}
            size='lg'
            body={
                <FormComponent
                    incident={incident}
                    handleChange={handleChange}
                    loading={loading}
                />
            }
            footer={<Fragment>
                <ButtonIcon variant="outline-secondary" onClick={onClose} name={t(`${prefix}.button-close`)} icon={faArrowLeft} />
                <ButtonIcon variant="outline-success" onClick={saveChanges} name={t(`${prefix}.button-save`)} icon={faSave} />
            </Fragment>}
        />
    );
}

const FormComponent = (props) => {
    const { loading } = props;
    const [t, i18n] = useTranslation('global');
    const prefix = 'incidents.filters';

    return (
        <Fragment>
            {
                loading ? <SkeletonForm cols={3} rows={3} /> :
                    <Form>
                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label={t('incidents.data.code')}>
                                    <Form.Control type="text" defaultValue={props.incident.code} readOnly />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label={t('incidents.data.type')}>
                                    <Form.Control type="text" value={props.incident.incident_type} readOnly />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label={t('incidents.data.status')}>
                                    {/* <Form.Control type="text" name='status'
                            defaultValue={props.incident.status} onChange={props.handleChange} /> */}
                                    <FormSelect value={props.incident.status} name='status' onChange={props.handleChange}>
                                        <option value="pendiente">{t(`${prefix}.pending`)}</option>
                                        <option value="atendiendo">{t(`${prefix}.attending`)}</option>
                                        <option value="resuelto">{t(`${prefix}.solved`)}</option>
                                        <option value="cancelado">{t(`${prefix}.canceled`)}</option>
                                    </FormSelect>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label={t('incidents.data.user')}>
                                    <Form.Control type="text" value={props.incident.user} readOnly />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label={t('incidents.data.created-at')}>
                                    <Form.Control type="text" value={props.incident.created_at} readOnly />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <label>{t('incidents.data.description')}</label>
                                <textarea className="form-control" name='description'
                                    defaultValue={props.incident.description} onChange={props.handleChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel label={t('incidents.data.sector')}>
                                    <Form.Control type="text" name='sector'
                                        defaultValue={props.incident.sector} onChange={props.handleChange} />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label={t('incidents.data.flat')}>
                                    <Form.Control type="text" name='flat'
                                        defaultValue={props.incident.flat} onChange={props.handleChange} />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label={t('incidents.data.local')}>
                                    <Form.Control type="text" name='local'
                                        defaultValue={props.incident.local} onChange={props.handleChange} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Form>
            }
        </Fragment>
    );
}

export default IncidentsEdit;