import { Fragment } from 'react'
import Main from './fragments/Main'

const ProviderChat = () => {
    return (
        <Fragment>
            <Main />
        </Fragment>
    )
}

export default ProviderChat;