import PropTypes from 'prop-types';

const setToast = async (props) => {
    const { title, description, status, toast } = props;

    toast({
        title,
        description,
        status,
        duration: 4000,
        isClosable: true,
    });
}

setToast.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
    toast: PropTypes.any,
}

export default setToast;